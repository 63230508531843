// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductPoints-module-points-mvGC8{grid-area:productpoints;color:#5b615e;font-size:16px;line-height:23px;font-weight:300;margin-block:auto;margin-inline:0;height:100%}.ProductPoints-module-points-mvGC8 .ProductPoints-module-ploom-cISQy{color:#3567ff;color:var(--colors-accent-accent-100, #3567ff);font-weight:600}.ProductPoints-module-points-mvGC8 .ProductPoints-module-pointsQuantity-P6GmL{font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"points": "ProductPoints-module-points-mvGC8",
	"ploom": "ProductPoints-module-ploom-cISQy",
	"pointsQuantity": "ProductPoints-module-pointsQuantity-P6GmL"
};
export default ___CSS_LOADER_EXPORT___;
