import { FC, memo } from "react";

import dompurify from "isomorphic-dompurify";

import { Txt } from "@jti/ui";

import styles from "./ProductDescription.module.scss";

const { sanitize } = dompurify;

type Props = {
  description: string;
};

const ProductDescription: FC<Props> = ({ description }) => {
  return (
    <Txt variant="txt-1">
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    </Txt>
  );
};

export default memo(ProductDescription);
