// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PolicyCheckFields-module-container-HPWRt{display:grid;grid-gap:24px;gap:24px}.PolicyCheckFields-module-isPolicyRead-4aO\\+Y,.PolicyCheckFields-module-isAdult-vgsgq{font-weight:300;letter-spacing:0;line-height:23px;color:#5b615e}.PolicyCheckFields-module-isPolicyRead-4aO\\+Y div,.PolicyCheckFields-module-isAdult-vgsgq div{font-size:16px}.PolicyCheckFields-module-isPolicyReadLink-fou2v a{text-decoration:none;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PolicyCheckFields-module-container-HPWRt",
	"isPolicyRead": "PolicyCheckFields-module-isPolicyRead-4aO+Y",
	"isAdult": "PolicyCheckFields-module-isAdult-vgsgq",
	"isPolicyReadLink": "PolicyCheckFields-module-isPolicyReadLink-fou2v"
};
export default ___CSS_LOADER_EXPORT___;
