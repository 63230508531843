// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InternationalTelephone-module-phoneContainer-gFygV{width:100%}.InternationalTelephone-module-phoneInputContainer-J7EJa{width:100%;display:flex;height:50px;padding:10px;border:1.4px solid #373a36;border-radius:8px;background-color:#fff;transition:border-color .2s ease;direction:ltr}.InternationalTelephone-module-phoneInputContainer-J7EJa input{width:100%;border:none;outline:none;background-color:rgba(0,0,0,0);font-family:inherit;font-size:inherit;font-weight:inherit}[dir=rtl] .InternationalTelephone-module-phoneInputContainer-J7EJa input{text-align:end;direction:ltr}.InternationalTelephone-module-phoneInputContainer-J7EJa.InternationalTelephone-module-hasError-81Zo2{border-color:#dc283e}.InternationalTelephone-module-phoneInputContainer-J7EJa:focus-within{border:2px solid #4681ff}.InternationalTelephone-module-phoneInputContainer-J7EJa.InternationalTelephone-module-isDisabled-IHYW0{background-color:#e2e2e2;border-color:#e2e2e2;cursor:not-allowed}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneContainer": "InternationalTelephone-module-phoneContainer-gFygV",
	"phoneInputContainer": "InternationalTelephone-module-phoneInputContainer-J7EJa",
	"hasError": "InternationalTelephone-module-hasError-81Zo2",
	"isDisabled": "InternationalTelephone-module-isDisabled-IHYW0"
};
export default ___CSS_LOADER_EXPORT___;
