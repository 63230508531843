import { FC, useCallback, useEffect, useState } from "react";

import {
  cartMini,
  fetchItems,
  fetchPrices,
  getCartPending,
  getItems,
  getPrices,
  getQtyPending,
  removeItem,
  removeItems,
  updateItemQuantity,
  useDispatch,
  useSelector,
} from "@jti/store";
import { dispatchTrackingEvent, TrackingEvents, TrackingEventsAdditionalData } from "@jti/tracker";
import { useConfig } from "@jti/ui";

import { buildCommerceBaseData } from "@checkout/hooks/gtm/trackingHelpers";

import CartMiniComponent from "../../components/CartMini";
import useGtmQuantityChange from "../../hooks/gtm/useGtmQuantityChange";
import getCartItemsCount from "../../utils/getCartItemsCount";

const CartMini: FC = () => {
  const dispatch = useDispatch();
  const { CART_CHECKOUT_PAGE_URL } = useConfig();

  const cart = useSelector((state) => state.cart);
  const cartId = cart.id;
  const items = useSelector(getItems);
  const prices = useSelector(getPrices);
  const itemsPending = useSelector(getCartPending);
  const qtyPending = useSelector(getQtyPending);
  const { opened } = useSelector((state) => state.cartMini);
  const grandTotal = useSelector((state) => state.cart.prices?.grandTotal.value);
  const shipping = useSelector((state) => state.cart.prices?.shipping?.value);
  const { is_order_limit_enabled: isLimitEnabled, order_limit_value: maxOrderLimit } = useSelector(
    (state) => state.cart.storeConfig
  );
  useGtmQuantityChange();

  const [limitMessageStatus, setLimitMessageStatus] = useState(false);
  const [isUserClickedToOpenCart, setIsUserClickedToOpenCart] = useState(false);

  useEffect(() => {
    const element = window.document.querySelector("body");

    if (element) {
      element.classList.toggle("body-locked", opened);
    }
  }, [opened]);

  useEffect(() => {
    if (cartId) {
      dispatch(fetchItems(cartId));
    }
  }, [dispatch, cartId]);

  useEffect(() => {
    setLimitMessageStatus((grandTotal || 0) - (shipping || 0) > maxOrderLimit && isLimitEnabled);
  }, [grandTotal]);

  // track mini cart open state
  useEffect(() => {
    if (opened && isUserClickedToOpenCart) {
      const data: TrackingEventsAdditionalData[TrackingEvents.TrackingCartOpen] = buildCommerceBaseData(cart);
      dispatchTrackingEvent(TrackingEvents.TrackingCartOpen, data);
    }
  }, [opened, isUserClickedToOpenCart]);

  // handlers
  const handleQuantityChange = useCallback(
    async (itemId: string, quantity: number) => {
      if (cartId) {
        await dispatch(updateItemQuantity({ cartId, itemId, quantity }));
        dispatch(fetchItems(cartId));
        dispatch(fetchPrices(cartId));
      }
    },
    [dispatch, cartId]
  );

  const handleRemoveItem = useCallback(
    async (itemId: string) => {
      if (cartId) {
        await dispatch(removeItem({ cartId, itemId }));
        dispatch(fetchItems(cartId));
      }
    },
    [dispatch, cartId]
  );

  const handleRemoveItems = useCallback(
    async (itemIds: string[]) => {
      if (cartId) {
        await dispatch(removeItems({ cartId, itemIds }));
        dispatch(fetchItems(cartId));
      }
    },
    [dispatch, cartId]
  );

  const handleCheckoutClick = useCallback(() => {
    window.location.href = CART_CHECKOUT_PAGE_URL;
  }, []);

  const handleOpen = useCallback(() => {
    dispatch(cartMini.actions.open());
    setIsUserClickedToOpenCart(true);
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(cartMini.actions.close());
    setIsUserClickedToOpenCart(false);
  }, [dispatch]);

  const subtotal = prices?.subtotal;
  const subtotalPrice = subtotal && { currency: subtotal.currency, value: subtotal.value };

  return (
    <CartMiniComponent
      subtotal={subtotalPrice}
      items={items}
      opened={opened}
      onOpen={handleOpen}
      onClose={handleClose}
      warningStatus={limitMessageStatus}
      isLimitEnabled={isLimitEnabled}
      itemsCount={getCartItemsCount(items)}
      itemsPending={itemsPending}
      qtyPending={qtyPending}
      onItemQuantityChange={handleQuantityChange}
      onRemoveItems={handleRemoveItems}
      onRemoveItemClick={handleRemoveItem}
      onCheckoutClick={handleCheckoutClick}
      isOnCartPage={window.location.href.includes(CART_CHECKOUT_PAGE_URL)}
    />
  );
};

export default CartMini;
