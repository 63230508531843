// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Password-module-inputWrap-SE95u{position:relative}.Password-module-icon-XreTO{position:absolute;top:50%;transform:translateY(-50%);margin-inline-start:-35px;cursor:pointer;height:18px;width:18px;color:#373a36}.Password-module-input-FkWmR{padding-inline-end:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": "Password-module-inputWrap-SE95u",
	"icon": "Password-module-icon-XreTO",
	"input": "Password-module-input-FkWmR"
};
export default ___CSS_LOADER_EXPORT___;
