// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavBar-module-container-4zsPO{width:100%;border-radius:8px;background-color:#131110;margin-bottom:20px}.NavBar-module-list-STs-A{display:grid;grid-row-gap:35px;padding-block:45px;padding-inline:0;list-style:none;margin:0}.NavBar-module-link-beIre{height:20px;color:#fff;font-size:14px;letter-spacing:.15px;line-height:20px;text-decoration:none;display:flex;align-items:center}.NavBar-module-icons-bSJiP{visibility:hidden}.NavBar-module-text-gi9k3{padding-block:0;padding-inline:20px;flex-basis:85%}.NavBar-module-active-KJ8g7{font-weight:bold}.NavBar-module-active-KJ8g7 .NavBar-module-icons-bSJiP{visibility:visible}.NavBar-module-active-KJ8g7 svg rect{fill:#3567ff;fill:var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NavBar-module-container-4zsPO",
	"list": "NavBar-module-list-STs-A",
	"link": "NavBar-module-link-beIre",
	"icons": "NavBar-module-icons-bSJiP",
	"text": "NavBar-module-text-gi9k3",
	"active": "NavBar-module-active-KJ8g7"
};
export default ___CSS_LOADER_EXPORT___;
