// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StreetAddressFields-module-container-KhbXo{display:grid;grid-gap:24px;gap:24px}.StreetAddressFields-module-addressFields-WBNL\\+{display:flex;flex-wrap:wrap;align-items:flex-start}@media(min-width: 960px){.StreetAddressFields-module-addressFields-WBNL\\+>div:first-child{padding-inline-end:20px}}.StreetAddressFields-module-streetName-X8ys5{width:100%;margin-bottom:24px}@media(min-width: 960px){.StreetAddressFields-module-streetName-X8ys5{width:65%}}.StreetAddressFields-module-streetNumber-Yt6l4{width:100%;margin-bottom:24px}@media(min-width: 960px){.StreetAddressFields-module-streetNumber-Yt6l4{width:35%}}.StreetAddressFields-module-streetAdditional-VFEsz{width:100%;margin-bottom:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StreetAddressFields-module-container-KhbXo",
	"addressFields": "StreetAddressFields-module-addressFields-WBNL+",
	"streetName": "StreetAddressFields-module-streetName-X8ys5",
	"streetNumber": "StreetAddressFields-module-streetNumber-Yt6l4",
	"streetAdditional": "StreetAddressFields-module-streetAdditional-VFEsz"
};
export default ___CSS_LOADER_EXPORT___;
