// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Row-module-row-0bGfh{display:grid;grid-gap:4px;gap:4px;grid-template-columns:repeat(12, 1fr)}@media(min-width: 480px){.Row-module-row-0bGfh{gap:6px}}@media(min-width: 600px){.Row-module-row-0bGfh{gap:8px}}@media(min-width: 768px){.Row-module-row-0bGfh{gap:10px}}@media(min-width: 960px){.Row-module-row-0bGfh{gap:12px}}@media(min-width: 1070px){.Row-module-row-0bGfh{gap:20px}}@media(min-width: 1280px){.Row-module-row-0bGfh{gap:23px}}@media(min-width: 1920px){.Row-module-row-0bGfh{gap:24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Row-module-row-0bGfh"
};
export default ___CSS_LOADER_EXPORT___;
