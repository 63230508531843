// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExternalAgeVerification-module-wrapper-G286B{display:flex;flex-direction:column;align-items:center;justify-content:flex-start;padding-block:0 25px;padding-inline:10px;width:100%;gap:20px}@media(min-width: 960px){.ExternalAgeVerification-module-wrapper-G286B{padding-block:26px 28px;padding-inline:35px;gap:25px}}@media(min-width: 960px){.ExternalAgeVerification-module-wrapperOverlay-\\+uD-C{position:absolute;width:100%;height:100%;z-index:1;top:0;inset-inline-start:0}}.ExternalAgeVerification-module-title-j20TS{font-size:16px;font-weight:300;line-height:24px;margin-top:30px;color:#373a36}@media(min-width: 960px){.ExternalAgeVerification-module-title-j20TS{font-size:18px}}.ExternalAgeVerification-module-contentWrapper-o-tx4{padding:22px;width:100%;height:100%;padding-block:22px 25px;padding-inline:15px;border-radius:8px;background-color:rgba(216,216,216,.14);margin-bottom:30px;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:70px}@media(min-width: 960px){.ExternalAgeVerification-module-contentWrapper-o-tx4{background-color:rgba(0,0,0,0);justify-content:flex-start;gap:50px;padding:0;margin-bottom:0}}.ExternalAgeVerification-module-steps-SKTkg{display:flex;flex-direction:column;color:#373a36;list-style-position:inside;font-size:12px;font-weight:300;letter-spacing:0;line-height:25px;margin-bottom:20px}.ExternalAgeVerification-module-helperText-y5LRV{margin-top:25px;color:#373a36;font-size:14px;font-weight:300;line-height:20px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ExternalAgeVerification-module-wrapper-G286B",
	"wrapperOverlay": "ExternalAgeVerification-module-wrapperOverlay-+uD-C",
	"title": "ExternalAgeVerification-module-title-j20TS",
	"contentWrapper": "ExternalAgeVerification-module-contentWrapper-o-tx4",
	"steps": "ExternalAgeVerification-module-steps-SKTkg",
	"helperText": "ExternalAgeVerification-module-helperText-y5LRV"
};
export default ___CSS_LOADER_EXPORT___;
