// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerticalLoader-module-loaderContainer-710pn{height:100%;z-index:1;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column}.VerticalLoader-module-loaderText-8Df-a{margin-top:10px;color:#fff;text-transform:uppercase;font-size:14px;letter-spacing:3.5px;font-weight:300;animation:VerticalLoader-module-blink-0g98T 2s linear infinite}.VerticalLoader-module-progressBarContainer-ja09Z{width:7px;height:100px;border:1px solid rgba(255,255,255,.4);border-radius:8px;position:relative;overflow:hidden}.VerticalLoader-module-loaderProgressBar-mkLBp{background-color:#fff;position:absolute;top:0;inset-inline-start:0;width:100%;height:100%;transform:translateY(100%);animation-timing-function:ease-in-out;animation-name:VerticalLoader-module-move-progress-bar-8vhBy;animation-duration:1.5s;animation-iteration-count:infinite}@keyframes VerticalLoader-module-move-progress-bar-8vhBy{from{transform:translateY(100%)}to{transform:translateY(-100%)}}@keyframes VerticalLoader-module-blink-0g98T{0%{opacity:0}50%{opacity:1}100%{opacity:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": "VerticalLoader-module-loaderContainer-710pn",
	"loaderText": "VerticalLoader-module-loaderText-8Df-a",
	"blink": "VerticalLoader-module-blink-0g98T",
	"progressBarContainer": "VerticalLoader-module-progressBarContainer-ja09Z",
	"loaderProgressBar": "VerticalLoader-module-loaderProgressBar-mkLBp",
	"move-progress-bar": "VerticalLoader-module-move-progress-bar-8vhBy"
};
export default ___CSS_LOADER_EXPORT___;
