// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateInputs-module-dateInputsContainer-\\+DVyS{display:flex;flex-direction:row;gap:10px;gap:var(--spacing-3xs, 10px);align-items:flex-start;margin-block-start:calc(10px*-1);margin-block-start:calc(var(--spacing-3xs, 10px)*-1)}[dir=rtl] .DateInputs-module-dateInput-bmAJI{direction:rtl}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateInputsContainer": "DateInputs-module-dateInputsContainer-+DVyS",
	"dateInput": "DateInputs-module-dateInput-bmAJI"
};
export default ___CSS_LOADER_EXPORT___;
