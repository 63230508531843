// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactInfo-module-nameAndPhoneList-FfZBr{display:grid;grid-gap:6px;gap:6px}.ContactInfo-module-nameAndPhoneList-FfZBr strong{margin-inline-end:5px}.ContactInfo-module-label-Iy0fA{font-weight:bold;margin-inline-end:6px}.ContactInfo-module-editButton-QzWG9{margin-top:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameAndPhoneList": "ContactInfo-module-nameAndPhoneList-FfZBr",
	"label": "ContactInfo-module-label-Iy0fA",
	"editButton": "ContactInfo-module-editButton-QzWG9"
};
export default ___CSS_LOADER_EXPORT___;
