// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeLimitedBadge-module-timeLimitedBadge-EONOk{display:inline-flex;flex-direction:row;align-items:center;gap:8px}.TimeLimitedBadge-module-lastDay-wIAds{padding-block:2px;padding-inline:4px 12px;background:#373a36;border-radius:8px}.TimeLimitedBadge-module-iconWhite-oGrrD{color:#fff}.TimeLimitedBadge-module-iconBlack-EGEMg{color:#373a36}.TimeLimitedBadge-module-label-HT6gd{line-height:20px;letter-spacing:1.5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeLimitedBadge": "TimeLimitedBadge-module-timeLimitedBadge-EONOk",
	"lastDay": "TimeLimitedBadge-module-lastDay-wIAds",
	"iconWhite": "TimeLimitedBadge-module-iconWhite-oGrrD",
	"iconBlack": "TimeLimitedBadge-module-iconBlack-EGEMg",
	"label": "TimeLimitedBadge-module-label-HT6gd"
};
export default ___CSS_LOADER_EXPORT___;
