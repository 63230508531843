// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton-module-container-RamWo{font-size:12px;position:absolute;display:flex;align-items:center;top:-10px;inset-inline-start:-10px;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}@media(min-width: 960px){.BackButton-module-container-RamWo{top:8px;inset-inline-start:18px}}.BackButton-module-button-ZAJWI>span{display:flex;gap:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BackButton-module-container-RamWo",
	"button": "BackButton-module-button-ZAJWI"
};
export default ___CSS_LOADER_EXPORT___;
