import { useEffect, useRef } from "react";

import { useMessageBus } from "@jti/fe-message-bus";
import { addProduct, fetchTrialCart, useDispatch, useSelector } from "@jti/store";

const useProductListener = (): void => {
  const cart = useSelector((store) => store.cart);
  const cartRef = useRef(cart);
  const dispatch = useDispatch();
  const messageBus = useMessageBus();

  cartRef.current = cart;

  useEffect(() => {
    messageBus.subscribe({
      channel: "cart.add",
      callback: ({ payload: { sku, bundle, quantity, isFreeTrial } }) => {
        if (!cartRef.current.id) {
          messageBus.publish("fail.cart.add", {
            payload: { message: "productdetails.addproduct.error.cartId" },
          });

          return;
        }

        if (!sku) {
          messageBus.publish("fail.cart.add", {
            payload: { message: "productdetails.addproduct.error.skuRequired" },
          });

          return;
        }

        if (isFreeTrial) {
          dispatch(
            fetchTrialCart({
              useAnonymous: true,
              freeTrialProduct: {
                sku,
                bundle,
              },
            })
          );

          return;
        }

        dispatch(
          addProduct({
            cartId: cartRef.current.id,
            sku,
            bundle,
            quantity,
          })
        );
      },
    });

    return () => {
      messageBus.unsubscribe("cart.add");
    };
  }, []);
};

export default useProductListener;
