import { __ } from "@jti/fe-common";
import { Price, Txt } from "@jti/ui";
import Close from "@jti/ui/icons/close.svg";

export type CartMiniHeaderType = {
  itemsCount: number;
  subtotal?: {
    currency: string;
    value: number;
  };
  onClose?: () => void;
};

const CartMiniHeader = ({ itemsCount, subtotal, onClose }: CartMiniHeaderType): JSX.Element => {
  const Content = () => {
    if (!!itemsCount && subtotal) {
      return (
        <div className={"mini-cart__header-subtotal-row"}>
          <div className={"mini-cart__header-subtotal-row-text"}>{__("cart.cart_mini.subtotal")} </div>
          <div className={"mini-cart__header-price"} data-testid="miniCartSubtotal">
            <Price price={subtotal} />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={"mini-cart__header"}>
      <div className={"mini-cart__header-title"}>
        {__("cart.cart_mini.title")}
        <div className={"mini-cart__close-icon"}>
          <Close onClick={onClose} width={"10px"} height={"10px"} data-testid={"miniCartCloseIcon"} />
        </div>
      </div>
      <Txt variant="txt-2" className={"mini-cart__header-count"}>
        {__(itemsCount === 1 ? "cart.items_count.single" : "cart.items_count", [itemsCount])}
      </Txt>
      <Content />
    </div>
  );
};

export default CartMiniHeader;
