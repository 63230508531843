import { FC } from "react";

import classNames from "classnames";

import { useSelector } from "@jti/store";
import { FormattedPrice, I18n, useTranslations } from "@jti/ui";

import styles from "../SingleError.module.scss";

type PriceLimitErrorMessageProps = {
  maxOrderLimit: number;
  className?: string;
  variant?: "regular" | "compact";
};

export const PriceLimitErrorMessage: FC<PriceLimitErrorMessageProps> = ({
  maxOrderLimit,
  className,
  variant = "regular",
}) => {
  const { __ } = useTranslations();
  const baseCurrency = useSelector((state) => state.cart.currency.code);

  return (
    <>
      <p className={classNames(styles.title, { [styles.titleRegular]: variant === "regular" }, className)}>
        {__("com.communication.text_message.limit_title")}
      </p>
      {variant === "regular" && baseCurrency && (
        <p className={styles.intro}>
          <I18n t="com.communication.text_message.limit_warning">
            <FormattedPrice value={maxOrderLimit} currency={baseCurrency} />
          </I18n>
        </p>
      )}
    </>
  );
};
