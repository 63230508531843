// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutOfStock-module-circle-DTCiG::before{content:\" ●\";font-size:10px;color:#ec0000}.OutOfStock-module-icon-Hay-4{padding-inline:10px 10px;vertical-align:middle}.OutOfStock-module-outOfStock-q28ux{font-size:14px;line-height:20px;color:#373a36;padding-inline-start:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": "OutOfStock-module-circle-DTCiG",
	"icon": "OutOfStock-module-icon-Hay-4",
	"outOfStock": "OutOfStock-module-outOfStock-q28ux"
};
export default ___CSS_LOADER_EXPORT___;
