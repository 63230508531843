// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Disclaimer-module-flickerAnimation-KQWus{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Disclaimer-module-fadeInAnimation-zQd-B{0%{opacity:0}100%{opacity:1}}@keyframes Disclaimer-module-fadeInFromTop-5goOw{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Disclaimer-module-fadeOutToTop-XHzA2{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Disclaimer-module-fadeInFromLeftAnimation-xi\\+OE{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Disclaimer-module-textClip-kFQ9Q{to{background-size:100% 100%}}@keyframes Disclaimer-module-scroll-u8Mqn{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes Disclaimer-module-flickerAnimation-KQWus{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Disclaimer-module-fadeInAnimation-zQd-B{0%{opacity:0}100%{opacity:1}}@keyframes Disclaimer-module-fadeInFromTop-5goOw{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Disclaimer-module-fadeOutToTop-XHzA2{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Disclaimer-module-fadeInFromLeftAnimation-xi\\+OE{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Disclaimer-module-textClip-kFQ9Q{to{background-size:100% 100%}}@keyframes Disclaimer-module-scroll-u8Mqn{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.Disclaimer-module-disclaimer-kn3OA p{font-size:12px;font-size:var(--size-text-xs, 12px);line-height:16px;line-height:var(--line-height-text-xs, 16px);text-align:center;font-weight:300}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": "Disclaimer-module-disclaimer-kn3OA",
	"flickerAnimation": "Disclaimer-module-flickerAnimation-KQWus",
	"fadeInAnimation": "Disclaimer-module-fadeInAnimation-zQd-B",
	"fadeInFromTop": "Disclaimer-module-fadeInFromTop-5goOw",
	"fadeOutToTop": "Disclaimer-module-fadeOutToTop-XHzA2",
	"fadeInFromLeftAnimation": "Disclaimer-module-fadeInFromLeftAnimation-xi+OE",
	"textClip": "Disclaimer-module-textClip-kFQ9Q",
	"scroll": "Disclaimer-module-scroll-u8Mqn"
};
export default ___CSS_LOADER_EXPORT___;
