// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MessageBox-module-container-DCLos{position:relative;width:100%;border-radius:5px;padding-block:23px;padding-inline:17px 54px;opacity:1;max-height:350px;overflow:hidden;transition:all .3s ease;display:flex;gap:17px;box-sizing:border-box}.MessageBox-module-container-DCLos .MessageBox-module-heading-adKQy{font-weight:600;margin-top:0;margin-bottom:0;font-size:16px}.MessageBox-module-container-DCLos.MessageBox-module-notScrollable-j9h13{max-height:100%}.MessageBox-module-container-DCLos.MessageBox-module-notScrollable-j9h13 .MessageBox-module-content-\\+6jUP{max-height:100%}.MessageBox-module-container-DCLos .MessageBox-module-content-\\+6jUP{font-family:var(--font-family);color:#373a36;font-weight:300;font-size:16px;line-height:23px;max-height:300px;text-align:start;overflow-y:auto;padding-top:3px}.MessageBox-module-container-DCLos .MessageBox-module-content-\\+6jUP p:first-of-type{margin-top:0}.MessageBox-module-container-DCLos .MessageBox-module-content-\\+6jUP p:last-child{margin-bottom:0}.MessageBox-module-container-DCLos.MessageBox-module-variant-error-63SWL{background-color:#fbbfbf}.MessageBox-module-container-DCLos.MessageBox-module-variant-warning-lLZwx{background-color:#ffe9d1}.MessageBox-module-container-DCLos.MessageBox-module-variant-message-E-bvi{background-color:#b9dcd2}.MessageBox-module-container-DCLos.MessageBox-module-variant-info-OJh-S{background-color:#fcfcfc}.MessageBox-module-compact-t5Aol{padding-block:21px 16px;padding-inline:14px 19px;gap:13px}.MessageBox-module-compact-t5Aol .MessageBox-module-heading-adKQy{font-size:14px}.MessageBox-module-compact-t5Aol .MessageBox-module-content-\\+6jUP{letter-spacing:.15px;font-size:14px;line-height:20px;padding-top:0}.MessageBox-module-hidden-8no\\+E{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MessageBox-module-container-DCLos",
	"heading": "MessageBox-module-heading-adKQy",
	"notScrollable": "MessageBox-module-notScrollable-j9h13",
	"content": "MessageBox-module-content-+6jUP",
	"variant-error": "MessageBox-module-variant-error-63SWL",
	"variant-warning": "MessageBox-module-variant-warning-lLZwx",
	"variant-message": "MessageBox-module-variant-message-E-bvi",
	"variant-info": "MessageBox-module-variant-info-OJh-S",
	"compact": "MessageBox-module-compact-t5Aol",
	"hidden": "MessageBox-module-hidden-8no+E"
};
export default ___CSS_LOADER_EXPORT___;
