// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon-module-icon-1YL\\+T{display:inline-block;color:inherit;font-style:normal;line-height:0;text-align:center;text-transform:none;vertical-align:-0.125em;text-rendering:optimizelegibility;-webkit-font-smoothing:antialiased}.Icon-module-flip-ANWOn{transform:scaleX(-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Icon-module-icon-1YL+T",
	"flip": "Icon-module-flip-ANWOn"
};
export default ___CSS_LOADER_EXPORT___;
