// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopFooterHealthBanner-module-HealthBannerContainer-sfl9c{overflow:hidden;justify-content:center;display:flex;padding-block:18px;padding-inline:18px;margin-top:-40px;width:100%;position:relative}@media(min-width: 960px){.TopFooterHealthBanner-module-HealthBannerContainer-sfl9c{padding-block:var(--spacing-lg);padding-inline:41px;margin-top:-80px}}.TopFooterHealthBanner-module-HealthBannerContent-IbjeQ{gap:var(--spacing-3xs);width:100%;max-width:var(--container-max-width);display:flex;justify-content:center;align-items:center;text-align:center;box-sizing:border-box;padding-block:var(--spacing-xs);padding-inline:var(--spacing-2xl)}.TopFooterHealthBanner-module-HealthBannerContent-IbjeQ .TopFooterHealthBanner-module-message-F4flQ{text-align:center;line-height:1.4;letter-spacing:var(--letter-spacing-lg)}.TopFooterHealthBanner-module-HealthBannerContent-IbjeQ .TopFooterHealthBanner-module-message-F4flQ b{font-weight:bold}.TopFooterHealthBanner-module-HealthBannerContent-IbjeQ.TopFooterHealthBanner-module-rounded-F3LfO{border-radius:var(--radius-md)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HealthBannerContainer": "TopFooterHealthBanner-module-HealthBannerContainer-sfl9c",
	"HealthBannerContent": "TopFooterHealthBanner-module-HealthBannerContent-IbjeQ",
	"message": "TopFooterHealthBanner-module-message-F4flQ",
	"rounded": "TopFooterHealthBanner-module-rounded-F3LfO"
};
export default ___CSS_LOADER_EXPORT___;
