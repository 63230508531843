import { FC, ReactNode, useCallback } from "react";

import classNames from "classnames";

import { Product } from "@jti/magento";
import { Button } from "@jti/ui";

import styles from "../SingleError.module.scss";

type CheckoutErrorMessageProps = {
  items: Product[];
  onRemoveItems?: (itemsIds: string[]) => void;
  className?: string;
  variant?: "regular" | "compact";
  title: string;
  intro?: string | number | ReactNode | JSX.Element | JSX.Element[];
  buttonText?: string;
};

export const CheckoutErrorMessage: FC<CheckoutErrorMessageProps> = ({
  onRemoveItems,
  items,
  className,
  variant = "regular",
  title,
  intro,
  buttonText,
}) => {
  const hasStringId = (item: Product): item is Product & { id: string } => {
    return typeof item.id === "string";
  };

  const handleOnRemove = useCallback(() => {
    if (onRemoveItems) {
      const stringItemIds = items.filter(hasStringId).map((item) => item.id);
      onRemoveItems(stringItemIds);
    }
  }, [items, onRemoveItems]);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <p className={classNames(styles.title, { [styles.titleRegular]: variant === "regular" }, className)}>{title}</p>
      {variant === "regular" ? (
        <>
          {intro && <p className={styles.intro}>{intro}</p>}
          {buttonText && (
            <Button type="button" variant="cta" onClick={handleOnRemove}>
              {buttonText}
            </Button>
          )}
        </>
      ) : null}
    </>
  );
};
