import { FC, memo } from "react";

import { __ } from "@jti/fe-common";
import { Button } from "@jti/ui";

type Props = {
  onClick(): void;
};

export const RemoveBtn: FC<Props> = memo(({ onClick }) => {
  return (
    <Button color="tertiary" onClick={onClick} data-testid="cartRemoveButton" data-remove-button>
      {__("cart.cart_item.button.remove")}
    </Button>
  );
});
