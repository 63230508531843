import { Product as CartItem, SelectedBundleOptionValue } from "@jti/magento";

export const getOutOfStockItems = (items: CartItem[]): (CartItem | SelectedBundleOptionValue)[] => {
  return items.flatMap((item) => {
    const outOfStockItems: (CartItem | SelectedBundleOptionValue)[] = [];

    if (item.status === "OUT_OF_STOCK") {
      outOfStockItems.push(item);
    }

    if (item.bundle_options) {
      item.bundle_options.forEach((bundleOption) => {
        bundleOption.values?.forEach((value) => {
          if (value.presentation_info.product.stock_status === "OUT_OF_STOCK") {
            outOfStockItems.push(value);
          }
        });
      });
    }

    return outOfStockItems;
  });
};
