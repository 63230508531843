// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PersonalDetails-module-fields--wqEI{align-items:baseline;display:grid;grid-gap:26px;gap:26px;grid-template-columns:1fr}.PersonalDetails-module-name-Qru-J{display:grid;grid-gap:26px;gap:26px}.PersonalDetails-module-dobBlock-fvjlP{padding-top:10px;display:grid;grid-template-columns:1fr;grid-gap:10px;gap:10px}@media(min-width: 960px){.PersonalDetails-module-dobBlock-fvjlP{grid-template-columns:1fr 2.25fr 1.4fr;gap:20px}}.PersonalDetails-module-dobDay-MM88U,.PersonalDetails-module-dobYear-pykqu{max-width:35%}@media(min-width: 960px){.PersonalDetails-module-dobDay-MM88U,.PersonalDetails-module-dobYear-pykqu{max-width:100%}}.PersonalDetails-module-dobMonth-Rpu3C{max-width:45%}@media(min-width: 960px){.PersonalDetails-module-dobMonth-Rpu3C{max-width:100%}}.PersonalDetails-module-personalFields-BiE2R{display:grid;grid-gap:10px;gap:10px;grid-template-areas:\"label\" \"control\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": "PersonalDetails-module-fields--wqEI",
	"name": "PersonalDetails-module-name-Qru-J",
	"dobBlock": "PersonalDetails-module-dobBlock-fvjlP",
	"dobDay": "PersonalDetails-module-dobDay-MM88U",
	"dobYear": "PersonalDetails-module-dobYear-pykqu",
	"dobMonth": "PersonalDetails-module-dobMonth-Rpu3C",
	"personalFields": "PersonalDetails-module-personalFields-BiE2R"
};
export default ___CSS_LOADER_EXPORT___;
