// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsianAddressForm-module-container-uCArp{display:grid}.AsianAddressForm-module-addressFields-VPTKO{display:grid;grid-gap:24px 20px;gap:24px 20px;grid-template-columns:1fr;grid-template-areas:\"province\" \"city\" \"district\" \"postcode\" \"country\"}@media(min-width: 960px){.AsianAddressForm-module-addressFields-VPTKO{grid-template-columns:repeat(6, 1fr);grid-template-areas:\"district district city city province province\" \"postcode postcode country country . .\"}}.AsianAddressForm-module-addressFields-VPTKO:not(:has(.AsianAddressForm-module-postcode-cEWbC)){grid-template-columns:1fr;grid-template-areas:\"city\" \"country\"}.AsianAddressForm-module-streetName-v\\+FBN{grid-area:streetName}.AsianAddressForm-module-streetNumber-cdRAH{grid-area:streetNumber}.AsianAddressForm-module-streetAdditional-ty\\+DV{grid-area:streetAdditional}.AsianAddressForm-module-postcode-cEWbC{grid-area:postcode}.AsianAddressForm-module-city-AAuZD{grid-area:city}.AsianAddressForm-module-country-JvS0P{grid-area:country}.AsianAddressForm-module-country-JvS0P input[readonly]{background-color:rgba(91,97,94,.1)}.AsianAddressForm-module-district-DQK3H{grid-area:district}.AsianAddressForm-module-province-QrtnK{grid-area:province}.AsianAddressForm-module-actions-Ba9My{margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AsianAddressForm-module-container-uCArp",
	"addressFields": "AsianAddressForm-module-addressFields-VPTKO",
	"postcode": "AsianAddressForm-module-postcode-cEWbC",
	"streetName": "AsianAddressForm-module-streetName-v+FBN",
	"streetNumber": "AsianAddressForm-module-streetNumber-cdRAH",
	"streetAdditional": "AsianAddressForm-module-streetAdditional-ty+DV",
	"city": "AsianAddressForm-module-city-AAuZD",
	"country": "AsianAddressForm-module-country-JvS0P",
	"district": "AsianAddressForm-module-district-DQK3H",
	"province": "AsianAddressForm-module-province-QrtnK",
	"actions": "AsianAddressForm-module-actions-Ba9My"
};
export default ___CSS_LOADER_EXPORT___;
