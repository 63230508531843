// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignOut-module-signoutButton-IcLCy{background-color:rgba(0,0,0,0);display:flex;gap:10px;gap:var(--spacing-3xs, 10px);padding-block:16px;padding-block:var(--spacing-xs, 16px);padding-inline:26px;padding-inline:var(--spacing-65, 26px);justify-content:center;cursor:pointer;align-self:center}.SignOut-module-signoutButton-IcLCy:hover{border-radius:8px;border-radius:var(--radius-md, 8px);background-color:#fcfcfc;background-color:var(--colors-components-card-bg, #fcfcfc)}.SignOut-module-signoutButton-IcLCy:hover .SignOut-module-signoutButtonText-Kty5l{color:#3567ff;color:var(--colors-text-text-brand, #3567ff)}.SignOut-module-signoutButton-IcLCy:hover .SignOut-module-signoutButtonIcon-gS73- svg path{fill:#3567ff;fill:var(--colors-text-text-brand, #3567ff)}.SignOut-module-signoutButton-IcLCy:active{border-radius:8px;border-radius:var(--radius-md, 8px);background:var(--colors-components-card-bg-pressed)}.SignOut-module-signoutButton-IcLCy:active .SignOut-module-signoutButtonText-Kty5l{color:var(--colors-text-text-brand)}.SignOut-module-signoutButton-IcLCy:active .SignOut-module-signoutButtonIcon-gS73- svg path{fill:var(--colors-text-text-brand)}.SignOut-module-signoutButtonText-Kty5l{color:#2d2926;color:var(--colors-text-text-primary, #2d2926);font-size:var(--size-text-small);font-weight:500;font-weight:var(--fonts-weight-medium, 500);line-height:var(--line-height-text-small);letter-spacing:var(--letter-spacing-none)}.SignOut-module-signoutButtonIcon-gS73- svg{fill:var(--colors-foreground-fg-primary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signoutButton": "SignOut-module-signoutButton-IcLCy",
	"signoutButtonText": "SignOut-module-signoutButtonText-Kty5l",
	"signoutButtonIcon": "SignOut-module-signoutButtonIcon-gS73-"
};
export default ___CSS_LOADER_EXPORT___;
