// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MessageLimit-module-regular-gxcC2{display:none;position:relative;width:100%;background:#ffe9d1;border:1px solid #ffe9d1;border-radius:5px;padding-block:20px;padding-inline:24px;opacity:0;transition:all .3s ease}.MessageLimit-module-regular-gxcC2 span{position:absolute;inset-inline-start:20px;top:40px}.MessageLimit-module-regular-gxcC2 h4{font-size:20px;font-weight:500;margin-inline-start:50px;margin-bottom:0}.MessageLimit-module-regular-gxcC2 p{margin-top:0;margin-inline-start:50px;font-family:var(--font-family);color:#373a36;font-weight:300;letter-spacing:0;font-size:17px;line-height:33px;text-align:justify}.MessageLimit-module-compact-yp3aF{display:none;position:relative;width:100%;background:#ffe9d1;border:1px solid #ffe9d1;border-radius:5px;padding-block:20px;padding-inline:24px;margin-block:25px;opacity:0;transition:all .3s ease}.MessageLimit-module-compact-yp3aF span{position:absolute;inset-inline-start:20px;top:40px}.MessageLimit-module-compact-yp3aF h4{font-size:14px;font-weight:600;margin-inline-start:50px;margin-bottom:0}.MessageLimit-module-compact-yp3aF p{margin-top:0;margin-inline-start:50px;font-family:var(--font-family);color:#373a36;font-weight:300;letter-spacing:.15px;font-size:14px;line-height:20px;text-align:justify}.MessageLimit-module-show-xV6AY{opacity:1;display:inline-block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"regular": "MessageLimit-module-regular-gxcC2",
	"compact": "MessageLimit-module-compact-yp3aF",
	"show": "MessageLimit-module-show-xV6AY"
};
export default ___CSS_LOADER_EXPORT___;
