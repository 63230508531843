// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegistrationPolicyCheckFields-module-checkboxGroup-2vSik{display:grid;grid-gap:10px;gap:10px}.RegistrationPolicyCheckFields-module-checkboxLabel-7xH0V{font-weight:300;color:#5b615e;font-size:14px;letter-spacing:.15px;line-height:20px}@media(min-width: 960px){.RegistrationPolicyCheckFields-module-checkboxLabel-7xH0V{font-size:16px;letter-spacing:0;line-height:23px}}.RegistrationPolicyCheckFields-module-checkboxLabel-7xH0V a{text-decoration:none;font-weight:500;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}.RegistrationPolicyCheckFields-module-checkboxLabel-7xH0V a:hover{text-decoration:underline}.RegistrationPolicyCheckFields-module-checkboxLabelMandatory-e95c1{position:relative}.RegistrationPolicyCheckFields-module-checkboxLabelMandatory-e95c1::before{content:\"*\";position:absolute;top:0;inset-inline-start:-10px}.RegistrationPolicyCheckFields-module-checkboxLabelMandatory-e95c1 *::before{content:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": "RegistrationPolicyCheckFields-module-checkboxGroup-2vSik",
	"checkboxLabel": "RegistrationPolicyCheckFields-module-checkboxLabel-7xH0V",
	"checkboxLabelMandatory": "RegistrationPolicyCheckFields-module-checkboxLabelMandatory-e95c1"
};
export default ___CSS_LOADER_EXPORT___;
