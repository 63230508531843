// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes QuickLinks-module-flickerAnimation-hAWhA{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes QuickLinks-module-fadeInAnimation-5eFld{0%{opacity:0}100%{opacity:1}}@keyframes QuickLinks-module-fadeInFromTop-TDael{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes QuickLinks-module-fadeOutToTop-xG5X8{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes QuickLinks-module-fadeInFromLeftAnimation-JwjOL{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes QuickLinks-module-textClip-\\+P7ov{to{background-size:100% 100%}}@keyframes QuickLinks-module-scroll-u6E4G{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes QuickLinks-module-flickerAnimation-hAWhA{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes QuickLinks-module-fadeInAnimation-5eFld{0%{opacity:0}100%{opacity:1}}@keyframes QuickLinks-module-fadeInFromTop-TDael{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes QuickLinks-module-fadeOutToTop-xG5X8{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes QuickLinks-module-fadeInFromLeftAnimation-JwjOL{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes QuickLinks-module-textClip-\\+P7ov{to{background-size:100% 100%}}@keyframes QuickLinks-module-scroll-u6E4G{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.QuickLinks-module-wrapper-r3ngu{padding-block:var(--spacing-xl);padding-inline:var(--spacing-sl);margin-top:var(--spacing-xs);background:var(--colors-background-bg-octonary);border-radius:var(--radius-md);display:flex;flex-direction:column;gap:var(--spacing-lg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QuickLinks-module-wrapper-r3ngu",
	"flickerAnimation": "QuickLinks-module-flickerAnimation-hAWhA",
	"fadeInAnimation": "QuickLinks-module-fadeInAnimation-5eFld",
	"fadeInFromTop": "QuickLinks-module-fadeInFromTop-TDael",
	"fadeOutToTop": "QuickLinks-module-fadeOutToTop-xG5X8",
	"fadeInFromLeftAnimation": "QuickLinks-module-fadeInFromLeftAnimation-JwjOL",
	"textClip": "QuickLinks-module-textClip-+P7ov",
	"scroll": "QuickLinks-module-scroll-u6E4G"
};
export default ___CSS_LOADER_EXPORT___;
