// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponsiveImage-module-responsiveImage-1cB8N{opacity:1;filter:none}.ResponsiveImage-module-responsiveImage-1cB8N.ResponsiveImage-module-loading-qVraz{opacity:.5;filter:blur(5px);transition:opacity .3s,filter .3s}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveImage": "ResponsiveImage-module-responsiveImage-1cB8N",
	"loading": "ResponsiveImage-module-loading-qVraz"
};
export default ___CSS_LOADER_EXPORT___;
