// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrustPilotMicroReviewCount-module-widget-BN8ss{height:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": "TrustPilotMicroReviewCount-module-widget-BN8ss"
};
export default ___CSS_LOADER_EXPORT___;
