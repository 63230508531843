// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MessageSku-module-compact-8PjpY,.MessageSku-module-regular-\\+cUg\\+{display:none;position:relative;width:100%;height:94px;background:#fcfcfc;border:1px solid #fcfcfc;border-radius:5px;padding-block:25px;padding-inline:24px;opacity:0;margin-top:25px;transition:all .3s ease}.MessageSku-module-compact-8PjpY span,.MessageSku-module-regular-\\+cUg\\+ span{position:absolute;inset-inline-start:20px;top:30px}.MessageSku-module-compact-8PjpY p,.MessageSku-module-regular-\\+cUg\\+ p{margin-top:10px;margin-inline-start:50px;font-family:var(--font-family);font-weight:300;letter-spacing:0;font-size:17px;line-height:23px;text-align:justify}@media(max-width: 1070px){.MessageSku-module-compact-8PjpY p,.MessageSku-module-regular-\\+cUg\\+ p{font-size:15px;margin-top:5px}}@media(max-width: 320px){.MessageSku-module-compact-8PjpY p,.MessageSku-module-regular-\\+cUg\\+ p{font-size:12px;margin-top:0}}@media(max-width: 418px){.MessageSku-module-compact-8PjpY,.MessageSku-module-regular-\\+cUg\\+{padding-block:15px;padding-inline:20px}.MessageSku-module-compact-8PjpY p,.MessageSku-module-regular-\\+cUg\\+ p{font-size:13px;margin-top:0}}@media(max-width: 320px){.MessageSku-module-compact-8PjpY,.MessageSku-module-regular-\\+cUg\\+{padding-block:15px;padding-inline:20px}}.MessageSku-module-compact-8PjpY p{margin-top:5px;font-size:15px;line-height:15px;margin-inline-start:35px}@media(min-width: 320px){.MessageSku-module-compact-8PjpY p{font-size:13px;margin-top:0}}.MessageSku-module-show-tL2A5{opacity:1;display:inline-block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compact": "MessageSku-module-compact-8PjpY",
	"regular": "MessageSku-module-regular-+cUg+",
	"show": "MessageSku-module-show-tL2A5"
};
export default ___CSS_LOADER_EXPORT___;
