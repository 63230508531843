// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QrCode-module-wrapper-LoXwK{position:relative;display:inline-block;padding:16px;background-color:#fff;border-radius:8px}.QrCode-module-overlay-aGqOB{display:flex;align-items:center;justify-content:center;flex-direction:column;color:#fff;position:absolute;top:0;inset-inline-start:0;width:100%;height:100%;border-radius:8px;background-color:rgba(55,58,54,.4);-webkit-backdrop-filter:blur(20px);backdrop-filter:blur(20px);padding-block:0;padding-inline:8px}.QrCode-module-errorMessage-GaHOH{font-size:16px;font-weight:500;letter-spacing:0;line-height:18px;margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QrCode-module-wrapper-LoXwK",
	"overlay": "QrCode-module-overlay-aGqOB",
	"errorMessage": "QrCode-module-errorMessage-GaHOH"
};
export default ___CSS_LOADER_EXPORT___;
