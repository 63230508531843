// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ValidationMethods-module-verificationImage-9n1rs{display:block;height:auto;margin-top:24px;margin-bottom:20px;width:100%}.ValidationMethods-module-formContainer-0TBTx{margin-bottom:40px}.ValidationMethods-module-methodsRow-dRMRT{display:flex;flex-wrap:wrap}.ValidationMethods-module-identificationLabelContainer-m9ckL{margin-top:20px;margin-bottom:10px}.ValidationMethods-module-actionContainer-bZg2h{display:flex;justify-content:center;margin-top:30px}.ValidationMethods-module-methodsTitle-Or5Ko{color:#5c615e}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationImage": "ValidationMethods-module-verificationImage-9n1rs",
	"formContainer": "ValidationMethods-module-formContainer-0TBTx",
	"methodsRow": "ValidationMethods-module-methodsRow-dRMRT",
	"identificationLabelContainer": "ValidationMethods-module-identificationLabelContainer-m9ckL",
	"actionContainer": "ValidationMethods-module-actionContainer-bZg2h",
	"methodsTitle": "ValidationMethods-module-methodsTitle-Or5Ko"
};
export default ___CSS_LOADER_EXPORT___;
