// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComponentHeading-module-heading-IvVxi{width:100%;color:#666b69;font-weight:500;line-height:23px;text-align:center;text-transform:uppercase;font-size:12px;letter-spacing:5.33px;margin-top:80px;margin-bottom:32px}@media(min-width: 960px){.ComponentHeading-module-heading-IvVxi{font-size:16px;letter-spacing:7.11px;margin-top:108px}}@media(min-width: 1280px){.ComponentHeading-module-heading-IvVxi{margin-top:104px}}[dir=rtl] .ComponentHeading-module-heading-IvVxi{letter-spacing:normal}.ComponentHeading-module-noTopMargin-Ul0\\+s{margin-top:0}.ComponentHeading-module-thin-\\+lAfE{font-weight:300}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "ComponentHeading-module-heading-IvVxi",
	"noTopMargin": "ComponentHeading-module-noTopMargin-Ul0+s",
	"thin": "ComponentHeading-module-thin-+lAfE"
};
export default ___CSS_LOADER_EXPORT___;
