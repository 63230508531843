import { FC, memo, useCallback, useEffect, useState } from "react";

import { ICartItemErrorType, Product as CartItem } from "@jti/magento";
import { usePdpLink } from "@jti/rest-api";
import { useSelector } from "@jti/store";
import { Card, Price, ProductMiniature, QuantityInput, Separator, useTranslations } from "@jti/ui";

import { hasCartErrorCode } from "@checkout/utils/getCartErrors";

import { usePromotionalOffers } from "../../../hooks/usePromotionalOffers";
import RemoveItemDialog from "../../RemoveItemDialog";
import { CartLimitError } from "../CartLimitError";
import ProductDescription from "../ProductDescription";
import ProductPoints from "../ProductPoints";
import { RemoveBtn } from "../RemoveBtn";
import { SubscriptionItemFlag } from "../SubscriptionItemFlag";

import styles from "./RegularItem.module.scss";

export type RegularItemProps = {
  item: CartItem;
  qtyPending?: boolean;
  blockQuantityAdd?: boolean;
  isLimitEnabled?: boolean;
  onRemoveClick(id: string | undefined): void;
  onQuantityChange(id: string | undefined, quantity: number): void;
};

export const RegularItem: FC<RegularItemProps> = ({
  qtyPending = false,
  item,
  blockQuantityAdd = false,
  onRemoveClick,
  onQuantityChange,
}) => {
  const { __ } = useTranslations();

  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
  const {
    id,
    sku,
    imageUrl,
    name,
    quantity,
    price,
    description,
    status,
    points,
    enable_order_quantity_limit,
    order_quantity_limit,
    promotionalOffers,
    errors,
  } = item;
  const [limitMessageStatus, setLimitMessageStatus] = useState(false);
  const [isQuantityLimitReached, setIsQuantityLimitReached] = useState(false);
  const grandTotal = useSelector((state) => state.cart.prices?.grandTotal.value) ?? 0;
  const shipping = useSelector((state) => state.cart.prices?.shipping?.value) ?? 0;
  const maxOrderLimit = useSelector((state) => state.cart.storeConfig?.order_limit_value);
  const orderLimitEnabled = useSelector((state) => state.cart.storeConfig?.is_order_limit_enabled);
  const pdpLink = usePdpLink(sku);
  const isOutOfStock = (errors && hasCartErrorCode(errors, ICartItemErrorType.ItemQty)) || status === "OUT_OF_STOCK";
  const isCartonsLimitReached = (errors && hasCartErrorCode(errors, ICartItemErrorType.CartonsLimit)) || false;
  const {
    isLimitReached: isSpecialOfferLimitReached,
    hasPromotionalOffers,
    promotionalOfferLimit,
  } = usePromotionalOffers(quantity, promotionalOffers);
  const isSubscriptionItem = item.subscription === 1;
  const quantityOrderLimit = {
    isLimit: hasPromotionalOffers ? 1 : 0,
    quantityLimit: hasPromotionalOffers ? promotionalOfferLimit : Infinity,
  };

  if (enable_order_quantity_limit) {
    quantityOrderLimit.isLimit = hasPromotionalOffers ? 1 : order_quantity_limit;
    quantityOrderLimit.quantityLimit = hasPromotionalOffers
      ? Math.min(promotionalOfferLimit, order_quantity_limit)
      : order_quantity_limit;
  }

  useEffect(() => {
    const skuLimitReached = quantity >= order_quantity_limit && enable_order_quantity_limit === 1;
    const specialOfferLimitReached = hasPromotionalOffers && isSpecialOfferLimitReached;

    setIsQuantityLimitReached(skuLimitReached || specialOfferLimitReached);
  }, [quantity, hasPromotionalOffers, isSpecialOfferLimitReached, enable_order_quantity_limit, order_quantity_limit]);

  useEffect(() => {
    setLimitMessageStatus(grandTotal + price.value / quantity - shipping > maxOrderLimit);
  }, [grandTotal]);

  const showRemoveItemDialog = useCallback(() => {
    setIsOpenRemoveDialog(true);
  }, [setIsOpenRemoveDialog]);

  const handleOnCancel = useCallback(() => {
    setIsOpenRemoveDialog(false);
  }, [setIsOpenRemoveDialog]);

  const handleOnRemove = useCallback(() => {
    onRemoveClick(id);
    handleOnCancel();
  }, [id, onRemoveClick, handleOnCancel]);

  const handleQuantityChange = useCallback(
    (value: number) => {
      onQuantityChange(id, value);
    },
    [id, onQuantityChange]
  );

  return (
    <Card variant="variant1">
      <div className={styles.rows}>
        <div className={styles.product}>
          <ProductMiniature url={pdpLink} name={name} imageUrl={imageUrl} outOfStock={isOutOfStock}>
            {isSubscriptionItem && <SubscriptionItemFlag className={styles.subscriptionProductFlag} />}
          </ProductMiniature>
        </div>
        {isSubscriptionItem && <SubscriptionItemFlag className={styles.subscriptionProductFlag} />}
        <div className={styles.sideways}>
          <div className={styles.actions}>
            <RemoveBtn onClick={showRemoveItemDialog} />
          </div>
          <Price
            price={price}
            className={styles.price}
            frequency={isSubscriptionItem ? __("cart.cart_item.subscription_billing_frequency") : undefined}
          />
        </div>
        <div className={styles.separator}>
          <Separator />
        </div>
        <ProductPoints pointsQuantity={points || 0} />
        <div>
          <ProductDescription description={description} />
        </div>
        <div className={styles.quantity}>
          <QuantityInput
            value={quantity}
            disabled={isOutOfStock}
            disabledAdd={blockQuantityAdd || isQuantityLimitReached}
            hoverLimitDisplay={limitMessageStatus}
            productLimitCurrency={price.currency}
            maxOrderLimit={maxOrderLimit}
            quantityOrderLimit={quantityOrderLimit}
            disabledMessage={__("cart.cart_item.out_of_stock")}
            onChange={handleQuantityChange}
            pending={qtyPending}
            orderLimitEnabled={orderLimitEnabled}
          />
        </div>
      </div>

      <CartLimitError
        isCartonsLimitReached={isCartonsLimitReached}
        isQuantityLimitReached={isQuantityLimitReached}
        quantityLimit={quantityOrderLimit.quantityLimit}
      />

      <RemoveItemDialog
        item={item}
        isOpenDialog={isOpenRemoveDialog}
        onRemove={handleOnRemove}
        onCancel={handleOnCancel}
      />
    </Card>
  );
};

export default memo(RegularItem);
