// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductMiniature-module-container-W0yC5{display:grid;grid-template-columns:auto 1fr;align-items:flex-start;position:relative;z-index:0}.ProductMiniature-module-image-84amx{width:100px;height:100px;background-repeat:no-repeat;background-size:contain;background-position:center}.ProductMiniature-module-compactImage-uQyn6{width:65px;height:65px}.ProductMiniature-module-name-h-hJP{margin-inline-start:20px}.ProductMiniature-module-name-h-hJP h2{margin-top:0;margin-bottom:10px}.ProductMiniature-module-name-h-hJP a{text-decoration:none}.ProductMiniature-module-productName-cu7Vu{font-weight:300;font-size:22px;line-height:24px;color:#373a36}@media(min-width: 960px){.ProductMiniature-module-productName-cu7Vu{font-size:20px;line-height:24px}}.ProductMiniature-module-outOfStock-dj8TF{margin-top:7px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProductMiniature-module-container-W0yC5",
	"image": "ProductMiniature-module-image-84amx",
	"compactImage": "ProductMiniature-module-compactImage-uQyn6",
	"name": "ProductMiniature-module-name-h-hJP",
	"productName": "ProductMiniature-module-productName-cu7Vu",
	"outOfStock": "ProductMiniature-module-outOfStock-dj8TF"
};
export default ___CSS_LOADER_EXPORT___;
