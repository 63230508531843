// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponsiveContainer-module-responsiveContainer-ecpub{position:relative;width:100%;margin:auto}@media(min-width: 768px){.ResponsiveContainer-module-responsiveContainer-ecpub{padding:0;margin-block:0;margin-inline:auto;max-width:1260px}}.ResponsiveContainer-module-fullWidth-CsCwU{padding:0;max-width:none;max-width:initial;margin:0;margin:initial}.ResponsiveContainer-module-small-Q\\+RX3{max-width:620px}.ResponsiveContainer-module-medium-6oQFh{max-width:940px}.ResponsiveContainer-module-large-e1Hiu{max-width:1260px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveContainer": "ResponsiveContainer-module-responsiveContainer-ecpub",
	"fullWidth": "ResponsiveContainer-module-fullWidth-CsCwU",
	"small": "ResponsiveContainer-module-small-Q+RX3",
	"medium": "ResponsiveContainer-module-medium-6oQFh",
	"large": "ResponsiveContainer-module-large-e1Hiu"
};
export default ___CSS_LOADER_EXPORT___;
