/* istanbul ignore file */

import { createRoot } from "react-dom/client";

import { waitFor } from "@jti/fe-common";

import { getGlobalStore } from "../../utils/globalStore";

import { CartMiniLayout } from "./CartMiniLayout";

import "@jti/ui/styles/base.scss";

// We check for Cart store in global scope and use it
// if we haven't found global store - we will use local one
waitFor(
  () => Boolean(getGlobalStore()),
  (resolvedByTest) => {
    const container = document.querySelector("[data-cart-mini-container]");
    const root = createRoot(container!);

    if (container) {
      root.render(<CartMiniLayout globalStoreResolved={resolvedByTest} />);
    }
  }
);
