import { FC } from "react";

import { MessageBox, MessageBoxVariant, useTranslations } from "@jti/ui";

import styles from "./CartLimitError.module.scss";

export type CartLimitErrorProps = {
  isCartonsLimitReached: boolean;
  isQuantityLimitReached: boolean;
  quantityLimit: number;
  className?: string;
};

export const CartLimitError: FC<CartLimitErrorProps> = ({
  isCartonsLimitReached,
  isQuantityLimitReached,
  quantityLimit,
  className,
}) => {
  const { __: translate } = useTranslations();
  return (
    <div className={className}>
      <MessageBox isVisible={isCartonsLimitReached} variant={MessageBoxVariant.warning} className={styles.warning}>
        <>{translate("cart.items.subscription_limit_reached_message_box.singleIntro")}</>
      </MessageBox>

      <MessageBox isVisible={isQuantityLimitReached} variant={MessageBoxVariant.warning} className={styles.warning}>
        <>
          {translate("libs.ui.notification.limit.sku")} ({quantityLimit})
        </>
      </MessageBox>
    </div>
  );
};
