import { FC, useCallback, useEffect, useState } from "react";

import classNames from "classnames";

import { __ } from "@jti/fe-common";
import { useMessageBus } from "@jti/fe-message-bus";
import { areBundleOptionsFromCart, ICartItemErrorType } from "@jti/magento";
import { usePdpLink } from "@jti/rest-api";
import { useSelector } from "@jti/store";
import { Card, MessageBox, MessageBoxVariant, OutOfStock, Price, QuantityInput } from "@jti/ui";

import { hasCartErrorCode } from "@checkout/utils/getCartErrors";

import { usePromotionalOffers } from "../../../hooks/usePromotionalOffers";
import { BundleItemProps } from "../BundleItem";
import { RemoveBtn } from "../RemoveBtn";

import CompactBundleOption from "./CompactBundleOption";

import styles from "./CompactBundleItem.module.scss";

export const CompactBundleItem: FC<BundleItemProps> = ({
  qtyPending = false,
  item,
  blockQuantityAdd = false,
  onRemoveClick,
  onQuantityChange,
}) => {
  const {
    id,
    sku,
    name,
    quantity,
    price,
    status,
    enable_order_quantity_limit,
    order_quantity_limit,
    promotionalOffers,
    subscription = 0,
  } = item;
  const messageBus = useMessageBus();
  const [limitMessageStatus, setLimitMessageStatus] = useState(false);
  const [isQuantityLimitReached, setIsQuantityLimitReached] = useState(false);
  const grandTotal = useSelector((state) => state.cart.prices?.grandTotal.value) ?? 0;
  const shipping = useSelector((state) => state.cart.prices?.shipping?.value) ?? 0;
  const maxOrderLimit = useSelector((state) => state.cart.storeConfig?.order_limit_value);
  const orderLimitEnabled = useSelector((state) => state.cart.storeConfig?.is_order_limit_enabled);
  const pdpLink = usePdpLink(sku);
  const isOutOfStock =
    (item.errors && hasCartErrorCode(item.errors, ICartItemErrorType.ItemQty)) || status === "OUT_OF_STOCK";
  const {
    isLimitReached: isPromotionalOfferLimitReached,
    hasPromotionalOffers,
    promotionalOfferLimit,
  } = usePromotionalOffers(quantity, promotionalOffers);
  const quantityOrderLimit = {
    isLimit: hasPromotionalOffers ? 1 : 0,
    quantityLimit: hasPromotionalOffers ? promotionalOfferLimit : Infinity,
  };
  const isSubscriptionItem = subscription === 1;

  if (enable_order_quantity_limit) {
    quantityOrderLimit.isLimit = hasPromotionalOffers ? 1 : order_quantity_limit;
    quantityOrderLimit.quantityLimit = hasPromotionalOffers
      ? Math.min(promotionalOfferLimit, order_quantity_limit)
      : order_quantity_limit;
  }

  useEffect(() => {
    const skuLimitReached = quantity >= order_quantity_limit && enable_order_quantity_limit === 1;
    const specialOfferLimitReached = hasPromotionalOffers && isPromotionalOfferLimitReached;

    setIsQuantityLimitReached(skuLimitReached || specialOfferLimitReached);

    messageBus.publish("cart.limit.update", {
      payload: {
        sku,
        quantity,
        limit: quantityOrderLimit.quantityLimit,
        quantityLimitReached: skuLimitReached || specialOfferLimitReached,
      },
    });
  }, [
    enable_order_quantity_limit,
    hasPromotionalOffers,
    isPromotionalOfferLimitReached,
    order_quantity_limit,
    quantity,
  ]);

  useEffect(() => {
    setLimitMessageStatus(grandTotal + price.value / quantity - shipping > maxOrderLimit);
  }, [grandTotal, price, shipping, maxOrderLimit, quantity]);

  const handleOnRemove = useCallback(() => {
    onRemoveClick(id);
  }, [id, onRemoveClick]);

  const handleQuantityChange = useCallback(
    (value: number) => {
      onQuantityChange(id, value);
    },
    [id, onQuantityChange]
  );

  const renderTitle = (_name: string) => (
    <strong className={styles.bundleName} data-testid="compactBundleName">
      {_name}
    </strong>
  );

  return (
    <Card variant="variant1">
      <div className={styles.rows}>
        <div className={styles.product}>
          <div className={styles.productName}>
            {pdpLink ? <a href={pdpLink}>{renderTitle(name)}</a> : renderTitle(name)}
          </div>
        </div>

        {/* 
        // disabled due to the request: DESP-68593
        
        <div className={styles.productPoints}>
          <ProductPoints pointsQuantity={points || 0} />
        </div>
        */}

        <Price
          price={price}
          className={classNames(styles.price, { [styles.subscriptionPrice]: isSubscriptionItem })}
          frequency={isSubscriptionItem ? __("cart.cart_item.subscription_billing_frequency") : undefined}
        />

        <div className={styles.quantity}>
          <QuantityInput
            value={quantity}
            disabled={status === "OUT_OF_STOCK"}
            disabledAdd={blockQuantityAdd || isQuantityLimitReached}
            hoverLimitDisplay={limitMessageStatus}
            productLimitCurrency={price.currency}
            maxOrderLimit={maxOrderLimit}
            quantityOrderLimit={quantityOrderLimit}
            disabledMessage={__("cart.cart_item.out_of_stock")}
            onChange={handleQuantityChange}
            pending={qtyPending}
            orderLimitEnabled={orderLimitEnabled}
          />
        </div>
      </div>

      {isOutOfStock && <OutOfStock hideIcon={true} />}

      <MessageBox isVisible={isQuantityLimitReached} variant={MessageBoxVariant.warning} className={styles.warning}>
        <>
          {__("libs.ui.notification.limit.sku")} ({quantityOrderLimit.quantityLimit})
        </>
      </MessageBox>

      <ul className={styles.bundleOptionsContainer}>
        {item?.bundle_options?.map(
          ({ values, uid, label }) =>
            values &&
            areBundleOptionsFromCart(values) && (
              <CompactBundleOption key={`${sku} - ${label} - ${uid}}`} categoryName={label} values={values} />
            )
        )}
      </ul>

      <div className={styles.actions}>
        <RemoveBtn onClick={handleOnRemove} />
      </div>
    </Card>
  );
};
