import { FC } from "react";

import { BundleOptionProps } from "../BundleItem/BundleOption";

import CompactBundleOptionValue from "./CompactBundleOptionValue";

const CompactBundleOption: FC<BundleOptionProps> = ({ categoryName, values }) => (
  <>
    {values.map((option) => {
      const { initial_price = option.price, presentation_price, product } = option?.presentation_info || {};
      const product_name = values.length > 1 ? `${categoryName}: ${option.label}` : option.label;

      return (
        <CompactBundleOptionValue
          key={`${categoryName} - ${option.label}`}
          prices={{ initial_price, presentation_price }}
          quantity={option.quantity}
          imageUrl={product?.small_image?.url}
          product_name={product_name}
        />
      );
    })}
  </>
);

export default CompactBundleOption;
