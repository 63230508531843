// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HardAgeVerificationPopup-module-dialog-FPc9V{padding:18px;max-width:850px}@media(min-width: 960px){.HardAgeVerificationPopup-module-dialog-FPc9V{overflow:hidden;padding:0}}.HardAgeVerificationPopup-module-dialogContainer-L8SJu{padding-top:20px;padding-bottom:20px}.HardAgeVerificationPopup-module-container-OB9N\\+{display:flex;position:relative;flex-direction:column}@media(min-width: 960px){.HardAgeVerificationPopup-module-container-OB9N\\+{padding:0;flex-direction:row}}.HardAgeVerificationPopup-module-wrapper-138dF{display:flex;flex-direction:column;align-items:center;justify-content:center}@media(min-width: 960px){.HardAgeVerificationPopup-module-wrapper-138dF{padding-block:65px 30px;padding-inline:55px 80px;flex:1 1;align-items:flex-start}}.HardAgeVerificationPopup-module-logo-RHW2T{margin-bottom:16px;max-width:75px}@media(min-width: 960px){.HardAgeVerificationPopup-module-logo-RHW2T{margin-bottom:24px}}.HardAgeVerificationPopup-module-title-mSxS8{font-size:26px;line-height:44px;color:#373a36;text-align:center;margin-bottom:16px}@media(min-width: 960px){.HardAgeVerificationPopup-module-title-mSxS8{font-size:32px;margin-bottom:18px;text-align:start}}.HardAgeVerificationPopup-module-description-3lcSU{font-weight:300;color:#373a36;margin-bottom:16px;font-size:14px;line-height:23px;text-align:center}@media(min-width: 960px){.HardAgeVerificationPopup-module-description-3lcSU{font-size:16px;margin-bottom:18px;text-align:start}}.HardAgeVerificationPopup-module-legalNotice-ORoeT{font-size:12px;font-weight:300;line-height:16px;color:#5c615e;padding-top:12px;border-top:1px solid #d2d2d2;text-align:center}@media(min-width: 960px){.HardAgeVerificationPopup-module-legalNotice-ORoeT{text-align:start}}.HardAgeVerificationPopup-module-functionalContainer-uEAc8{position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "HardAgeVerificationPopup-module-dialog-FPc9V",
	"dialogContainer": "HardAgeVerificationPopup-module-dialogContainer-L8SJu",
	"container": "HardAgeVerificationPopup-module-container-OB9N+",
	"wrapper": "HardAgeVerificationPopup-module-wrapper-138dF",
	"logo": "HardAgeVerificationPopup-module-logo-RHW2T",
	"title": "HardAgeVerificationPopup-module-title-mSxS8",
	"description": "HardAgeVerificationPopup-module-description-3lcSU",
	"legalNotice": "HardAgeVerificationPopup-module-legalNotice-ORoeT",
	"functionalContainer": "HardAgeVerificationPopup-module-functionalContainer-uEAc8"
};
export default ___CSS_LOADER_EXPORT___;
