// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionButton-module-button-BMgPf{background-color:rgba(0,0,0,0);cursor:pointer;padding:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ActionButton-module-button-BMgPf"
};
export default ___CSS_LOADER_EXPORT___;
