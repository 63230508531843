import { useEffect, useState } from "react";

import { Product as CartItem } from "@jti/magento";

const useLoyaltyProductPoints = (items: CartItem[] = []): number => {
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    setTotalPoints(items.reduce((prev: number, { points }: CartItem) => prev + (points || 0), 0));
  }, [items]);

  return totalPoints;
};

export default useLoyaltyProductPoints;
