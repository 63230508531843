// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScanQrCode-module-scanQrContainer-RYgkw{display:block}@media(min-width: 960px){.ScanQrCode-module-scanQrContainer-RYgkw{display:none}}.ScanQrCode-module-scanQrButton-R0vFI{position:relative;width:100%}.ScanQrCode-module-scanQrIcon-2p8iD{position:absolute;color:#fff;inset-inline-start:10%;top:50%;transform:translate(-50%, -50%)}[dir=rtl] .ScanQrCode-module-scanQrIcon-2p8iD{transform:translate(50%, -50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scanQrContainer": "ScanQrCode-module-scanQrContainer-RYgkw",
	"scanQrButton": "ScanQrCode-module-scanQrButton-R0vFI",
	"scanQrIcon": "ScanQrCode-module-scanQrIcon-2p8iD"
};
export default ___CSS_LOADER_EXPORT___;
