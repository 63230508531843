// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconBanner-module-iconBanner-SraE9{display:flex;gap:20px;flex-direction:column;align-items:center;justify-content:center;flex:1 0;text-align:center}@media(max-width: 768px){.IconBanner-module-iconSmall-py5JR{flex-direction:row;text-align:start;gap:30px}}.IconBanner-module-iconImage-CdtYs{width:60px;height:60px}.IconBanner-module-iconLarge-vFvGK{aspect-ratio:1;border-radius:8px;border:solid 1px rgba(0,0,0,.2);padding:20px}@media(max-width: 768px){.IconBanner-module-iconLarge-vFvGK{gap:20px;text-align:center}}@media(min-width: 768px){.IconBanner-module-iconLarge-vFvGK .IconBanner-module-iconTitle-RKsK8{line-height:38px}}.IconBanner-module-iconLarge-vFvGK .IconBanner-module-iconDescription-9Wxf-{opacity:.7;line-height:23px}@media(max-width: 768px){.IconBanner-module-iconLarge-vFvGK .IconBanner-module-iconDescription-9Wxf-{font-size:16px}}.IconBanner-module-iconXLarge-qOwqt{gap:initial;align-items:initial;max-width:404px;width:100%;text-align:start}@media(max-width: 1440px){.IconBanner-module-iconXLarge-qOwqt{max-width:298px}}.IconBanner-module-iconXLarge-qOwqt .IconBanner-module-iconTitle-RKsK8{margin-bottom:20px;text-transform:uppercase;letter-spacing:2.4px;font-size:12px;line-height:normal;color:#3a3d3f}.IconBanner-module-iconXLarge-qOwqt .IconBanner-module-iconDescription-9Wxf-{line-height:38px}@media(max-width: 1440px){.IconBanner-module-iconXLarge-qOwqt .IconBanner-module-iconDescription-9Wxf-{font-size:20px;line-height:33px}}.IconBanner-module-iconXLarge-qOwqt .IconBanner-module-iconSection-wYfBK{height:158px;margin-bottom:25px;overflow:hidden;border-radius:8px;background-image:var(--imgSrc);background-repeat:no-repeat;background-size:cover;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBanner": "IconBanner-module-iconBanner-SraE9",
	"iconSmall": "IconBanner-module-iconSmall-py5JR",
	"iconImage": "IconBanner-module-iconImage-CdtYs",
	"iconLarge": "IconBanner-module-iconLarge-vFvGK",
	"iconTitle": "IconBanner-module-iconTitle-RKsK8",
	"iconDescription": "IconBanner-module-iconDescription-9Wxf-",
	"iconXLarge": "IconBanner-module-iconXLarge-qOwqt",
	"iconSection": "IconBanner-module-iconSection-wYfBK"
};
export default ___CSS_LOADER_EXPORT___;
