// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SingleFieldLookupAddressForm-module-container-GZNm7{display:flex;flex-direction:column}.SingleFieldLookupAddressForm-module-inputContainer-ll-IT{margin-bottom:24px}.SingleFieldLookupAddressForm-module-inputContainer-ll-IT:last-child{margin-bottom:0}.SingleFieldLookupAddressForm-module-postcodeInput-7x4vx:read-only{pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SingleFieldLookupAddressForm-module-container-GZNm7",
	"inputContainer": "SingleFieldLookupAddressForm-module-inputContainer-ll-IT",
	"postcodeInput": "SingleFieldLookupAddressForm-module-postcodeInput-7x4vx"
};
export default ___CSS_LOADER_EXPORT___;
