// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountInput-module-countInput-2LV5e{display:flex;align-items:center;gap:7px}.CountInput-module-countInputButton-GxzAq{border:1px solid #858785;border-radius:50%;width:28px;height:28px;display:flex;justify-content:center;align-items:center;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;background-color:rgba(0,0,0,0);transition:all .2s}.CountInput-module-countInputButton-GxzAq:hover,.CountInput-module-countInputButton-GxzAq:focus{border-color:#000}.CountInput-module-countInputButton-GxzAq:disabled,.CountInput-module-countInputButton-GxzAq[aria-disabled=true]{border-style:dashed;border-color:#373a36;opacity:.6;cursor:not-allowed}.CountInput-module-countInputButton-GxzAq:disabled:hover,.CountInput-module-countInputButton-GxzAq:disabled:focus,.CountInput-module-countInputButton-GxzAq[aria-disabled=true]:hover,.CountInput-module-countInputButton-GxzAq[aria-disabled=true]:focus{border-color:#373a36}.CountInput-module-countInputValue-p5mm1{border:1px solid #2d2926;border-radius:8px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;-webkit-user-select:none;-ms-user-select:none;user-select:none;font-size:16px;font-weight:300}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countInput": "CountInput-module-countInput-2LV5e",
	"countInputButton": "CountInput-module-countInputButton-GxzAq",
	"countInputValue": "CountInput-module-countInputValue-p5mm1"
};
export default ___CSS_LOADER_EXPORT___;
