// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadMore-module-button-AhEDJ{display:inline-block;padding-block:10px;padding-inline:10px 40px;position:relative;color:#5b615e;text-decoration:none;font-size:16px;font-weight:300;line-height:1.4;text-align:center;white-space:nowrap;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;background-color:rgba(0,0,0,0);border:none}.LoadMore-module-button-AhEDJ:hover{color:#424745}.LoadMore-module-button-AhEDJ:hover::after{border-color:#3567ff;border-color:var(--colors-accent-accent-100, #3567ff)}.LoadMore-module-button-AhEDJ:disabled{pointer-events:none;opacity:.5}.LoadMore-module-button-AhEDJ::after{content:\"\";position:absolute;margin-top:3px;inset-inline-end:0;width:9px;height:9px;border-inline-end:2px solid #000;border-bottom:2px solid #000;transform:rotate(45deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "LoadMore-module-button-AhEDJ"
};
export default ___CSS_LOADER_EXPORT___;
