// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreLocatorMap-module-mapWrapper-CKe66{width:100%;height:315px}@media(min-width: 960px){.StoreLocatorMap-module-mapWrapper-CKe66{position:relative;height:100%}}.StoreLocatorMap-module-map-iSmV-{width:100%;height:100%;border-radius:8px;background-color:#adb0ae;z-index:2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapWrapper": "StoreLocatorMap-module-mapWrapper-CKe66",
	"map": "StoreLocatorMap-module-map-iSmV-"
};
export default ___CSS_LOADER_EXPORT___;
