// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 960px){.PrizesList-module-wrapper-lLNVt{padding-inline-end:5px;min-width:305px;margin-top:0;display:block;overflow-y:scroll;height:740px;scrollbar-width:thin;scrollbar-color:#131110 rgba(0,0,0,0)}.PrizesList-module-wrapper-lLNVt::-webkit-scrollbar-button{display:none}}.PrizesList-module-loader-4fZ6h{margin:auto}.PrizesList-module-prizesList-82q0M{display:flex;flex-direction:column;gap:25px}.PrizesList-module-venueName-kRz6N{font-size:32px;line-height:44px}.PrizesList-module-backButton--bs4k[data-variant=linky]:hover{text-decoration:none}.PrizesList-module-venueDetails-cahPC{display:flex;align-items:flex-start;flex-direction:column;gap:16px}@media(min-width: 960px){.PrizesList-module-venueDetails-cahPC{max-width:305px}}.PrizesList-module-prizes-zZE3o{display:flex;flex-direction:column;gap:25px}@media(max-width: 959px){.PrizesList-module-mapView-ncGCw{padding:23px;border-radius:8px;border:2px solid #3567ff}.PrizesList-module-mapView-ncGCw button{display:none}}.PrizesList-module-noOffers-CfpLg{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PrizesList-module-wrapper-lLNVt",
	"loader": "PrizesList-module-loader-4fZ6h",
	"prizesList": "PrizesList-module-prizesList-82q0M",
	"venueName": "PrizesList-module-venueName-kRz6N",
	"backButton": "PrizesList-module-backButton--bs4k",
	"venueDetails": "PrizesList-module-venueDetails-cahPC",
	"prizes": "PrizesList-module-prizes-zZE3o",
	"mapView": "PrizesList-module-mapView-ncGCw",
	"noOffers": "PrizesList-module-noOffers-CfpLg"
};
export default ___CSS_LOADER_EXPORT___;
