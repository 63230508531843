// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleLoader-module-svgContainer-1pYjf{transform-origin:center;animation:CircleLoader-module-rotate-C\\+Nvo 2s linear infinite}.CircleLoader-module-circleGrey-QaPSh{fill:none;stroke:#e4e4e4;stroke-width:4}.CircleLoader-module-circleBlack-S3Dix{fill:none;stroke:#000;stroke-width:4;stroke-dasharray:1,200;stroke-dashoffset:0;stroke-linecap:round;animation:CircleLoader-module-dash-VO0d3 1.5s ease-in-out infinite}@keyframes CircleLoader-module-rotate-C\\+Nvo{100%{transform:rotate(360deg)}}@keyframes CircleLoader-module-dash-VO0d3{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:90,200;stroke-dashoffset:-35px}100%{stroke-dashoffset:-125px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgContainer": "CircleLoader-module-svgContainer-1pYjf",
	"rotate": "CircleLoader-module-rotate-C+Nvo",
	"circleGrey": "CircleLoader-module-circleGrey-QaPSh",
	"circleBlack": "CircleLoader-module-circleBlack-S3Dix",
	"dash": "CircleLoader-module-dash-VO0d3"
};
export default ___CSS_LOADER_EXPORT___;
