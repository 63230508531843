// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgeVerificationDialog-module-headline-YeZck{color:#373a36;text-align:center;font-size:26px;line-height:1.5;margin-bottom:14px}@media(min-width: 768px){.AgeVerificationDialog-module-headline-YeZck{font-size:32px}}.AgeVerificationDialog-module-container-go5wO{padding-block:0;padding-inline:5px}.AgeVerificationDialog-module-container-go5wO a{text-decoration:none;font-weight:500;color:#3567ff}.AgeVerificationDialog-module-container-go5wO a:hover{text-decoration:underline}.AgeVerificationDialog-module-container-go5wO a:visited{color:#3567ff}@media(min-width: 768px){.AgeVerificationDialog-module-container-go5wO{padding-block:0;padding-inline:56px}}@media(max-width: 600px){.AgeVerificationDialog-module-container-go5wO .AgeVerificationDialog-module-submitButton-HP8pi button{width:100%;min-height:40px}}.AgeVerificationDialog-module-textBlock-zq977{text-align:center;margin-bottom:10px;font-size:14px}@media(min-width: 768px){.AgeVerificationDialog-module-textBlock-zq977{font-size:16px}}.AgeVerificationDialog-module-closeIcon-0VbQr{top:15px;inset-inline-end:15px}.AgeVerificationDialog-module-closeIcon-0VbQr svg{width:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "AgeVerificationDialog-module-headline-YeZck",
	"container": "AgeVerificationDialog-module-container-go5wO",
	"submitButton": "AgeVerificationDialog-module-submitButton-HP8pi",
	"textBlock": "AgeVerificationDialog-module-textBlock-zq977",
	"closeIcon": "AgeVerificationDialog-module-closeIcon-0VbQr"
};
export default ___CSS_LOADER_EXPORT___;
