// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextInput-module-input-N7mkl{padding:0;width:100%;font-family:inherit;font-size:16px;font-size:var(--size-text-md, 16px);font-weight:var(--fonts-weight-light);line-height:22px;line-height:var(--line-height-text-md, 22px);letter-spacing:0;letter-spacing:var(--letter-spacing-none, 0);border:none;background-color:rgba(0,0,0,0)}.TextInput-module-input-N7mkl:read-only{text-overflow:ellipsis}.TextInput-module-input-N7mkl:-ms-input-placeholder{color:#7c7c7c;color:var(--colors-text-text-quartery_fixed, #7c7c7c)}.TextInput-module-input-N7mkl::placeholder{color:#7c7c7c;color:var(--colors-text-text-quartery_fixed, #7c7c7c)}.TextInput-module-iconSuccess-OK6zX{color:#4c8978;color:var(--colors-semantics-green-mid, #4c8978)}.TextInput-module-editButton-8JbVY{padding:0;border:none;cursor:pointer;background-color:rgba(0,0,0,0);font-size:14px;font-size:var(--size-text-small, 14px);font-weight:500;font-weight:var(--weight-medium, 500);color:#3567ff;color:var(--colors-text-text-brand, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "TextInput-module-input-N7mkl",
	"iconSuccess": "TextInput-module-iconSuccess-OK6zX",
	"editButton": "TextInput-module-editButton-8JbVY"
};
export default ___CSS_LOADER_EXPORT___;
