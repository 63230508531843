import { RefObject } from "react";

const getElementHeightWithMargins = (element: RefObject<HTMLDivElement>): number => {
  const currentElement = element.current;

  if (!currentElement) {
    return 0;
  }

  const style = window.getComputedStyle(currentElement);

  const height = currentElement.offsetHeight;

  const marginTop = parseFloat(style.marginTop);
  const marginBottom = parseFloat(style.marginBottom);

  return height + marginTop + marginBottom;
};

export default getElementHeightWithMargins;
