// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactFields-module-fields-AHEQL{align-items:baseline;display:grid;grid-gap:20px;gap:20px;grid-template-columns:1fr}@media(min-width: 960px){.ContactFields-module-fields-AHEQL{grid-template-columns:1fr 1fr 1.2fr}.ContactFields-module-fields-AHEQL.ContactFields-module-isSingleNameField-cjK4m{grid-template-columns:3fr 4fr}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": "ContactFields-module-fields-AHEQL",
	"isSingleNameField": "ContactFields-module-isSingleNameField-cjK4m"
};
export default ___CSS_LOADER_EXPORT___;
