// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumbs-module-listContainer-wDvtd{display:flex;align-items:center;padding-top:20px}.Breadcrumbs-module-dots-RCSBz{display:flex;background-color:rgba(0,0,0,0);width:20px;height:20px;justify-content:center;align-items:center;gap:var(--spacing-4xs);cursor:pointer}.Breadcrumbs-module-dots-RCSBz:hover .Breadcrumbs-module-dot-0aqKQ{background-color:var(--colors-foreground-fg-brand)}.Breadcrumbs-module-dot-0aqKQ{width:4px;height:4px;border-radius:50%;background-color:var(--colors-foreground-fg-primary_alpha_50)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "Breadcrumbs-module-listContainer-wDvtd",
	"dots": "Breadcrumbs-module-dots-RCSBz",
	"dot": "Breadcrumbs-module-dot-0aqKQ"
};
export default ___CSS_LOADER_EXPORT___;
