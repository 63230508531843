// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryFilter-module-categoryFilter-8vV1t{overflow-x:hidden;max-width:800px;display:flex;flex-direction:column;gap:10px}.CategoryFilter-module-list-voiyq{list-style:none;display:flex;gap:24px;flex-wrap:wrap}@media(max-width: 768px){.CategoryFilter-module-list-voiyq{flex-wrap:nowrap;gap:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryFilter": "CategoryFilter-module-categoryFilter-8vV1t",
	"list": "CategoryFilter-module-list-voiyq"
};
export default ___CSS_LOADER_EXPORT___;
