// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoyaltyPointsStatus-module-loyaltyPointsStatus-v0z\\+r{display:flex;flex-direction:column;gap:25px}.LoyaltyPointsStatus-module-firstBlockContentWrapper-o99ZQ{display:flex;flex-direction:column;gap:20px}@media(min-width: 768px){.LoyaltyPointsStatus-module-firstBlockContentWrapper-o99ZQ{gap:25px}}.LoyaltyPointsStatus-module-secondBlockContentWrapper-E1IsZ{display:flex;flex-direction:column;gap:25px}@media(min-width: 768px){.LoyaltyPointsStatus-module-secondBlockContentWrapper-E1IsZ{gap:45px}}.LoyaltyPointsStatus-module-pointsHistoryLink-\\+sTp5{display:flex;align-items:center;justify-content:flex-end;gap:5px;text-decoration:none;color:#2d2926}.LoyaltyPointsStatus-module-pointsHistoryLinkLabel-UVOlm{font-size:14px}.LoyaltyPointsStatus-module-mobileInfo-OTDHh{display:flex;flex-direction:column;gap:20px;margin-top:10px}@media(min-width: 768px){.LoyaltyPointsStatus-module-mobileInfo-OTDHh{display:none}}.LoyaltyPointsStatus-module-mobileInfoTitle-FlcgX{font-weight:500;font-size:12px;letter-spacing:3.6px;background:linear-gradient(244deg, #202020 10.14%, #1e1e1e 48.33%, #525252 89.21%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.LoyaltyPointsStatus-module-mobileInfoDescription-1ZbFr{display:none}@media(max-width: 768px){.LoyaltyPointsStatus-module-mobileInfoDescription-1ZbFr{display:block;color:#2d2926;font-size:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loyaltyPointsStatus": "LoyaltyPointsStatus-module-loyaltyPointsStatus-v0z+r",
	"firstBlockContentWrapper": "LoyaltyPointsStatus-module-firstBlockContentWrapper-o99ZQ",
	"secondBlockContentWrapper": "LoyaltyPointsStatus-module-secondBlockContentWrapper-E1IsZ",
	"pointsHistoryLink": "LoyaltyPointsStatus-module-pointsHistoryLink-+sTp5",
	"pointsHistoryLinkLabel": "LoyaltyPointsStatus-module-pointsHistoryLinkLabel-UVOlm",
	"mobileInfo": "LoyaltyPointsStatus-module-mobileInfo-OTDHh",
	"mobileInfoTitle": "LoyaltyPointsStatus-module-mobileInfoTitle-FlcgX",
	"mobileInfoDescription": "LoyaltyPointsStatus-module-mobileInfoDescription-1ZbFr"
};
export default ___CSS_LOADER_EXPORT___;
