// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverButton-module-popper-S0A1q{display:flex}.PopoverButton-module-popper--default-S5fq3{display:flex;max-width:260px;box-shadow:0 2px 8px 0 rgba(0,0,0,.26);border-radius:5px;padding:20px;background-color:#fff}@media(min-width: 960px){.PopoverButton-module-popper--default-S5fq3{max-width:580px}}.PopoverButton-module-popper--small-L01i9{max-width:318px;width:calc(100vw - 78px);background:#fbfbfb;padding:24px;box-shadow:0 2px 8px 0 rgba(0,0,0,.29);border-radius:8px}@media(min-width: 960px){.PopoverButton-module-popper--small-L01i9{padding-block:24px;padding-inline:32px}}.PopoverButton-module-popper--small-L01i9 .PopoverButton-module-close-dBA7x{position:absolute;top:10px;inset-inline-end:10px;line-height:10px}.PopoverButton-module-popper--small-L01i9 .PopoverButton-module-close-dBA7x svg{width:10px;height:10px}.PopoverButton-module-close-dBA7x{position:absolute;top:16px;inset-inline-end:20px}.PopoverButton-module-helpIcon-GgLP4{margin-inline-end:20px;padding-top:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": "PopoverButton-module-popper-S0A1q",
	"popper--default": "PopoverButton-module-popper--default-S5fq3",
	"popper--small": "PopoverButton-module-popper--small-L01i9",
	"close": "PopoverButton-module-close-dBA7x",
	"helpIcon": "PopoverButton-module-helpIcon-GgLP4"
};
export default ___CSS_LOADER_EXPORT___;
