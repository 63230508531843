// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelectorList-module-languageSelectorList-M2y1G{text-align:start}[dir=rtl] .LanguageSelectorList-module-languageSelectorList-M2y1G{text-align:end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelectorList": "LanguageSelectorList-module-languageSelectorList-M2y1G"
};
export default ___CSS_LOADER_EXPORT___;
