// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductDescription-module-description-hAN2K{text-align:justify;display:none;max-height:70px;overflow:hidden}.ProductDescription-module-description-hAN2K :first-child{margin-top:0}.ProductDescription-module-description-hAN2K :last-child{margin-bottom:0}@media(min-width: 960px){.ProductDescription-module-description-hAN2K{display:block}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "ProductDescription-module-description-hAN2K"
};
export default ___CSS_LOADER_EXPORT___;
