// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./images/cluster-marker-1.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./images/cluster-marker-2.png";
import ___CSS_LOADER_URL_IMPORT_2___ from "./images/cluster-marker-3.png";
import ___CSS_LOADER_URL_IMPORT_3___ from "./images/cluster-marker-4.png";
import ___CSS_LOADER_URL_IMPORT_4___ from "./images/cluster-marker-5.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NaverClusterMarker-module-wrapper-oTbhR{display:flex;align-items:center;justify-content:center;width:40px;height:40px;border-radius:50%;color:#fff;font-size:14px;font-weight:bold;cursor:pointer;background-size:cover;background-repeat:no-repeat;background-position:center center}.NaverClusterMarker-module-backgroundXS-4hq03{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.NaverClusterMarker-module-backgroundS-MV6W5{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.NaverClusterMarker-module-backgroundM-EOQ8f{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.NaverClusterMarker-module-backgroundL-MZxXX{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.NaverClusterMarker-module-backgroundXL-3Kega{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NaverClusterMarker-module-wrapper-oTbhR",
	"backgroundXS": "NaverClusterMarker-module-backgroundXS-4hq03",
	"backgroundS": "NaverClusterMarker-module-backgroundS-MV6W5",
	"backgroundM": "NaverClusterMarker-module-backgroundM-EOQ8f",
	"backgroundL": "NaverClusterMarker-module-backgroundL-MZxXX",
	"backgroundXL": "NaverClusterMarker-module-backgroundXL-3Kega"
};
export default ___CSS_LOADER_EXPORT___;
