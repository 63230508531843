// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes FooterNavLinks-module-flickerAnimation-6-d\\+j{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes FooterNavLinks-module-fadeInAnimation-c7yq1{0%{opacity:0}100%{opacity:1}}@keyframes FooterNavLinks-module-fadeInFromTop-et00Y{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes FooterNavLinks-module-fadeOutToTop-uXsY7{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes FooterNavLinks-module-fadeInFromLeftAnimation-hsv8W{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes FooterNavLinks-module-textClip-vZfkn{to{background-size:100% 100%}}@keyframes FooterNavLinks-module-scroll-hcfNx{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes FooterNavLinks-module-flickerAnimation-6-d\\+j{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes FooterNavLinks-module-fadeInAnimation-c7yq1{0%{opacity:0}100%{opacity:1}}@keyframes FooterNavLinks-module-fadeInFromTop-et00Y{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes FooterNavLinks-module-fadeOutToTop-uXsY7{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes FooterNavLinks-module-fadeInFromLeftAnimation-hsv8W{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes FooterNavLinks-module-textClip-vZfkn{to{background-size:100% 100%}}@keyframes FooterNavLinks-module-scroll-hcfNx{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.FooterNavLinks-module-link-3PFKi{font-size:12px;font-size:var(--size-text-xs, 12px);line-height:30px;color:#d2d2d2;color:var(--colors-text-text-secondary, #d2d2d2);text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "FooterNavLinks-module-link-3PFKi",
	"flickerAnimation": "FooterNavLinks-module-flickerAnimation-6-d+j",
	"fadeInAnimation": "FooterNavLinks-module-fadeInAnimation-c7yq1",
	"fadeInFromTop": "FooterNavLinks-module-fadeInFromTop-et00Y",
	"fadeOutToTop": "FooterNavLinks-module-fadeOutToTop-uXsY7",
	"fadeInFromLeftAnimation": "FooterNavLinks-module-fadeInFromLeftAnimation-hsv8W",
	"textClip": "FooterNavLinks-module-textClip-vZfkn",
	"scroll": "FooterNavLinks-module-scroll-hcfNx"
};
export default ___CSS_LOADER_EXPORT___;
