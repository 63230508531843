import { useEffect, useState } from "react";

import { LoyaltyApi, useLoyaltyRestrictedContent } from "@jti/rest-api";
import { useSelector } from "@jti/store";

interface StoreInterface {
  loyalty: {
    status: boolean;
  };
}

const useLoyaltyStatus = (isAsync = false): boolean => {
  const loyaltyApi = LoyaltyApi.init();
  const { status } = useSelector((store: StoreInterface) => store?.loyalty);
  const [hasLoyalty, setLoyaltyStatus] = useState(false);
  const { isRestricted } = useLoyaltyRestrictedContent();

  useEffect(() => {
    if (isAsync) {
      loyaltyApi.getStatus().then((isActive: boolean) => {
        setLoyaltyStatus(isActive);
      });
    }
  }, []);
  if (isRestricted) return false;
  return isAsync ? hasLoyalty : status;
};

export default useLoyaltyStatus;
