// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConsentCheckbox-module-checkboxLabel-6cF4r{font-weight:300;color:#5b615e;font-size:16px;line-height:20px}.ConsentCheckbox-module-checkboxLabel-6cF4r>span{margin-inline-end:6px}.ConsentCheckbox-module-checkboxLabel-6cF4r a{text-decoration:none;font-weight:500;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}.ConsentCheckbox-module-checkboxLabel-6cF4r a:hover{text-decoration:underline}.ConsentCheckbox-module-checkboxContainer-1VIB0{gap:4px;grid-template-columns:auto 1fr;grid-template-areas:\"control label\";margin-bottom:16px}.ConsentCheckbox-module-checkboxContainer-1VIB0 label{font-size:16px;line-height:20px}.ConsentCheckbox-module-checkboxContainer-1VIB0 label>div{width:100%;display:flex;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxLabel": "ConsentCheckbox-module-checkboxLabel-6cF4r",
	"checkboxContainer": "ConsentCheckbox-module-checkboxContainer-1VIB0"
};
export default ___CSS_LOADER_EXPORT___;
