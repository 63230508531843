// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HoverLimit-module-container-CXm\\+Q{display:block;position:absolute;bottom:42px;inset-inline-start:0;width:300px;background:#000;border:1px solid #000;border-radius:5px;padding-block:20px;padding-inline:24px;opacity:0;transition:all .3s ease;transform:scaleX(0.5) scaleY(0);transform-origin:20% bottom;z-index:10}.HoverLimit-module-container-CXm\\+Q p{font-family:var(--font-family);font-size:16px;color:#fff;letter-spacing:1.3px;font-weight:200;text-align:justify;line-height:1.4}@media(min-width: 960px){:not(.mini-cart) .HoverLimit-module-container-CXm\\+Q{top:42px;inset-inline:auto 0;bottom:auto;transform-origin:right top}}.mini-cart .HoverLimit-module-container-CXm\\+Q{transform-origin:20% bottom;bottom:42px;inset-inline:-10px auto;top:auto;padding-block:16px;padding-inline:20px;width:270px}.mini-cart .HoverLimit-module-container-CXm\\+Q p{font-size:14px;letter-spacing:1.1px;line-height:1.2}.HoverLimit-module-show-V1BXI{opacity:1;transform:scale(1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HoverLimit-module-container-CXm+Q",
	"show": "HoverLimit-module-show-V1BXI"
};
export default ___CSS_LOADER_EXPORT___;
