import { Product as CartItem } from "@jti/magento";

const getCartItemsCount = (items: Partial<CartItem>[]): number => {
  if (!Array.isArray(items)) {
    return 0;
  }

  return items.reduce((count, { quantity }) => count + (quantity || 0), 0);
};

export default getCartItemsCount;
