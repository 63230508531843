// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QrAgeVerification-module-container-eeaCW{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;margin-top:12px;width:100%;padding-inline:18px}@media(min-width: 960px){.QrAgeVerification-module-container-eeaCW{margin-top:0}}@media(min-width: 960px){.QrAgeVerification-module-containerOverlay-1Em64{position:absolute;width:100%;height:100%;z-index:1;top:0;inset-inline-start:0}}.QrAgeVerification-module-title-Xxkoh{margin-bottom:20px}@media(min-width: 960px){.QrAgeVerification-module-title-Xxkoh{margin-bottom:30px}}.QrAgeVerification-module-contentWrapper-kFzBD{padding:22px;width:100%;border-radius:8px;background-color:rgba(216,216,216,.14);margin-bottom:30px}@media(min-width: 960px){.QrAgeVerification-module-contentWrapper-kFzBD{background-color:rgba(0,0,0,0);padding:0;margin-bottom:0}}.QrAgeVerification-module-steps-ftpmT{display:flex;flex-direction:column;color:#373a36;font-size:12px;font-weight:300;letter-spacing:0;line-height:20px;margin-bottom:20px}.QrAgeVerification-module-QrButton-WJIWp{background-color:rgba(0,0,0,0)}.QrAgeVerification-module-helperText-6Ajgz{margin-top:18px;color:#373a36;font-size:12px;font-weight:300;letter-spacing:.13px;line-height:16px;text-align:center}.QrAgeVerification-module-newCodeButton-qX4Yb>span{display:flex;gap:6px;align-items:center}.QrAgeVerification-module-newCodeButtonContainer-CrPvW{margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "QrAgeVerification-module-container-eeaCW",
	"containerOverlay": "QrAgeVerification-module-containerOverlay-1Em64",
	"title": "QrAgeVerification-module-title-Xxkoh",
	"contentWrapper": "QrAgeVerification-module-contentWrapper-kFzBD",
	"steps": "QrAgeVerification-module-steps-ftpmT",
	"QrButton": "QrAgeVerification-module-QrButton-WJIWp",
	"helperText": "QrAgeVerification-module-helperText-6Ajgz",
	"newCodeButton": "QrAgeVerification-module-newCodeButton-qX4Yb",
	"newCodeButtonContainer": "QrAgeVerification-module-newCodeButtonContainer-CrPvW"
};
export default ___CSS_LOADER_EXPORT___;
