// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelector-module-languageSelectorContainer-at7ym{inset-inline-end:5px;padding-block:10px;padding-inline:5px;border-radius:8px;line-height:1.4}.LanguageSelector-module-languageSelectorContainer-at7ym.LanguageSelector-module-languageSelectorDarkMode-2\\+D15:hover{background-color:#131313}.LanguageSelector-module-languageSelectorContainer-at7ym:hover{background-color:#f9f8f8}.LanguageSelector-module-languageSelectorOpened-HPvuy{background-color:#f9f8f8}.LanguageSelector-module-languageSelectorOpened-HPvuy .LanguageSelector-module-languageSelectorDropdownArrow-A0dzS{transform:rotate(180deg)}.LanguageSelector-module-languageSelectorOpened-HPvuy.LanguageSelector-module-languageSelectorDarkMode-2\\+D15{background-color:#131313}.LanguageSelector-module-languageSelectorDropdown-LC\\+Dq{font-size:14px;text-transform:uppercase;cursor:pointer;background-color:rgba(0,0,0,0);display:inline-flex;align-items:center;gap:5px;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}@media(min-width: 960px){.LanguageSelector-module-languageSelectorDropdown-LC\\+Dq{color:#3a3d3f}}@media(min-width: 960px){.LanguageSelector-module-languageSelectorDarkMode-2\\+D15 .LanguageSelector-module-languageSelectorDropdown-LC\\+Dq{color:rgba(255,255,255,.7)}}.LanguageSelector-module-languageSelectorDropdownArrow-A0dzS{width:10px;transition:transform .2s ease-in-out;display:none}@media(min-width: 960px){.LanguageSelector-module-languageSelectorDropdownArrow-A0dzS{display:flex}}.LanguageSelector-module-languageSelectorDropdownArrow-A0dzS svg path{fill:#2d2926}.LanguageSelector-module-languageSelectorDarkMode-2\\+D15 .LanguageSelector-module-languageSelectorOpened-HPvuy{background-color:#131313}.LanguageSelector-module-languageSelectorDarkMode-2\\+D15 .LanguageSelector-module-languageSelectorDropdownArrow-A0dzS svg path{fill:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelectorContainer": "LanguageSelector-module-languageSelectorContainer-at7ym",
	"languageSelectorDarkMode": "LanguageSelector-module-languageSelectorDarkMode-2+D15",
	"languageSelectorOpened": "LanguageSelector-module-languageSelectorOpened-HPvuy",
	"languageSelectorDropdownArrow": "LanguageSelector-module-languageSelectorDropdownArrow-A0dzS",
	"languageSelectorDropdown": "LanguageSelector-module-languageSelectorDropdown-LC+Dq"
};
export default ___CSS_LOADER_EXPORT___;
