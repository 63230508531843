// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserMarkerInfoWindow-module-infoWindowContentWrapper-RDmiX{border-radius:0;background-color:#3567ff;padding-block:5px;padding-inline:15px}.UserMarkerInfoWindow-module-infoWindowText-oge6y{color:#fff;font-size:14px;line-height:23px;font-weight:bold}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoWindowContentWrapper": "UserMarkerInfoWindow-module-infoWindowContentWrapper-RDmiX",
	"infoWindowText": "UserMarkerInfoWindow-module-infoWindowText-oge6y"
};
export default ___CSS_LOADER_EXPORT___;
