// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VirtualPrizeBadges-module-label-WpP5H{line-height:20px;letter-spacing:1.5px}.VirtualPrizeBadges-module-left-cepqQ{position:absolute;top:16px;inset-inline-start:16px}.VirtualPrizeBadges-module-leftSmallBox-ABCoJ{top:10px;inset-inline-start:10px}.VirtualPrizeBadges-module-right-meFj3{position:absolute;top:16px;inset-inline-end:16px}.VirtualPrizeBadges-module-rightSmallBox-t1ipU{top:10px;inset-inline-end:10px}.VirtualPrizeBadges-module-wrapper-1BVvZ{display:flex;align-items:center;gap:5px}.VirtualPrizeBadges-module-outOfStock-DiKPH{display:flex;height:30px;padding-block:2px;padding-inline:10px;border-radius:8px;align-items:center;background:#f1f1f1;color:#707472}.VirtualPrizeBadges-module-outOfStockDark--N1ce{background:#373a36;color:#f1f1f1}.VirtualPrizeBadges-module-iconWhite-YVu\\+Y{color:#fff}.VirtualPrizeBadges-module-iconDark-T-nG4{color:#373a36}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "VirtualPrizeBadges-module-label-WpP5H",
	"left": "VirtualPrizeBadges-module-left-cepqQ",
	"leftSmallBox": "VirtualPrizeBadges-module-leftSmallBox-ABCoJ",
	"right": "VirtualPrizeBadges-module-right-meFj3",
	"rightSmallBox": "VirtualPrizeBadges-module-rightSmallBox-t1ipU",
	"wrapper": "VirtualPrizeBadges-module-wrapper-1BVvZ",
	"outOfStock": "VirtualPrizeBadges-module-outOfStock-DiKPH",
	"outOfStockDark": "VirtualPrizeBadges-module-outOfStockDark--N1ce",
	"iconWhite": "VirtualPrizeBadges-module-iconWhite-YVu+Y",
	"iconDark": "VirtualPrizeBadges-module-iconDark-T-nG4"
};
export default ___CSS_LOADER_EXPORT___;
