// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box-module-box-Z11Xk{border-radius:8px;background-color:#fcfcfc;padding:24px}.Box-module-box-Z11Xk.Box-module-boxAccent-xbMJk{background-color:rgba(210,210,210,.25)}.Box-module-boxTitle-yEG1z{text-transform:uppercase;letter-spacing:3.5px;line-height:20px;font-size:14px;font-weight:400;margin-bottom:40px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "Box-module-box-Z11Xk",
	"boxAccent": "Box-module-boxAccent-xbMJk",
	"boxTitle": "Box-module-boxTitle-yEG1z"
};
export default ___CSS_LOADER_EXPORT___;
