// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreLocatorCardList-module-listContainer-1BLUh{margin-inline-end:0;margin-top:17px}@media(min-width: 960px){.StoreLocatorCardList-module-listContainer-1BLUh{width:360px}}@media(min-width: 960px){.StoreLocatorCardList-module-listContainer-1BLUh{margin-top:0;margin-inline-end:10px;display:block;overflow-y:scroll;flex:0 0 33.33%;height:740px;scrollbar-width:thin;scrollbar-color:#fff rgba(0,0,0,0)}}.StoreLocatorCardList-module-listContainer-1BLUh::-webkit-scrollbar{width:4px}.StoreLocatorCardList-module-listContainer-1BLUh::-webkit-scrollbar-track{background:rgba(0,0,0,0)}.StoreLocatorCardList-module-listContainer-1BLUh::-webkit-scrollbar-thumb{background:#fff;border-radius:2px}.StoreLocatorCardList-module-selectStoreButton-E2fHn{margin-block:24px;margin-inline:0;width:100%;background:#fff;border-radius:8px;font-size:14px;line-height:20px;padding:10px;text-align:center;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "StoreLocatorCardList-module-listContainer-1BLUh",
	"selectStoreButton": "StoreLocatorCardList-module-selectStoreButton-E2fHn"
};
export default ___CSS_LOADER_EXPORT___;
