// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StandardAddressForm-module-container-K6gPb{display:grid}.StandardAddressForm-module-addressFields-448oh{display:grid;grid-gap:24px 20px;gap:24px 20px;grid-template-columns:1fr;grid-template-areas:\"postcode\" \"city\" \"country\"}@media(min-width: 960px){.StandardAddressForm-module-addressFields-448oh{grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;grid-template-areas:\"postcode postcode city city country country\"}}.StandardAddressForm-module-addressFields-448oh:not(:has(.StandardAddressForm-module-postcode-KEhhp)){grid-template-columns:1fr;grid-template-areas:\"city\" \"country\"}.StandardAddressForm-module-streetName-CioYv{grid-area:streetName}.StandardAddressForm-module-streetNumber-ThB\\+q{grid-area:streetNumber}.StandardAddressForm-module-streetAdditional-1gs1C{grid-area:streetAdditional}.StandardAddressForm-module-postcode-KEhhp{grid-area:postcode}.StandardAddressForm-module-city-7NTeW{grid-area:city}.StandardAddressForm-module-country-l\\+O8o{grid-area:country}.StandardAddressForm-module-country-l\\+O8o input[readonly]{background-color:rgba(91,97,94,.1)}.StandardAddressForm-module-actions-aLyJo{margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StandardAddressForm-module-container-K6gPb",
	"addressFields": "StandardAddressForm-module-addressFields-448oh",
	"postcode": "StandardAddressForm-module-postcode-KEhhp",
	"streetName": "StandardAddressForm-module-streetName-CioYv",
	"streetNumber": "StandardAddressForm-module-streetNumber-ThB+q",
	"streetAdditional": "StandardAddressForm-module-streetAdditional-1gs1C",
	"city": "StandardAddressForm-module-city-7NTeW",
	"country": "StandardAddressForm-module-country-l+O8o",
	"actions": "StandardAddressForm-module-actions-aLyJo"
};
export default ___CSS_LOADER_EXPORT___;
