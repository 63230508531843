// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Text-module-flickerAnimation-sJQYm{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Text-module-fadeInAnimation-tGmAP{0%{opacity:0}100%{opacity:1}}@keyframes Text-module-fadeInFromTop-y3uYy{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Text-module-fadeOutToTop-phfPM{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Text-module-fadeInFromLeftAnimation-z0aLq{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Text-module-textClip-HwpDa{to{background-size:100% 100%}}@keyframes Text-module-scroll-VzMYQ{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes Text-module-flickerAnimation-sJQYm{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Text-module-fadeInAnimation-tGmAP{0%{opacity:0}100%{opacity:1}}@keyframes Text-module-fadeInFromTop-y3uYy{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Text-module-fadeOutToTop-phfPM{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Text-module-fadeInFromLeftAnimation-z0aLq{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Text-module-textClip-HwpDa{to{background-size:100% 100%}}@keyframes Text-module-scroll-VzMYQ{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.Text-module-Text-TcSRZ{font-weight:var(--weight-light);text-align:center;font-size:16px;font-size:var(--size-text-md, 16px);line-height:22px;line-height:var(--line-height-text-md, 22px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Text": "Text-module-Text-TcSRZ",
	"flickerAnimation": "Text-module-flickerAnimation-sJQYm",
	"fadeInAnimation": "Text-module-fadeInAnimation-tGmAP",
	"fadeInFromTop": "Text-module-fadeInFromTop-y3uYy",
	"fadeOutToTop": "Text-module-fadeOutToTop-phfPM",
	"fadeInFromLeftAnimation": "Text-module-fadeInFromLeftAnimation-z0aLq",
	"textClip": "Text-module-textClip-HwpDa",
	"scroll": "Text-module-scroll-VzMYQ"
};
export default ___CSS_LOADER_EXPORT___;
