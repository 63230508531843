// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelectorPopupDesktop-module-languageSelectorPopupDesktop-gqYtp{position:absolute;top:50px;inset-inline-end:0;z-index:2;background-color:#fbfbfb;padding-block:12px;padding-inline:16px;border-radius:4px;box-shadow:rgba(0,0,0,.26) 0 2px 8px}.LanguageSelectorPopupDesktop-module-languageSelectorPopupDesktop-gqYtp.LanguageSelectorPopupDesktop-module-languageSelectorPopupDesktopDarkMode-rHaN7{background-color:#252725}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelectorPopupDesktop": "LanguageSelectorPopupDesktop-module-languageSelectorPopupDesktop-gqYtp",
	"languageSelectorPopupDesktopDarkMode": "LanguageSelectorPopupDesktop-module-languageSelectorPopupDesktopDarkMode-rHaN7"
};
export default ___CSS_LOADER_EXPORT___;
