// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmationDialog-module-dialog-YqI\\+B{display:grid;grid-gap:20px;gap:20px}.ConfirmationDialog-module-title-bMJae{font-size:32px;line-height:44px;color:#373a36;font-weight:200;text-align:center}.ConfirmationDialog-module-subTitle-O33Li,.ConfirmationDialog-module-footerText-x1gRX{font-size:16px;font-weight:300;letter-spacing:0;line-height:23px;text-align:center;color:#373a36}.ConfirmationDialog-module-btnGroup-zUhUM{display:flex;justify-content:center;flex-wrap:wrap}.ConfirmationDialog-module-btnGroup-zUhUM button{width:100%;margin-bottom:20px}@media(min-width: 960px){.ConfirmationDialog-module-btnGroup-zUhUM button{width:160px;margin-bottom:0}}@media(min-width: 960px){.ConfirmationDialog-module-confirmButton-dDdid{margin-inline-start:19px}}.ConfirmationDialog-module-footer-JpDgs{display:flex;justify-content:center;align-items:center;text-align:center;margin-block-start:var(--spacing-sm)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "ConfirmationDialog-module-dialog-YqI+B",
	"title": "ConfirmationDialog-module-title-bMJae",
	"subTitle": "ConfirmationDialog-module-subTitle-O33Li",
	"footerText": "ConfirmationDialog-module-footerText-x1gRX",
	"btnGroup": "ConfirmationDialog-module-btnGroup-zUhUM",
	"confirmButton": "ConfirmationDialog-module-confirmButton-dDdid",
	"footer": "ConfirmationDialog-module-footer-JpDgs"
};
export default ___CSS_LOADER_EXPORT___;
