// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Loading-module-loading-hdVND{0%{transform:translate(0, 0)}50%{transform:translate(0, 15px)}100%{transform:translate(0, 0)}}.Loading-module-loading-hdVND{display:none;align-items:center;justify-content:space-between;width:3rem;height:3rem;flex-wrap:wrap;padding:10px;margin:0}.Loading-module-loading-hdVND.Loading-module-active-hwbGz{display:flex}.Loading-module-dot-2tx9q{display:inline-block;width:8px;height:8px;border-radius:50%;background-color:#373a36}.Loading-module-dot-2tx9q:nth-last-child(1){animation:Loading-module-loading-hdVND .75s .2s linear infinite}.Loading-module-dot-2tx9q:nth-last-child(2){animation:Loading-module-loading-hdVND .75s .3s linear infinite}.Loading-module-dot-2tx9q:nth-last-child(3){animation:Loading-module-loading-hdVND .75s .4s linear infinite}.Loading-module-whiteDots-wmr-C{background-color:var(--colors-text-text-button)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "Loading-module-loading-hdVND",
	"active": "Loading-module-active-hwbGz",
	"dot": "Loading-module-dot-2tx9q",
	"whiteDots": "Loading-module-whiteDots-wmr-C"
};
export default ___CSS_LOADER_EXPORT___;
