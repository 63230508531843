// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TiersStatuses-module-tiersStatuses-sWYHp{display:flex;padding:10px;flex-direction:column;gap:20px;background-color:#fbfbfb;border-radius:8px;align-items:flex-start}@media(min-width: 768px){.TiersStatuses-module-tiersStatuses-sWYHp{flex-direction:row}}.TiersStatuses-module-tierItem-XLRYS{display:flex;align-items:center}.TiersStatuses-module-tierName-CakdU,.TiersStatuses-module-pointsRange-ghn1C,.TiersStatuses-module-icon-y649N{color:#afafaf}.TiersStatuses-module-tierName-CakdU{overflow:hidden;text-align:center;text-overflow:ellipsis;font-size:14px;line-height:23px}.TiersStatuses-module-pointsRange-ghn1C{margin-inline-start:5px;font-size:12px}.TiersStatuses-module-icon-y649N{margin-inline-start:10px}.TiersStatuses-module-active-HEEWK *{color:#5c5c5c}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tiersStatuses": "TiersStatuses-module-tiersStatuses-sWYHp",
	"tierItem": "TiersStatuses-module-tierItem-XLRYS",
	"tierName": "TiersStatuses-module-tierName-CakdU",
	"pointsRange": "TiersStatuses-module-pointsRange-ghn1C",
	"icon": "TiersStatuses-module-icon-y649N",
	"active": "TiersStatuses-module-active-HEEWK"
};
export default ___CSS_LOADER_EXPORT___;
