import { FC, memo } from "react";

import CompactItem, { CompactItemProps } from "./CompactItem";
import RegularItem, { RegularItemProps } from "./RegularItem";

type Props = RegularItemProps &
  CompactItemProps & {
    blockQuantityAdd?: boolean;
    isLimitEnabled?: boolean;
    variant?: "regular" | "compact";
    maxPrice?: number;
  };

const CartItem: FC<Props> = ({ isLimitEnabled = false, blockQuantityAdd = false, variant = "regular", ...props }) => {
  if (variant === "regular") {
    return <RegularItem isLimitEnabled={isLimitEnabled} blockQuantityAdd={blockQuantityAdd} {...props} />;
  }

  if (variant === "compact") {
    return <CompactItem blockQuantityAdd={blockQuantityAdd} {...props} />;
  }

  return null;
};

export default memo(CartItem);
