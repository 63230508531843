// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextInput-module-input-pb9\\+3{width:100%;box-sizing:border-box;height:50px;border:1.4px solid #373a36;border-radius:8px;outline:none;background-color:#fff;padding-inline:10px;font-family:inherit;font-size:inherit;font-weight:inherit}.TextInput-module-input-pb9\\+3:read-only{background-color:#e2e2e2;border-color:#e2e2e2;text-overflow:ellipsis}.TextInput-module-input-pb9\\+3:not(:disabled):focus,.TextInput-module-input-pb9\\+3:not(:disabled):active{border:2px solid #4681ff;border-radius:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "TextInput-module-input-pb9+3"
};
export default ___CSS_LOADER_EXPORT___;
