import { FC } from "react";

import { __, getConfig } from "@jti/fe-common";
import { PointsBlock } from "@jti/ui";

type CartMiniPointsBlockType = {
  totalPoints: number;
  pending: boolean;
  isActive: boolean;
};

const CartMiniPointsBlock: FC<CartMiniPointsBlockType> = ({ totalPoints, isActive = false, pending = false }) => {
  if (totalPoints === 0 || pending || !isActive) {
    return null;
  }

  return (
    <PointsBlock
      hasLogo={true}
      knowMoreUrl={getConfig().LOYALTY_KNOW_MORE_URL}
      urlText={__("cart.ploom_club.pointsBlock.link")}
      points={totalPoints}
      pointsLabel={__("cart.ploom_club.points")}
      text={__("cart.ploom_club.pointsBlock.message", [totalPoints])}
    />
  );
};

export default CartMiniPointsBlock;
