// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VenuePopUp-module-venuePopUp-aebQL{display:flex;flex-direction:column;gap:16px;padding:10px}.VenuePopUp-module-venueName-y806i{font-size:18px;line-height:23px}.VenuePopUp-module-wrapper-AAjzq{display:flex;align-items:flex-end}.VenuePopUp-module-address-59vy6{max-width:200px;margin-inline-end:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"venuePopUp": "VenuePopUp-module-venuePopUp-aebQL",
	"venueName": "VenuePopUp-module-venueName-y806i",
	"wrapper": "VenuePopUp-module-wrapper-AAjzq",
	"address": "VenuePopUp-module-address-59vy6"
};
export default ___CSS_LOADER_EXPORT___;
