import { FC, memo, ReactNode } from "react";

import classNames from "classnames";

import { __ } from "@jti/fe-common";
import { Product as CartItem } from "@jti/magento";

import { CheckoutErrorSummary } from "../CheckoutErrorSummary";

import styles from "./CartItemList.module.scss";

type CartItemListProps = {
  items: CartItem[];
  emptyMessage?: string;
  renderItem(item: CartItem): ReactNode;
  onRemoveItems(itemIds: string[]): Promise<void>;
  className?: string;
  emptyClassName?: string;
  testId?: string;
  variant?: "regular" | "compact";
};

export const CartItemList: FC<CartItemListProps> = memo(
  ({
    items,
    emptyMessage = __("cart.item_list.no_items"),
    renderItem,
    onRemoveItems,
    className = "",
    emptyClassName = "",
    testId = "",
    variant = "regular",
  }) => {
    if (items?.length > 0) {
      return (
        <>
          <CheckoutErrorSummary
            items={items}
            onRemoveItems={onRemoveItems}
            className={styles.checkoutErrorSummary}
            variant={variant}
          />
          <div
            data-testid={testId}
            className={classNames({
              [className]: !!className,
              [styles.container]: true,
            })}
          >
            {items.map((item) => renderItem(item))}
          </div>
        </>
      );
    }

    return (
      <div
        data-testid="emptyCartContainer"
        className={classNames({
          [emptyClassName]: !!emptyClassName,
          [styles.emptyMessage]: true,
        })}
      >
        {emptyMessage}
      </div>
    );
  }
);
