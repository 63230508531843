// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes SocialLinks-module-flickerAnimation-t31NZ{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes SocialLinks-module-fadeInAnimation-wiMtS{0%{opacity:0}100%{opacity:1}}@keyframes SocialLinks-module-fadeInFromTop-TZJrL{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes SocialLinks-module-fadeOutToTop-M\\+90B{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes SocialLinks-module-fadeInFromLeftAnimation-mtSez{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes SocialLinks-module-textClip-CQKsO{to{background-size:100% 100%}}@keyframes SocialLinks-module-scroll-Dd-U2{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes SocialLinks-module-flickerAnimation-t31NZ{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes SocialLinks-module-fadeInAnimation-wiMtS{0%{opacity:0}100%{opacity:1}}@keyframes SocialLinks-module-fadeInFromTop-TZJrL{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes SocialLinks-module-fadeOutToTop-M\\+90B{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes SocialLinks-module-fadeInFromLeftAnimation-mtSez{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes SocialLinks-module-textClip-CQKsO{to{background-size:100% 100%}}@keyframes SocialLinks-module-scroll-Dd-U2{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.SocialLinks-module-socialsWrapper-DuGcD{display:flex;gap:10px;justify-content:center}@media(min-width: 960px){.SocialLinks-module-socialsWrapper-DuGcD{justify-content:flex-start}}.SocialLinks-module-socialLink-Vlycm{display:block;width:33px;height:33px}.SocialLinks-module-socialLink-Vlycm img{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialsWrapper": "SocialLinks-module-socialsWrapper-DuGcD",
	"socialLink": "SocialLinks-module-socialLink-Vlycm",
	"flickerAnimation": "SocialLinks-module-flickerAnimation-t31NZ",
	"fadeInAnimation": "SocialLinks-module-fadeInAnimation-wiMtS",
	"fadeInFromTop": "SocialLinks-module-fadeInFromTop-TZJrL",
	"fadeOutToTop": "SocialLinks-module-fadeOutToTop-M+90B",
	"fadeInFromLeftAnimation": "SocialLinks-module-fadeInFromLeftAnimation-mtSez",
	"textClip": "SocialLinks-module-textClip-CQKsO",
	"scroll": "SocialLinks-module-scroll-Dd-U2"
};
export default ___CSS_LOADER_EXPORT___;
