import { FC, memo, useCallback, useEffect, useState } from "react";

import classNames from "classnames";

import { useMessageBus } from "@jti/fe-message-bus";
import { ICartItemErrorType, Product as CartItem } from "@jti/magento";
import { usePdpLink } from "@jti/rest-api";
import { useSelector } from "@jti/store";
import { Card, MessageBox, MessageBoxVariant, Price, ProductMiniature, QuantityInput, useTranslations } from "@jti/ui";

import { hasCartErrorCode } from "@checkout/utils/getCartErrors";

import { usePromotionalOffers } from "../../../hooks/usePromotionalOffers";
import { RemoveBtn } from "../RemoveBtn";

import styles from "./CompactItem.module.scss";

export type CompactItemProps = {
  item: CartItem;
  blockQuantityAdd?: boolean;
  onRemoveClick(id: string | undefined): void;
  onQuantityChange(id: string | undefined, quantity: number): void;
};

export const CompactItem: FC<CompactItemProps> = ({
  item: {
    id,
    sku,
    imageUrl,
    name,
    quantity,
    price,
    status,
    enable_order_quantity_limit,
    order_quantity_limit,
    promotionalOffers,
    subscription = 0,
    errors,
  },
  onRemoveClick,
  onQuantityChange,
  blockQuantityAdd = false,
}) => {
  const messageBus = useMessageBus();
  const { __ } = useTranslations();
  const handleRemoveClick = useCallback(() => onRemoveClick(id), [id, onRemoveClick]);
  const handleQuantityChange = useCallback((value: number) => onQuantityChange(id, value), [id, onQuantityChange]);
  const [limitMessageStatus, setLimitMessageStatus] = useState(false);
  const [isQuantityLimitReached, setIsQuantityLimitReached] = useState(false);
  const pdpLink = usePdpLink(sku);
  const {
    isLimitReached: isPromotionalOfferLimitReached,
    hasPromotionalOffers,
    promotionalOfferLimit,
  } = usePromotionalOffers(quantity, promotionalOffers);
  const grandTotal = useSelector((state) => state.cart.prices?.grandTotal.value) ?? 0;
  const shipping = useSelector((state) => state.cart.prices?.shipping?.value) ?? 0;
  const maxOrderLimit = useSelector((state) => state.cart.storeConfig?.order_limit_value);
  const orderLimitEnabled = useSelector((state) => state.cart.storeConfig?.is_order_limit_enabled);
  const isSubscription = subscription === 1;
  const isOutOfStock = (errors && hasCartErrorCode(errors, ICartItemErrorType.ItemQty)) || status === "OUT_OF_STOCK";

  const quantityOrderLimit = {
    isLimit: hasPromotionalOffers ? 1 : 0,
    quantityLimit: hasPromotionalOffers ? promotionalOfferLimit : Infinity,
  };

  if (enable_order_quantity_limit) {
    quantityOrderLimit.isLimit = hasPromotionalOffers ? 1 : order_quantity_limit;
    quantityOrderLimit.quantityLimit = hasPromotionalOffers
      ? Math.min(promotionalOfferLimit, order_quantity_limit)
      : order_quantity_limit;
  }

  useEffect(() => {
    setLimitMessageStatus(grandTotal + price.value / quantity - shipping > maxOrderLimit);
  }, [grandTotal]);

  useEffect(() => {
    const quantityLimitReached = quantity >= order_quantity_limit && enable_order_quantity_limit === 1;
    const specialOfferLimitReached = hasPromotionalOffers && isPromotionalOfferLimitReached;

    setIsQuantityLimitReached(quantityLimitReached || specialOfferLimitReached);

    messageBus.publish("cart.limit.update", {
      payload: {
        sku,
        quantity,
        limit: quantityOrderLimit.quantityLimit,
        quantityLimitReached: quantityLimitReached || specialOfferLimitReached,
      },
    });
  }, [quantity]);

  return (
    <Card variant="variant1" data-testid="item">
      <div className={styles.rows}>
        <ProductMiniature url={pdpLink} name={name} imageUrl={imageUrl} outOfStock={isOutOfStock} />
        <div className={styles.quantityAndPrice}>
          <div className={styles.quantity}>
            <QuantityInput
              value={quantity}
              disabledAdd={blockQuantityAdd || isQuantityLimitReached}
              hoverLimitDisplay={limitMessageStatus}
              productLimitCurrency={price.currency}
              maxOrderLimit={maxOrderLimit}
              quantityOrderLimit={quantityOrderLimit}
              disabled={isOutOfStock}
              disabledMessage={__("cart.cart_item.out_of_stock")}
              onChange={handleQuantityChange}
              orderLimitEnabled={orderLimitEnabled}
            />
          </div>
          <Price
            price={price}
            className={classNames(styles.price, { [styles.subscriptionPrice]: isSubscription })}
            frequency={isSubscription ? __("cart.cart_item.subscription_billing_frequency") : undefined}
          />
        </div>
        <MessageBox
          isVisible={isQuantityLimitReached}
          variant={MessageBoxVariant.warning}
          isCompact={true}
          className={styles.warning}
        >
          <>
            {__("libs.ui.notification.limit.sku")} ({quantityOrderLimit.quantityLimit})
          </>
        </MessageBox>
        <div className={styles.remove}>
          <RemoveBtn onClick={handleRemoveClick} />
        </div>
      </div>
    </Card>
  );
};

export default memo(CompactItem);
