// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrizeTile-module-prizeTile-70pBr{border-radius:8px;border:1px solid rgba(0,0,0,.2);background:#fbfbfb}@media(max-width: 480px){.PrizeTile-module-prizeTile-70pBr{display:flex;justify-content:center}}.PrizeTile-module-prizeTileContent-4BuTL{padding:25px;display:flex;flex-direction:column;gap:25px}.PrizeTile-module-imageWrapper-2ag9Y{max-width:300px;position:relative;height:90px;border-radius:8px;overflow:hidden}.PrizeTile-module-imageWrapper-2ag9Y img{width:100%;border-radius:8px}.PrizeTile-module-venue-WhHeb{cursor:pointer}.PrizeTile-module-title-HawZ5{font-size:18px;line-height:23px}.PrizeTile-module-infoWrapper-jlrJN{display:flex;flex-direction:column;gap:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prizeTile": "PrizeTile-module-prizeTile-70pBr",
	"prizeTileContent": "PrizeTile-module-prizeTileContent-4BuTL",
	"imageWrapper": "PrizeTile-module-imageWrapper-2ag9Y",
	"venue": "PrizeTile-module-venue-WhHeb",
	"title": "PrizeTile-module-title-HawZ5",
	"infoWrapper": "PrizeTile-module-infoWrapper-jlrJN"
};
export default ___CSS_LOADER_EXPORT___;
