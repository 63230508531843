// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextWordBreakWrapper-module-defaultWrapText-E8S12{word-break:break-word}.TextWordBreakWrapper-module-asianWrapText-0qvzw{word-break:keep-all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultWrapText": "TextWordBreakWrapper-module-defaultWrapText-E8S12",
	"asianWrapText": "TextWordBreakWrapper-module-asianWrapText-0qvzw"
};
export default ___CSS_LOADER_EXPORT___;
