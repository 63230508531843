// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GetDirectionLink-module-getDirectionLink-HZCEm{display:inline-flex;gap:10px;text-decoration:none}.GetDirectionLink-module-label-EheN9{text-wrap:nowrap}.GetDirectionLink-module-iconBlack-p0E23{color:#131110}.GetDirectionLink-module-iconBlue-rKVIp{color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"getDirectionLink": "GetDirectionLink-module-getDirectionLink-HZCEm",
	"label": "GetDirectionLink-module-label-EheN9",
	"iconBlack": "GetDirectionLink-module-iconBlack-p0E23",
	"iconBlue": "GetDirectionLink-module-iconBlue-rKVIp"
};
export default ___CSS_LOADER_EXPORT___;
