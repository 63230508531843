// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes RichText-module-flickerAnimation-XOR-4{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes RichText-module-fadeInAnimation-ZKqzM{0%{opacity:0}100%{opacity:1}}@keyframes RichText-module-fadeInFromTop-nkCgU{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes RichText-module-fadeOutToTop-Je5nf{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes RichText-module-fadeInFromLeftAnimation-uFeV6{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes RichText-module-textClip-FhnQ4{to{background-size:100% 100%}}@keyframes RichText-module-scroll-lQ7nK{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes RichText-module-flickerAnimation-XOR-4{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes RichText-module-fadeInAnimation-ZKqzM{0%{opacity:0}100%{opacity:1}}@keyframes RichText-module-fadeInFromTop-nkCgU{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes RichText-module-fadeOutToTop-Je5nf{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes RichText-module-fadeInFromLeftAnimation-uFeV6{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes RichText-module-textClip-FhnQ4{to{background-size:100% 100%}}@keyframes RichText-module-scroll-lQ7nK{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.RichText-module-darkMode--c3s4{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkMode": "RichText-module-darkMode--c3s4",
	"flickerAnimation": "RichText-module-flickerAnimation-XOR-4",
	"fadeInAnimation": "RichText-module-fadeInAnimation-ZKqzM",
	"fadeInFromTop": "RichText-module-fadeInFromTop-nkCgU",
	"fadeOutToTop": "RichText-module-fadeOutToTop-Je5nf",
	"fadeInFromLeftAnimation": "RichText-module-fadeInFromLeftAnimation-uFeV6",
	"textClip": "RichText-module-textClip-FhnQ4",
	"scroll": "RichText-module-scroll-lQ7nK"
};
export default ___CSS_LOADER_EXPORT___;
