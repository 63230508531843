// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroCards-module-heroCardsContainer-cDuIA{transform:translateY(calc(-50% - var(--fixed-hero-cards-margin)))}.HeroCards-module-heroCardsContainer-cDuIA .HeroCards-module-heroCards-onday{display:flex;width:100%;background-color:rgba(255,255,255,.2);-webkit-backdrop-filter:blur(30px);backdrop-filter:blur(30px);border-radius:8px;flex-direction:column;padding:var(--hero-cards-padding);gap:10px}@media(min-width: 768px){.HeroCards-module-heroCardsContainer-cDuIA .HeroCards-module-heroCards-onday{flex-flow:row;gap:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroCardsContainer": "HeroCards-module-heroCardsContainer-cDuIA",
	"heroCards": "HeroCards-module-heroCards-onday"
};
export default ___CSS_LOADER_EXPORT___;
