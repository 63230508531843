// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TierBadge-module-tierBadge-aD1ac{display:inline-flex;border-radius:5px;padding-block:5px;padding-inline:10px;align-items:center;background-color:#6c6c6c}.TierBadge-module-silverWithOpacity-a038O{background-color:rgba(107,107,107,.8)}.TierBadge-module-text-7RG4b{margin-inline-start:8px;letter-spacing:1.5px;line-height:20px;text-transform:capitalize;text-wrap:nowrap;display:inline-block}.TierBadge-module-icon-gpw4A{color:#fff}.TierBadge-module-black-j4VBc,.TierBadge-module-blackWithOpacity-Bmx0N{background-color:#000}.TierBadge-module-gold-XhW6t{background-color:#bf9544}.TierBadge-module-goldWithOpacity-qOcUo{background-color:rgba(191,150,69,.8)}.TierBadge-module-noText-XXNX\\+{padding-right:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tierBadge": "TierBadge-module-tierBadge-aD1ac",
	"silverWithOpacity": "TierBadge-module-silverWithOpacity-a038O",
	"text": "TierBadge-module-text-7RG4b",
	"icon": "TierBadge-module-icon-gpw4A",
	"black": "TierBadge-module-black-j4VBc",
	"blackWithOpacity": "TierBadge-module-blackWithOpacity-Bmx0N",
	"gold": "TierBadge-module-gold-XhW6t",
	"goldWithOpacity": "TierBadge-module-goldWithOpacity-qOcUo",
	"noText": "TierBadge-module-noText-XXNX+"
};
export default ___CSS_LOADER_EXPORT___;
