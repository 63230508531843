// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List-module-listWrapper-AMoFU{border:1px solid rgba(0,0,0,0);border-radius:8px}.List-module-listWrapper-arrow-3Psha{padding-block:40px;padding-inline:24px;background:#fbfbfb}.List-module-listWrapper-navigation-edQCV{padding:10px;border-color:rgba(151,151,151,.2)}.List-module-list-\\+vBd1{display:grid}.List-module-listWrapper-navigation-edQCV .List-module-list-\\+vBd1{gap:20px}.List-module-listWrapper-horizontal-arrows-pt3EO .List-module-list-\\+vBd1{display:flex;justify-content:center;flex-flow:row wrap}.List-module-listTitle-\\+ABsO{margin-bottom:24px;font-size:22px;line-height:26px;font-weight:300;color:#7c7c7c}.List-module-listWrapper-standard-nyH75 .List-module-listTitle-\\+ABsO{text-transform:uppercase}.List-module-listWrapper-related-resources-tLbWL .List-module-listTitle-\\+ABsO{font-size:20px;line-height:24px}.List-module-listWrapper-blue-links-rZ5Aw .List-module-listTitle-\\+ABsO{font-size:18px;line-height:22px;font-weight:500}.List-module-listWrapper-arrow-3Psha .List-module-listTitle-\\+ABsO{position:relative;padding-bottom:8px;margin-bottom:16px;font-size:12px;line-height:16px;letter-spacing:2px;text-transform:uppercase}.List-module-listWrapper-arrow-3Psha .List-module-listTitle-\\+ABsO::after{content:\"\";position:absolute;bottom:0;inset-inline:0;width:30px;height:2px;background:#d3d3d3}.List-module-listWrapper-footer-CI\\+q- .List-module-listTitle-\\+ABsO{font-size:12px;line-height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listWrapper": "List-module-listWrapper-AMoFU",
	"listWrapper-arrow": "List-module-listWrapper-arrow-3Psha",
	"listWrapper-navigation": "List-module-listWrapper-navigation-edQCV",
	"list": "List-module-list-+vBd1",
	"listWrapper-horizontal-arrows": "List-module-listWrapper-horizontal-arrows-pt3EO",
	"listTitle": "List-module-listTitle-+ABsO",
	"listWrapper-standard": "List-module-listWrapper-standard-nyH75",
	"listWrapper-related-resources": "List-module-listWrapper-related-resources-tLbWL",
	"listWrapper-blue-links": "List-module-listWrapper-blue-links-rZ5Aw",
	"listWrapper-footer": "List-module-listWrapper-footer-CI+q-"
};
export default ___CSS_LOADER_EXPORT___;
