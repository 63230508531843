// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterBreadcrumbs-module-listContainer-Sa4Q5{display:flex;align-items:center;padding-top:50px}.FooterBreadcrumbs-module-logo-Q8f-C{display:flex;margin-inline-end:9px;width:56px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "FooterBreadcrumbs-module-listContainer-Sa4Q5",
	"logo": "FooterBreadcrumbs-module-logo-Q8f-C"
};
export default ___CSS_LOADER_EXPORT___;
