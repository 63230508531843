// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormCheckbox-module-checkboxContainer-soyD3{position:relative;gap:4px;grid-template-columns:auto 1fr;margin-bottom:16px}.FormCheckbox-module-checkboxContainer-soyD3.FormCheckbox-module-formField-hPFAi{grid-template-areas:\"control label\"}.FormCheckbox-module-checkboxContainer-soyD3 .FormCheckbox-module-errorMessage-NcXzS{grid-column:1/3}.FormCheckbox-module-checkboxContainer-soyD3 label{font-weight:300;color:#5b615e;font-size:16px;line-height:20px}.FormCheckbox-module-checkboxContainer-soyD3 label>div{width:100%;display:flex;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": "FormCheckbox-module-checkboxContainer-soyD3",
	"formField": "FormCheckbox-module-formField-hPFAi",
	"errorMessage": "FormCheckbox-module-errorMessage-NcXzS"
};
export default ___CSS_LOADER_EXPORT___;
