// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Tabs-module-flickerAnimation-2V8Zu{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Tabs-module-fadeInAnimation-r2z8l{0%{opacity:0}100%{opacity:1}}@keyframes Tabs-module-fadeInFromTop-vGJQX{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Tabs-module-fadeOutToTop-8wa5E{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Tabs-module-fadeInFromLeftAnimation-it7\\+h{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Tabs-module-textClip-XDF26{to{background-size:100% 100%}}@keyframes Tabs-module-scroll-tsMaj{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes Tabs-module-flickerAnimation-2V8Zu{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes Tabs-module-fadeInAnimation-r2z8l{0%{opacity:0}100%{opacity:1}}@keyframes Tabs-module-fadeInFromTop-vGJQX{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes Tabs-module-fadeOutToTop-8wa5E{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes Tabs-module-fadeInFromLeftAnimation-it7\\+h{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes Tabs-module-textClip-XDF26{to{background-size:100% 100%}}@keyframes Tabs-module-scroll-tsMaj{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.Tabs-module-tabMenu-utjPl{display:flex;margin-block:10px;margin-inline:0}.Tabs-module-tabMenu-utjPl .Tabs-module-label-j7Ol\\+{cursor:pointer;padding-block:0;padding-inline:20px;height:40px;line-height:40px;border-radius:8px}.Tabs-module-tabMenu-utjPl .Tabs-module-focus-k734W{background:rgba(70,129,255,.16);color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}.Tabs-module-tabContent-W-rgW{display:none}.Tabs-module-selected-bgrJ6{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabMenu": "Tabs-module-tabMenu-utjPl",
	"label": "Tabs-module-label-j7Ol+",
	"focus": "Tabs-module-focus-k734W",
	"tabContent": "Tabs-module-tabContent-W-rgW",
	"selected": "Tabs-module-selected-bgrJ6",
	"flickerAnimation": "Tabs-module-flickerAnimation-2V8Zu",
	"fadeInAnimation": "Tabs-module-fadeInAnimation-r2z8l",
	"fadeInFromTop": "Tabs-module-fadeInFromTop-vGJQX",
	"fadeOutToTop": "Tabs-module-fadeOutToTop-8wa5E",
	"fadeInFromLeftAnimation": "Tabs-module-fadeInFromLeftAnimation-it7+h",
	"textClip": "Tabs-module-textClip-XDF26",
	"scroll": "Tabs-module-scroll-tsMaj"
};
export default ___CSS_LOADER_EXPORT___;
