// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreLocator-module-warningMessage-7ndW0{margin-top:15px}.StoreLocator-module-resultsContainer-2MXqs{display:flex;min-height:315px;flex-direction:column-reverse;justify-content:flex-end;margin-top:20px}@media(min-width: 960px){.StoreLocator-module-resultsContainer-2MXqs{flex-direction:row}}.StoreLocator-module-mapContainer-3GGmv{width:100%}.StoreLocator-module-mapContainerHidden-84DU5{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningMessage": "StoreLocator-module-warningMessage-7ndW0",
	"resultsContainer": "StoreLocator-module-resultsContainer-2MXqs",
	"mapContainer": "StoreLocator-module-mapContainer-3GGmv",
	"mapContainerHidden": "StoreLocator-module-mapContainerHidden-84DU5"
};
export default ___CSS_LOADER_EXPORT___;
