// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddressInfoBlock-module-container-MV4nb{display:grid;grid-gap:6px;gap:6px;color:#000;font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px}@media(min-width: 960px){.AddressInfoBlock-module-container-MV4nb{color:#373a36;font-size:16px;font-weight:300;letter-spacing:0;line-height:23px}}.AddressInfoBlock-module-cart-3rwdn .AddressInfoBlock-module-name-EBiO2{font-weight:700}.AddressInfoBlock-module-cart2-sck8s{opacity:.6;color:#373a36;font-size:16px;font-weight:300;letter-spacing:0;line-height:23px}.AddressInfoBlock-module-cart2-sck8s .AddressInfoBlock-module-name-EBiO2{opacity:.6;color:#5c615e;font-size:18px;font-weight:500;letter-spacing:.19px;line-height:24px;padding-bottom:10px}.AddressInfoBlock-module-block-ngkvv .AddressInfoBlock-module-name-EBiO2{color:#373a36;font-size:14px;font-weight:bold;letter-spacing:0;line-height:20px;padding-bottom:15px}@media(min-width: 960px){.AddressInfoBlock-module-block-ngkvv .AddressInfoBlock-module-name-EBiO2{color:#5c615e;font-size:18px;font-weight:bold;letter-spacing:.19px;line-height:24px}}.AddressInfoBlock-module-block-ngkvv .AddressInfoBlock-module-countryName-\\+-y9A{display:inline-block}.AddressInfoBlock-module-dialog-3BUWJ .AddressInfoBlock-module-name-EBiO2{color:#373a36;font-size:14px;font-weight:bold;letter-spacing:0;line-height:20px;padding-bottom:15px}@media(min-width: 960px){.AddressInfoBlock-module-dialog-3BUWJ .AddressInfoBlock-module-name-EBiO2{color:#5c615e;font-size:18px;font-weight:bold;letter-spacing:.19px;line-height:24px}}.AddressInfoBlock-module-editButton-JKu0B{margin-top:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddressInfoBlock-module-container-MV4nb",
	"cart": "AddressInfoBlock-module-cart-3rwdn",
	"name": "AddressInfoBlock-module-name-EBiO2",
	"cart2": "AddressInfoBlock-module-cart2-sck8s",
	"block": "AddressInfoBlock-module-block-ngkvv",
	"countryName": "AddressInfoBlock-module-countryName-+-y9A",
	"dialog": "AddressInfoBlock-module-dialog-3BUWJ",
	"editButton": "AddressInfoBlock-module-editButton-JKu0B"
};
export default ___CSS_LOADER_EXPORT___;
