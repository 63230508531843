// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveItemDialog-module-dialog-8eqze{display:grid;grid-gap:20px;gap:20px;border-radius:8px;background-color:#fff;box-shadow:0 2px 34px 0 rgba(0,0,0,.07)}.RemoveItemDialog-module-title-W7Usm{font-size:32px;line-height:44px;color:#373a36;font-weight:200;text-align:center}.RemoveItemDialog-module-cardItem-OiNsd{max-width:640px;margin:auto}.RemoveItemDialog-module-container-sRi7V{padding-top:24px;padding-bottom:24px;display:grid;grid-template-columns:100px 1fr auto;grid-gap:24px;gap:24px;max-width:540px;margin:auto}.RemoveItemDialog-module-price-NyeGA{padding-top:20px;font-weight:bold;color:#5c615e;font-size:16px;letter-spacing:2.5px;line-height:23px}.RemoveItemDialog-module-name-jaolY{font-size:16px;margin-bottom:6px;font-weight:bold}.RemoveItemDialog-module-description-P5Tvc{font-size:14px;color:#5c615e}.RemoveItemDialog-module-description-P5Tvc>span{height:100%;display:block;max-height:40px;overflow:hidden}.RemoveItemDialog-module-btnGroup-u5poX{display:flex;justify-content:center;flex-flow:column wrap}@media(min-width: 960px){.RemoveItemDialog-module-btnGroup-u5poX{flex-direction:row}}.RemoveItemDialog-module-button--FqXt{width:200px;margin-block:0 20px;margin-inline:auto}@media(min-width: 960px){.RemoveItemDialog-module-button--FqXt{width:160px;margin-block:20px 0;margin-inline:20px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "RemoveItemDialog-module-dialog-8eqze",
	"title": "RemoveItemDialog-module-title-W7Usm",
	"cardItem": "RemoveItemDialog-module-cardItem-OiNsd",
	"container": "RemoveItemDialog-module-container-sRi7V",
	"price": "RemoveItemDialog-module-price-NyeGA",
	"name": "RemoveItemDialog-module-name-jaolY",
	"description": "RemoveItemDialog-module-description-P5Tvc",
	"btnGroup": "RemoveItemDialog-module-btnGroup-u5poX",
	"button": "RemoveItemDialog-module-button--FqXt"
};
export default ___CSS_LOADER_EXPORT___;
