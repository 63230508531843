// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Info-module-infoSupp-yJ6f9{height:16px;color:#5c615e;font-size:12px;font-weight:300;letter-spacing:.13px;line-height:16px;display:grid;grid-template-columns:auto 1fr;grid-gap:6px;gap:6px;align-items:center}.Info-module-icon-ulY3S{margin-block:0;margin-inline:6px}.Info-module-error-jc3wP{color:#ec0000}.Info-module-checkmark-wbbLo svg{height:16px;position:relative;top:-2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoSupp": "Info-module-infoSupp-yJ6f9",
	"icon": "Info-module-icon-ulY3S",
	"error": "Info-module-error-jc3wP",
	"checkmark": "Info-module-checkmark-wbbLo"
};
export default ___CSS_LOADER_EXPORT___;
