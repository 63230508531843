import { memo } from "react";

import { __ } from "@jti/fe-common";

import useLoyaltyStatus from "../../../hooks/useLoyaltyStatus";

import styles from "./ProductPoints.module.scss";

type ProductPointsType = {
  pointsQuantity: number;
};

const ProductPoints = ({ pointsQuantity }: ProductPointsType): JSX.Element | null => {
  const loyaltyStatus = useLoyaltyStatus();

  if (pointsQuantity <= 0 || !loyaltyStatus) {
    return null;
  }

  return (
    <div className={styles.points} data-testid="product-points">
      {__("cart.ploom_club.earn")}
      <span className={styles.pointsQuantity} data-testid="cart-points-earn">{` ${pointsQuantity} `}</span>
      <span className={styles.ploom}>{"Ploom Club "}</span>
      {pointsQuantity == 1 ? __("cart.ploom_club.point") : __("cart.ploom_club.points")}
    </div>
  );
};

export default memo(ProductPoints);
