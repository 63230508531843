// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignOut-module-text-S0WUn{margin-inline-end:12px}.SignOut-module-wrap-sbwbG{cursor:pointer;background-color:rgba(0,0,0,0);display:flex;align-items:center;box-sizing:border-box;height:61px;width:100%;border:0;justify-content:center;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}@media(min-width: 960px){.SignOut-module-wrap-sbwbG{border:1px solid rgba(150,150,150,.47);border-radius:8px;padding:22px;justify-content:initial;color:inherit}}.SignOut-module-wrap-sbwbG:hover path{fill:#3567ff;fill:var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "SignOut-module-text-S0WUn",
	"wrap": "SignOut-module-wrap-sbwbG"
};
export default ___CSS_LOADER_EXPORT___;
