import { __, getConfig } from "@jti/fe-common";
import { Product as CartItemType } from "@jti/magento";
import { Loading } from "@jti/ui";

import CartItem from "../CartItem";
import { CompactBundleItem } from "../CartItem/CompactBundleItem";
import { CartItemList } from "../CartItemList";

export type CartMiniItemsType = {
  pending: boolean;
  items: CartItemType[];
  buyLimitation: boolean;
  qtyPending: boolean;
  onRemoveItemClick(itemId: string): void;
  onItemQuantityChange(itemId: string, value: number): void;
  onRemoveItems(itemIds: string[]): Promise<void>;
};

const CartMiniItems = ({
  pending,
  items,
  qtyPending,
  buyLimitation,
  onRemoveItemClick,
  onItemQuantityChange,
  onRemoveItems,
}: CartMiniItemsType): JSX.Element => {
  if (pending) {
    return <Loading />;
  }

  return (
    <CartItemList
      className={"mini-cart__items-container"}
      emptyClassName={"mini-cart__items-empty-container"}
      items={items}
      testId={"mini-cart-list"}
      emptyMessage={__("cart.cart_mini.no_items_in_cart")}
      variant={"compact"}
      renderItem={(item) =>
        item.type_id === "bundle" && getConfig().displayBundleSelectedItems ? (
          <CompactBundleItem
            key={item.id}
            item={item}
            qtyPending={qtyPending}
            blockQuantityAdd={buyLimitation}
            onQuantityChange={onItemQuantityChange}
            onRemoveClick={onRemoveItemClick}
          />
        ) : (
          <CartItem
            key={item.id}
            item={item}
            variant="compact"
            qtyPending={qtyPending}
            blockQuantityAdd={buyLimitation}
            onRemoveClick={onRemoveItemClick}
            onQuantityChange={onItemQuantityChange}
          />
        )
      }
      onRemoveItems={onRemoveItems}
    />
  );
};

export default CartMiniItems;
