// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrizeLocatorDisplayTypeToggle-module-prizeLocatorDisplayTypeToggle-eokGx{position:relative;width:140px;margin-inline-start:auto}@media(min-width: 960px){.PrizeLocatorDisplayTypeToggle-module-prizeLocatorDisplayTypeToggle-eokGx{display:none}}.PrizeLocatorDisplayTypeToggle-module-input-muxqQ{display:none}.PrizeLocatorDisplayTypeToggle-module-input-muxqQ:checked+.PrizeLocatorDisplayTypeToggle-module-buttonLabel-5qMdj .PrizeLocatorDisplayTypeToggle-module-button-c0vXX.PrizeLocatorDisplayTypeToggle-module-mapView-FluNc{background:rgba(0,0,0,0);color:#000;border-color:#000}.PrizeLocatorDisplayTypeToggle-module-input-muxqQ:checked+.PrizeLocatorDisplayTypeToggle-module-buttonLabel-5qMdj .PrizeLocatorDisplayTypeToggle-module-button-c0vXX.PrizeLocatorDisplayTypeToggle-module-listView-2j8vn{background:#3567ff;color:#fff;border-color:#3567ff}.PrizeLocatorDisplayTypeToggle-module-button-c0vXX{font-size:12px;line-height:16px;text-align:center;padding-top:4px;padding-bottom:4px;color:#000;width:50%}.PrizeLocatorDisplayTypeToggle-module-mapView-FluNc{border-end-start-radius:8px;border-start-start-radius:8px;background:#3567ff;color:#fff;border:1px solid #3567ff}.PrizeLocatorDisplayTypeToggle-module-listView-2j8vn{border-end-end-radius:8px;border-start-end-radius:8px;border:1px solid #000;border-inline-start-color:rgba(0,0,0,0)}.PrizeLocatorDisplayTypeToggle-module-buttonLabel-5qMdj{border:1px solid #fff;border-radius:8px;display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prizeLocatorDisplayTypeToggle": "PrizeLocatorDisplayTypeToggle-module-prizeLocatorDisplayTypeToggle-eokGx",
	"input": "PrizeLocatorDisplayTypeToggle-module-input-muxqQ",
	"buttonLabel": "PrizeLocatorDisplayTypeToggle-module-buttonLabel-5qMdj",
	"button": "PrizeLocatorDisplayTypeToggle-module-button-c0vXX",
	"mapView": "PrizeLocatorDisplayTypeToggle-module-mapView-FluNc",
	"listView": "PrizeLocatorDisplayTypeToggle-module-listView-2j8vn"
};
export default ___CSS_LOADER_EXPORT___;
