import { __ } from "@jti/fe-common";
import { Button } from "@jti/ui";

type CartMiniCheckoutButton = {
  isVisible: boolean;
  qtyPending: boolean;
  buyLimitation: boolean;
  onCheckoutClick: () => void;
};

const CartMiniCheckoutButton = ({
  isVisible,
  buyLimitation,
  onCheckoutClick,
  qtyPending,
}: CartMiniCheckoutButton): JSX.Element | null => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={"mini-cart__checkout-button-container"}>
      <Button
        color="primary"
        disabled={buyLimitation || qtyPending}
        className={"mini-cart__checkout-button"}
        onClick={onCheckoutClick}
        data-testid="miniCartCheckoutButton"
      >
        {__("cart.cart_mini.button.checkout")}
      </Button>
    </div>
  );
};

export default CartMiniCheckoutButton;
