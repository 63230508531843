// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelectorListItem-module-languageSelectorListItem-oIGpi:not(:last-child){margin-bottom:15px}.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc{font-size:14px;white-space:nowrap;color:#858886;text-transform:capitalize;cursor:pointer;background-color:rgba(0,0,0,0)}.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc.LanguageSelectorListItem-module-languageSelectorListItemBtnActive-w6McG{color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}@media(min-width: 960px){.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc{text-transform:uppercase}.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc.LanguageSelectorListItem-module-languageSelectorListItemBtnActive-w6McG{pointer-events:none}}.LanguageSelectorListItem-module-languageSelectorListItemBtnDarkMode-N28Cx.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc{color:rgba(255,255,255,.8)}.LanguageSelectorListItem-module-languageSelectorListItemBtnDarkMode-N28Cx.LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc.LanguageSelectorListItem-module-languageSelectorListItemBtnActive-w6McG{color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelectorListItem": "LanguageSelectorListItem-module-languageSelectorListItem-oIGpi",
	"languageSelectorListItemBtn": "LanguageSelectorListItem-module-languageSelectorListItemBtn-5JtRc",
	"languageSelectorListItemBtnActive": "LanguageSelectorListItem-module-languageSelectorListItemBtnActive-w6McG",
	"languageSelectorListItemBtnDarkMode": "LanguageSelectorListItem-module-languageSelectorListItemBtnDarkMode-N28Cx"
};
export default ___CSS_LOADER_EXPORT___;
