// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LeavePageConfirmationDialog-module-dialog-bLVSH{max-width:700px;padding:30px}@media(min-width: 960px){.LeavePageConfirmationDialog-module-dialog-bLVSH{padding:60px}}.LeavePageConfirmationDialog-module-title-eBocO{color:#373a36;font-size:26px;font-weight:300;letter-spacing:0;line-height:38px;text-align:start}.LeavePageConfirmationDialog-module-text-HgQn-{color:#373a36;font-size:16px;font-weight:300;letter-spacing:0;line-height:23px;white-space:break-spaces}.LeavePageConfirmationDialog-module-subTitle-gKFp5{margin-top:15px;margin-bottom:60px}.LeavePageConfirmationDialog-module-confirmText-xc7p6{padding-top:24px}.LeavePageConfirmationDialog-module-btnGroup-DkViR{display:flex;flex-wrap:wrap}.LeavePageConfirmationDialog-module-btnGroup-DkViR button{width:100%}@media(min-width: 960px){.LeavePageConfirmationDialog-module-btnGroup-DkViR{flex-wrap:nowrap}.LeavePageConfirmationDialog-module-btnGroup-DkViR button{width:auto}}.LeavePageConfirmationDialog-module-leaveBtn-ab7Nu{width:auto}@media(min-width: 960px){.LeavePageConfirmationDialog-module-leaveBtn-ab7Nu{width:100%;margin-inline-end:15px}}.LeavePageConfirmationDialog-module-stayBtn-TUWM6{width:auto;margin-top:15px}@media(min-width: 960px){.LeavePageConfirmationDialog-module-stayBtn-TUWM6{width:100%;margin-top:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "LeavePageConfirmationDialog-module-dialog-bLVSH",
	"title": "LeavePageConfirmationDialog-module-title-eBocO",
	"text": "LeavePageConfirmationDialog-module-text-HgQn-",
	"subTitle": "LeavePageConfirmationDialog-module-subTitle-gKFp5",
	"confirmText": "LeavePageConfirmationDialog-module-confirmText-xc7p6",
	"btnGroup": "LeavePageConfirmationDialog-module-btnGroup-DkViR",
	"leaveBtn": "LeavePageConfirmationDialog-module-leaveBtn-ab7Nu",
	"stayBtn": "LeavePageConfirmationDialog-module-stayBtn-TUWM6"
};
export default ___CSS_LOADER_EXPORT___;
