// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscriptionItemFlag-module-container-O1h0L{display:flex;font-weight:300;font-size:16px;line-height:23px;color:#5c5c5c;gap:5px;margin-top:5px}.SubscriptionItemFlag-module-icon-GwIAS{color:#131110}.SubscriptionItemFlag-module-caption-TmWBs{margin-top:3px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SubscriptionItemFlag-module-container-O1h0L",
	"icon": "SubscriptionItemFlag-module-icon-GwIAS",
	"caption": "SubscriptionItemFlag-module-caption-TmWBs"
};
export default ___CSS_LOADER_EXPORT___;
