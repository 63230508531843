import { FC, memo } from "react";

import dompurify from "isomorphic-dompurify";

import { __ } from "@jti/fe-common";
import { Product } from "@jti/magento";
import { Button, Card, Dialog, Image, Price, Txt } from "@jti/ui";

import styles from "./RemoveItemDialog.module.scss";

const { sanitize } = dompurify;

type RemoveItemDialogProps = {
  item: Product;
  isOpenDialog: boolean;
  onRemove(): void;
  onCancel(): void;
};

const RemoveItemDialog: FC<RemoveItemDialogProps> = ({ isOpenDialog, onCancel, onRemove, item }) => {
  return (
    <Dialog className={styles.dialog} isOpened={isOpenDialog} onClose={onCancel}>
      <div className={styles.title}>{__("cart.items.remove_dialog.title")}</div>
      <div className={styles.cardItem} data-testid={"remove-item-dialog"}>
        <Card variant="variant1" key={item.name}>
          <div className={styles.container}>
            <div className={styles.image}>
              <Image url={item.imageUrl} />
            </div>
            <div>
              <Txt className={styles.name}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item.name),
                  }}
                />
              </Txt>
              <Txt className={styles.description}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item.description),
                  }}
                />
              </Txt>
              <Price price={item.price} className={styles.price} />
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.btnGroup}>
        <Button data-testid="remove-item-cancel-button" className={styles.button} color="secondary" onClick={onCancel}>
          {__("com.address.dialog.cancel_button_label")}
        </Button>
        <Button data-testid="remove-item-submit-button" className={styles.button} color="primary" onClick={onRemove}>
          {__("com.address.dialog.save_button_label")}
        </Button>
      </div>
    </Dialog>
  );
};

export default memo(RemoveItemDialog);
