// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormattedPrice-module-price-eDdEl{direction:ltr;display:inline-flex}s .FormattedPrice-module-price-eDdEl{text-decoration:line-through}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": "FormattedPrice-module-price-eDdEl"
};
export default ___CSS_LOADER_EXPORT___;
