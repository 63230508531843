import { FC, useMemo } from "react";

import classNames from "classnames";

import { useSelector } from "@jti/store";
import { I18n, MessageBox, MessageBoxVariant, useConfig, useTranslations } from "@jti/ui";

import { CartItem } from "@checkout/apps/OnePageCheckout/types/CartItem";
import useOrderLimit from "@checkout/hooks/useOrderLimit";
import { ExtendedCartItemErrorType, filterItemsByErrorCode } from "@checkout/utils/getCartErrors";
import { getOutOfStockItems } from "@checkout/utils/getOutOfStockItems";

import { CheckoutErrorMessage } from "./CheckoutErrorMessage/CheckoutErrorMessage";
import { OutOfStockItems } from "./OutOfStockItems/OutOfStockItems";

import { PriceLimitErrorMessage } from "./PriceLimitErrorMessage";

import styles from "./SingleError.module.scss";

type CheckoutErrorSummaryProps = {
  items: CartItem[];
  onRemoveItems(itemIds: string[]): Promise<void>;
  className?: string;
  variant?: "regular" | "compact";
};

export const CheckoutErrorSummary: FC<CheckoutErrorSummaryProps> = ({
  onRemoveItems,
  items,
  className,
  variant = "regular",
}) => {
  const { __: translate } = useTranslations();
  const { PROFILE_PAGE_URL } = useConfig();
  const mySubscriptionPage = PROFILE_PAGE_URL + "#/subscription";
  const outOfStockItems = getOutOfStockItems(items);
  const { isOrderLimitError, maxOrderLimit } = useOrderLimit();
  const cartonsLimitValue = useSelector((state) => state.cart.storeConfig?.cartons_limit_value);
  const packsLimitValue = cartonsLimitValue * 10;

  const ongoingSubscriptionItems = useMemo(
    () => filterItemsByErrorCode(items, ExtendedCartItemErrorType.Ongoing),
    [items]
  );

  const subscriptionLimitReachedItems = useMemo(
    () => filterItemsByErrorCode(items, ExtendedCartItemErrorType.CartonLimit),
    [items]
  );

  const trueConditionsCount = [
    outOfStockItems.length > 0,
    ongoingSubscriptionItems.length > 0,
    subscriptionLimitReachedItems.length > 0,
    isOrderLimitError,
  ].filter(Boolean).length;

  const shouldDisplayErrorBox = trueConditionsCount >= 1;

  const isBoxWithMultipleErrors = trueConditionsCount >= 2;

  if (!shouldDisplayErrorBox) {
    return null;
  }

  const extendedOnRemoveItems = async (itemsIds: string[]) => {
    await onRemoveItems(itemsIds);
    window.location.href = mySubscriptionPage;
  };

  return (
    <div className={classNames(styles.marginBottom, className)}>
      <MessageBox isVisible={true} variant={MessageBoxVariant.warning} isScrollable={false}>
        {isBoxWithMultipleErrors && variant === "regular" && (
          <p className={classNames(styles.title, styles.titleRegular)}>{translate("cart.items.multipleError")}</p>
        )}
        {outOfStockItems.length > 0 && (
          <div className={styles.singleErrorBox}>
            <OutOfStockItems items={outOfStockItems} className={styles.outOfStock} variant={variant} />
          </div>
        )}
        {isOrderLimitError && (
          <div className={styles.singleErrorBox}>
            <PriceLimitErrorMessage variant={variant} maxOrderLimit={maxOrderLimit} />
          </div>
        )}
        {subscriptionLimitReachedItems.length > 0 && (
          <div className={styles.singleErrorBox}>
            <CheckoutErrorMessage
              items={subscriptionLimitReachedItems}
              variant={variant}
              title={translate("cart.items.subscription_limit_reached_message_box.title")}
              intro={
                <I18n t="cart.items.subscription_limit_reached_message_box.intro">
                  <b>
                    <I18n t="cart.items.subscription_limit_reached_message_box.introMiddle">
                      <>{cartonsLimitValue}</>
                      <>{packsLimitValue}</>
                    </I18n>
                  </b>
                </I18n>
              }
            />
          </div>
        )}
        {ongoingSubscriptionItems.length > 0 && (
          <div className={styles.singleErrorBox}>
            <CheckoutErrorMessage
              items={ongoingSubscriptionItems}
              onRemoveItems={extendedOnRemoveItems}
              variant={variant}
              title={translate("cart.items.ongoing_subscriptions_message_box.title")}
              intro={translate("cart.items.ongoing_subscriptions_message_box.intro")}
              buttonText={translate("cart.items.ongoing_subscriptions_message_box.remove_button")}
            />
          </div>
        )}
      </MessageBox>
    </div>
  );
};
