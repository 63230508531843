// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label-module-label-N06Od{font-size:12px;color:#5c615e;grid-area:label;display:flex;align-items:center;line-height:1.3}.Label-module-labelCaption-3aQi1{font-size:12px;color:#5c615e}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Label-module-label-N06Od",
	"labelCaption": "Label-module-labelCaption-3aQi1"
};
export default ___CSS_LOADER_EXPORT___;
