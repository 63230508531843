// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgeVerificationBanner-module-description-XiMEv{margin-top:17px;margin-bottom:17px}.AgeVerificationBanner-module-description-XiMEv.AgeVerificationBanner-module-successDescription-s6jx3{margin-top:0}.AgeVerificationBanner-module-warningLink-Ih\\+xS{text-decoration:none;font-weight:500;color:#3567ff}.AgeVerificationBanner-module-warningLink-Ih\\+xS:hover{text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "AgeVerificationBanner-module-description-XiMEv",
	"successDescription": "AgeVerificationBanner-module-successDescription-s6jx3",
	"warningLink": "AgeVerificationBanner-module-warningLink-Ih+xS"
};
export default ___CSS_LOADER_EXPORT___;
