import { ISpecialOffer, ISpecialOfferTypeEnum } from "@jti/magento";

export function usePromotionalOffers(
  itemQuantity: number,
  promotionalOffers?: ISpecialOffer[]
): { isLimitReached: boolean; hasPromotionalOffers: boolean; promotionalOfferLimit: number } {
  let isLimitReached = false;
  let smallestLimit = Infinity;

  if (!promotionalOffers) {
    return {
      isLimitReached,
      hasPromotionalOffers: false,
      promotionalOfferLimit: smallestLimit,
    };
  }

  promotionalOffers.forEach((promotionalOffer) => {
    if (promotionalOffer.type === ISpecialOfferTypeEnum.ConditionalPurchase && !isLimitReached) {
      isLimitReached = itemQuantity >= promotionalOffer.limit_qty;
      smallestLimit = Math.min(smallestLimit, promotionalOffer.limit_qty);
    }
  });

  return {
    isLimitReached,
    hasPromotionalOffers: promotionalOffers.length > 0,
    promotionalOfferLimit: smallestLimit,
  };
}
