// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectItemDialog-module-dialog-uDjyc{display:grid;grid-gap:28px;gap:28px;padding-block:0;padding-inline:24px}.SelectItemDialog-module-title-7sHOM{font-size:26px;color:#1d1d1d;font-weight:300;letter-spacing:0;line-height:38px;text-align:center}@media(min-width: 960px){.SelectItemDialog-module-title-7sHOM{text-align:start}}.SelectItemDialog-module-subTitle-VY2NV,.SelectItemDialog-module-footerText-h4aAD{color:#373a36;line-height:33px;font-size:16px;font-weight:300;letter-spacing:0;margin-top:-20px}.SelectItemDialog-module-subTitle-VY2NV{text-align:center}@media(min-width: 960px){.SelectItemDialog-module-subTitle-VY2NV{text-align:start}}.SelectItemDialog-module-cardItems-Gvhbs{display:grid;grid-gap:10px;gap:10px;grid-template-columns:1fr}@media(min-width: 960px){.SelectItemDialog-module-cardItems-Gvhbs{grid-template-columns:1fr 1fr;gap:20px}}.SelectItemDialog-module-cardItems-Gvhbs input[type=radio]{width:18px;height:18px}.SelectItemDialog-module-radio-mMThh{margin:-10px}.SelectItemDialog-module-content-lpUEA{display:grid;grid-template-columns:40px auto}.SelectItemDialog-module-createNew-OHgQ2{display:flex;justify-content:center}.SelectItemDialog-module-btnGroup-\\+cfL-{display:flex;justify-content:center}.SelectItemDialog-module-cancelBtn-wUCfJ{width:160px}.SelectItemDialog-module-saveBtn-PAgfb{margin-inline-start:19px;width:160px}.SelectItemDialog-module-dialog-uDjyc button.SelectItemDialog-module-linkyBtn-XX32k{font-size:16px;font-weight:500;letter-spacing:0;line-height:23px}.SelectItemDialog-module-option-FOeH2{box-sizing:border-box;padding:20px;border-radius:8px;border:1px solid #979797}.SelectItemDialog-module-option-FOeH2.SelectItemDialog-module-selected-BWX6r{border:1px solid #3567ff;border:1px solid var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "SelectItemDialog-module-dialog-uDjyc",
	"title": "SelectItemDialog-module-title-7sHOM",
	"subTitle": "SelectItemDialog-module-subTitle-VY2NV",
	"footerText": "SelectItemDialog-module-footerText-h4aAD",
	"cardItems": "SelectItemDialog-module-cardItems-Gvhbs",
	"radio": "SelectItemDialog-module-radio-mMThh",
	"content": "SelectItemDialog-module-content-lpUEA",
	"createNew": "SelectItemDialog-module-createNew-OHgQ2",
	"btnGroup": "SelectItemDialog-module-btnGroup-+cfL-",
	"cancelBtn": "SelectItemDialog-module-cancelBtn-wUCfJ",
	"saveBtn": "SelectItemDialog-module-saveBtn-PAgfb",
	"linkyBtn": "SelectItemDialog-module-linkyBtn-XX32k",
	"option": "SelectItemDialog-module-option-FOeH2",
	"selected": "SelectItemDialog-module-selected-BWX6r"
};
export default ___CSS_LOADER_EXPORT___;
