// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailOtp-module-border-K6bDY{margin-top:32px;padding-block:0;padding-inline:18px;border:1px solid #f6f4ea;border-radius:8px}.EmailOtp-module-inputStyle-Gy9A3{box-sizing:border-box;height:50px;width:50px !important;border:1px solid #373a36;border-radius:8px;margin-inline-end:20px;font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px;color:#5c615e}.EmailOtp-module-text-H834Y{font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px;padding-block:24px;padding-inline:0;color:#373a36}@media(min-width: 960px){.EmailOtp-module-text-H834Y{font-size:16px;line-height:23px;letter-spacing:0}}.EmailOtp-module-resendBtn-cnkWw{margin-inline-start:5px}.EmailOtp-module-timer-xu4lc{font-size:14px;font-weight:300;letter-spacing:0;line-height:23px}.EmailOtp-module-requestNewCode-TnAtQ{color:#ed6d6b}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": "EmailOtp-module-border-K6bDY",
	"inputStyle": "EmailOtp-module-inputStyle-Gy9A3",
	"text": "EmailOtp-module-text-H834Y",
	"resendBtn": "EmailOtp-module-resendBtn-cnkWw",
	"timer": "EmailOtp-module-timer-xu4lc",
	"requestNewCode": "EmailOtp-module-requestNewCode-TnAtQ"
};
export default ___CSS_LOADER_EXPORT___;
