// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Promotion-module-promotionButton-Be4IR{display:flex;flex:1 1;padding:20px;background-color:#fcfcfc;border-radius:8px;border:1px solid #7c7c7c;cursor:pointer;transition:all .3s ease}.Promotion-module-promotionButton-Be4IR img{width:55px;height:54px;flex-shrink:0;margin-inline:auto}@media(max-width: 768px){.Promotion-module-promotionButton-Be4IR img{width:40px;height:39px}}@media(min-width: 1070px){.Promotion-module-promotionButton-Be4IR:hover{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff)}.Promotion-module-promotionButton-Be4IR:hover img{filter:brightness(0) invert(1)}}.Promotion-module-promotionButton-Be4IR.Promotion-module-isActive-iCQvj{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff)}.Promotion-module-promotionButton-Be4IR.Promotion-module-isActive-iCQvj img{filter:brightness(0) invert(1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotionButton": "Promotion-module-promotionButton-Be4IR",
	"isActive": "Promotion-module-isActive-iCQvj"
};
export default ___CSS_LOADER_EXPORT___;
