// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo-module-logo-A2tz2{display:block;width:50px;height:50px;position:relative}@media(min-width: 960px){.Logo-module-logo-A2tz2{width:75px;height:75px}}.Logo-module-image-8WF4p{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo-module-logo-A2tz2",
	"image": "Logo-module-image-8WF4p"
};
export default ___CSS_LOADER_EXPORT___;
