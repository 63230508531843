// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Invoice-module-wrapper-1VlaT{display:flex;justify-content:center;padding-bottom:24px;padding-top:24px}@media(min-width: 960px){.Invoice-module-wrapper-1VlaT{justify-content:flex-end}}.Invoice-module-anchor--2yAr{color:#373a36;text-decoration:none;cursor:pointer;display:inline-flex;align-items:center;gap:10px}.Invoice-module-anchor--2yAr:hover .Invoice-module-icon-AKGcx{color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Invoice-module-wrapper-1VlaT",
	"anchor": "Invoice-module-anchor--2yAr",
	"icon": "Invoice-module-icon-AKGcx"
};
export default ___CSS_LOADER_EXPORT___;
