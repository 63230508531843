// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseButton-module-close-jTGX1{text-align:center;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;z-index:9999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "CloseButton-module-close-jTGX1"
};
export default ___CSS_LOADER_EXPORT___;
