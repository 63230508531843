import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as StoreProvider } from "react-redux";

import { store } from "@jti/store";
import { LocaleContext } from "@jti/ui";

import AppRunner from "@checkout/components/AppRunner";
import { getGlobalStore } from "@checkout/utils/globalStore";

import CartMini from "./CartMini";

export type CartMiniLayoutProps = {
  globalStoreResolved: boolean;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const CartMiniLayout = ({ globalStoreResolved }: CartMiniLayoutProps) => {
  return (
    <StoreProvider store={globalStoreResolved ? getGlobalStore() : store}>
      <LocaleContext.Provider value={"en-US"}>
        <QueryClientProvider client={queryClient}>
          <CartMini />
          <AppRunner />
        </QueryClientProvider>
      </LocaleContext.Provider>
    </StoreProvider>
  );
};
