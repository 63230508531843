import useLoyaltyProductPoints from "../../hooks/useLoyaltyProductPoints";
import useLoyaltyStatus from "../../hooks/useLoyaltyStatus";

import CartMiniItems, { CartMiniItemsType } from "./CartMiniItems";
import CartMiniPointsBlock from "./CartMiniPointsBlock";

interface CartMiniContentType extends CartMiniItemsType {
  buyLimitation: boolean;
}

const CartMiniContent = ({
  pending,
  items,
  qtyPending,
  buyLimitation,
  onRemoveItemClick,
  onItemQuantityChange,
  onRemoveItems,
}: CartMiniContentType): JSX.Element => {
  const hasLoyalty = useLoyaltyStatus();
  const totalLoyaltyPoints = useLoyaltyProductPoints(items);

  return (
    <div className={"mini-cart__content-container"}>
      <CartMiniItems
        pending={pending}
        items={items}
        qtyPending={qtyPending}
        buyLimitation={buyLimitation}
        onRemoveItemClick={onRemoveItemClick}
        onItemQuantityChange={onItemQuantityChange}
        onRemoveItems={onRemoveItems}
      />
      <CartMiniPointsBlock totalPoints={totalLoyaltyPoints} pending={pending} isActive={hasLoyalty} />
    </div>
  );
};

export default CartMiniContent;
