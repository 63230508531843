// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryFilterItem-module-wrapper-Qi5zY{display:inline-flex;flex-direction:column;gap:10px;position:relative;align-items:center;-webkit-user-select:none;-ms-user-select:none;user-select:none}.CategoryFilterItem-module-item-A\\+X2l{cursor:pointer;width:100px;height:100px;padding:25px;display:flex;align-items:center;justify-content:center;border-radius:50%;background:rgba(210,210,210,.5019607843);border:2px solid rgba(0,0,0,0);position:relative;-webkit-tap-highlight-color:rgba(0,0,0,0)}.CategoryFilterItem-module-activeIcon-4Y0Nt{position:absolute;width:20px;height:20px;background-color:#3558fb;border-radius:50%;top:0;inset-inline-end:0;box-sizing:border-box;border:1px solid #fff;transform:translate(-15%, 15%);display:flex;justify-content:center;align-items:center}.CategoryFilterItem-module-active-kbmpn{background:#f4f4f4;border:2px solid #3558fb}.CategoryFilterItem-module-checkMark-eZp6L{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CategoryFilterItem-module-wrapper-Qi5zY",
	"item": "CategoryFilterItem-module-item-A+X2l",
	"activeIcon": "CategoryFilterItem-module-activeIcon-4Y0Nt",
	"active": "CategoryFilterItem-module-active-kbmpn",
	"checkMark": "CategoryFilterItem-module-checkMark-eZp6L"
};
export default ___CSS_LOADER_EXPORT___;
