// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toggle-module-wrapper-gcapj{display:flex;align-items:center;width:38px;height:18px;flex-shrink:0;border-radius:9px;background-color:var(--colors-background-bg-quaternary);padding-inline:1px;cursor:pointer;transition:background-color 115ms ease-out}.Toggle-module-wrapper-gcapj:focus-within{outline:4px solid var(--colors-border-border-focus)}.Toggle-module-wrapperActive-vE98j{background-color:var(--colors-background-bg-brand)}.Toggle-module-wrapperDisabled-4pDaG{background-color:var(--colors-background-bg-primary-disabled);cursor:default}.Toggle-module-circle-0Wg04{width:16px;height:16px;background-color:#fff;border-radius:50%;transition:transform 115ms ease-out}.Toggle-module-wrapperActive-vE98j .Toggle-module-circle-0Wg04{transform:translateX(20px)}[dir=rtl] .Toggle-module-wrapperActive-vE98j .Toggle-module-circle-0Wg04{transform:translateX(-20px)}.Toggle-module-checkboxInput-DjEvg{opacity:0;width:0;height:0;position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Toggle-module-wrapper-gcapj",
	"wrapperActive": "Toggle-module-wrapperActive-vE98j",
	"wrapperDisabled": "Toggle-module-wrapperDisabled-4pDaG",
	"circle": "Toggle-module-circle-0Wg04",
	"checkboxInput": "Toggle-module-checkboxInput-DjEvg"
};
export default ___CSS_LOADER_EXPORT___;
