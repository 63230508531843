import { useEffect, useState } from "react";

import { useSelector } from "@jti/store";

const useOrderLimit = () => {
  const grandTotal = useSelector((state) => state.cart.prices?.grandTotal.value);
  const shipping = useSelector((state) => state.cart.prices?.shipping?.value);
  const [limitMessageStatus, setLimitMessageStatus] = useState(false);
  const { is_order_limit_enabled: isLimitEnabled, order_limit_value: maxOrderLimit } = useSelector(
    (state) => state.cart.storeConfig
  );

  useEffect(() => {
    setLimitMessageStatus((grandTotal || 0) - (shipping || 0) > maxOrderLimit && isLimitEnabled);
  }, [grandTotal, shipping, maxOrderLimit, isLimitEnabled]);

  const isOrderLimitError = limitMessageStatus && isLimitEnabled;

  return { isOrderLimitError, maxOrderLimit };
};

export default useOrderLimit;
