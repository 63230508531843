// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FaqItem-module-item-Wb55n{margin:0;padding:0;list-style:none}.FaqItem-module-item-Wb55n:not(:last-child){margin-bottom:20px}.FaqItem-module-box-v7OZU{width:100%;border:1px solid #979797;border-radius:8px}.FaqItem-module-faqTitle-UPWKA{display:flex;justify-content:space-between;align-items:flex-start;width:100%;padding-block:24px;padding-inline:17px;cursor:pointer;border:0;outline:none;background-color:rgba(0,0,0,0);text-align:start;color:#5c615e;font-size:16px;font-weight:bold;letter-spacing:.17px;line-height:24px}@media(min-width: 960px){.FaqItem-module-faqTitle-UPWKA{padding-block:24px;padding-inline:22px;font-size:18px;letter-spacing:.19px}}.FaqItem-module-faqTitleIcon-oo3yN{position:relative;display:flex;flex-shrink:0;width:16px;margin-block:12px 0;margin-inline:16px 0}[dir=rtl] .FaqItem-module-faqTitleIcon-oo3yN{width:22px}.FaqItem-module-faqTitleIcon-oo3yN::before{transition:.25s ease-in-out;transform:translate(-50%, -50%) rotate(90deg)}.FaqItem-module-open-yzk-e .FaqItem-module-faqTitleIcon-oo3yN::before{transform:translate(-50%, -50%)}.FaqItem-module-faqTitleIcon-oo3yN::after{transform:translate(-50%, -50%)}.FaqItem-module-faqTitleIcon-oo3yN::before,.FaqItem-module-faqTitleIcon-oo3yN::after{position:absolute;top:50%;inset-inline-start:50%;width:16px;height:2px;content:\"\";border-radius:1px;background-color:#000}[dir=rtl] .FaqItem-module-faqTitleIcon-oo3yN::before,[dir=rtl] .FaqItem-module-faqTitleIcon-oo3yN::after{inset-inline-start:0}.FaqItem-module-faqDetails-90sCc{max-height:0;transition:max-height .25s ease-in-out;overflow:hidden}.FaqItem-module-open-yzk-e .FaqItem-module-faqDetails-90sCc{max-height:9999px}.FaqItem-module-content-xqoHX{position:relative;padding-block:0 20px;padding-inline:20px 48px;color:#373a36;font-size:16px;font-weight:300;letter-spacing:0;line-height:23px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "FaqItem-module-item-Wb55n",
	"box": "FaqItem-module-box-v7OZU",
	"faqTitle": "FaqItem-module-faqTitle-UPWKA",
	"faqTitleIcon": "FaqItem-module-faqTitleIcon-oo3yN",
	"open": "FaqItem-module-open-yzk-e",
	"faqDetails": "FaqItem-module-faqDetails-90sCc",
	"content": "FaqItem-module-content-xqoHX"
};
export default ___CSS_LOADER_EXPORT___;
