// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionButtons-module-wrapper-Kn8Sm{display:flex;margin-bottom:32px;width:100%;gap:10px;flex-direction:column}@media(min-width: 960px){.ActionButtons-module-wrapper-Kn8Sm{flex-direction:row;gap:20px}}.ActionButtons-module-button-Gj0hK{max-height:40px;width:100%}@media(min-width: 960px){.ActionButtons-module-button-Gj0hK{max-height:100%;width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ActionButtons-module-wrapper-Kn8Sm",
	"button": "ActionButtons-module-button-Gj0hK"
};
export default ___CSS_LOADER_EXPORT___;
