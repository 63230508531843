// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScanQrCodeDialog-module-dialogContainer-G1haD{padding-inline:0}.ScanQrCodeDialog-module-dialog-SabFn{width:100%;height:100%;max-width:none}.ScanQrCodeDialog-module-dialog-SabFn>div:first-of-type{height:calc(100% - 32px)}.ScanQrCodeDialog-module-backButton-nTobp{position:absolute;top:30px;inset-inline-start:30px;display:flex;align-items:center}.ScanQrCodeDialog-module-backIcon-AQVux{margin-inline-end:8px}.ScanQrCodeDialog-module-qrContainer-kZpdF{display:flex;flex-direction:column;justify-content:space-between;margin-top:32px;height:100%}.ScanQrCodeDialog-module-qrImageWrapper-XTe8L video{border-radius:8px;-webkit-mask-image:radial-gradient(white, black);mask-image:radial-gradient(white, black)}.ScanQrCodeDialog-module-cameraBlockContainer-jrjrZ{position:relative;width:100%;height:100%;padding-top:20px;padding-bottom:20px}.ScanQrCodeDialog-module-cameraOverlay-XX\\+FN{position:absolute;top:5%;inset-inline:5%;bottom:5%;z-index:3;background:linear-gradient(to right, #fff 2px, transparent 2px) 0 0,linear-gradient(to right, #fff 2px, transparent 2px) 0 100%,linear-gradient(to left, #fff 2px, transparent 2px) 100% 0,linear-gradient(to left, #fff 2px, transparent 2px) 100% 100%,linear-gradient(to bottom, #fff 2px, transparent 2px) 0 0,linear-gradient(to bottom, #fff 2px, transparent 2px) 100% 0,linear-gradient(to top, #fff 2px, transparent 2px) 0 100%,linear-gradient(to top, #fff 2px, transparent 2px) 100% 100%;background-repeat:no-repeat;background-size:40px 40px}.ScanQrCodeDialog-module-dialogTextIndents-06-bb{padding-inline:0}.ScanQrCodeDialog-module-cameraAccessMessage-loAei{position:absolute;top:50%;inset-inline-start:50%;transform:translate(-50%, -50%);text-align:center;z-index:1}[dir=rtl] .ScanQrCodeDialog-module-cameraAccessMessage-loAei{transform:translate(50%, -50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "ScanQrCodeDialog-module-dialogContainer-G1haD",
	"dialog": "ScanQrCodeDialog-module-dialog-SabFn",
	"backButton": "ScanQrCodeDialog-module-backButton-nTobp",
	"backIcon": "ScanQrCodeDialog-module-backIcon-AQVux",
	"qrContainer": "ScanQrCodeDialog-module-qrContainer-kZpdF",
	"qrImageWrapper": "ScanQrCodeDialog-module-qrImageWrapper-XTe8L",
	"cameraBlockContainer": "ScanQrCodeDialog-module-cameraBlockContainer-jrjrZ",
	"cameraOverlay": "ScanQrCodeDialog-module-cameraOverlay-XX+FN",
	"dialogTextIndents": "ScanQrCodeDialog-module-dialogTextIndents-06-bb",
	"cameraAccessMessage": "ScanQrCodeDialog-module-cameraAccessMessage-loAei"
};
export default ___CSS_LOADER_EXPORT___;
