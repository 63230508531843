// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlogListing-module-container-ChDIF{display:flex;flex-direction:column;align-items:center}.BlogListing-module-articleList-ACRt0{display:flex;flex-flow:row wrap;justify-content:space-between;padding-bottom:48px;padding-bottom:var(--spacing-2xl, 48px);margin:auto}@media(min-width: 768px){.BlogListing-module-articleList-ACRt0{max-width:940px;max-width:var(--container-mw, 940px)}}@media(min-width: 1280px){.BlogListing-module-articleList-ACRt0{max-width:1260px;max-width:var(--container-max-width, 1260px);padding-bottom:100px;padding-bottom:var(--spacing-4xl, 100px)}}.BlogListing-module-loading-Tpnio{margin:auto}.BlogListing-module-error-hGm\\+d{margin-block:100px;margin-inline:auto;text-align:center;padding-block:0;padding-inline:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BlogListing-module-container-ChDIF",
	"articleList": "BlogListing-module-articleList-ACRt0",
	"loading": "BlogListing-module-loading-Tpnio",
	"error": "BlogListing-module-error-hGm+d"
};
export default ___CSS_LOADER_EXPORT___;
