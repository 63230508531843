// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toaster-module-toast-G8e\\+F{max-width:80%;color:#373a36;padding-inline:24px}.Toaster-module-toast-G8e\\+F svg{flex-shrink:0;margin-inline-end:24px}.Toaster-module-toast-G8e\\+F.Toaster-module-error-99Y\\+J{background-color:#ff9896;padding-block:24px}.Toaster-module-toast-G8e\\+F.Toaster-module-success-CyZHb{background-color:#b9dcd2;padding-block:21px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": "Toaster-module-toast-G8e+F",
	"error": "Toaster-module-error-99Y+J",
	"success": "Toaster-module-success-CyZHb"
};
export default ___CSS_LOADER_EXPORT___;
