// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.OrderAttributesInfo-module-orderAttributes-QThO2 .OrderAttributesInfo-module-orderAttributesText-KkBBh{color:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderAttributes": "OrderAttributesInfo-module-orderAttributes-QThO2",
	"orderAttributesText": "OrderAttributesInfo-module-orderAttributesText-KkBBh"
};
export default ___CSS_LOADER_EXPORT___;
