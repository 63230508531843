// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonContactForm-module-formField-zavaJ{margin-bottom:20px}@media(min-width: 960px){.CommonContactForm-module-formField-zavaJ{margin-bottom:30px}}.CommonContactForm-module-disclaimer-UQLRR{margin-block-start:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "CommonContactForm-module-formField-zavaJ",
	"disclaimer": "CommonContactForm-module-disclaimer-UQLRR"
};
export default ___CSS_LOADER_EXPORT___;
