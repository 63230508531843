import { FC } from "react";

import classNames from "classnames";

import { IItemSelectedBundleOptionValue, ISelectedBundleOptionValue, Product } from "@jti/magento";
import { useTranslations } from "@jti/ui";

import styles from "../SingleError.module.scss";

type OutOfStockItemsProps = {
  items: (Product | ISelectedBundleOptionValue | IItemSelectedBundleOptionValue)[];
  className?: string;
  variant?: "regular" | "compact";
};

export const OutOfStockItems: FC<OutOfStockItemsProps> = ({ items, variant = "regular", className }) => {
  const { __: translate } = useTranslations();
  if (items.length === 0) {
    return null;
  }
  return (
    <div className={className}>
      <p className={classNames(styles.title, { [styles.titleRegular]: variant === "regular" })}>
        {translate("cart.items.out_of_stock_message_box.title")}
      </p>
      {variant === "regular" ? (
        <div className={styles.messageContainer}>
          <p>{translate("cart.items.out_of_stock_message_box.intro")}</p>
          <ul className={styles.productsList}>
            {items.map((item) => {
              const name = "name" in item ? item.name : undefined;
              const label = "label" in item ? item.label : undefined;
              return (
                <li key={name || label}>
                  <p className={styles.name}>{name || label}</p>
                </li>
              );
            })}
          </ul>
          <p>{translate("cart.items.out_of_stock_message_box.proceedText")}</p>
        </div>
      ) : null}
    </div>
  );
};
