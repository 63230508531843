import { useEffect } from "react";

import { getUser } from "@jti/rest-api";
import {
  clearFreeTrialRedirect,
  fetchCart,
  fetchCurrency,
  fetchStoreConfig,
  fetchTrialCart,
  isFreeTrialRedirect,
  redirectToTrialAuthWithBack,
  useDispatch,
  useSelector,
} from "@jti/store";

import useProductListener from "../../hooks/useProductListener";

const AppRunner = (): JSX.Element => {
  const user = getUser();
  const dispatch = useDispatch();
  const storeGroupCode = useSelector((state) => state.cart.storeConfig?.store_group_code);
  useProductListener();

  useEffect(() => {
    if (isFreeTrialRedirect()) {
      if (user?.email) {
        dispatch(fetchTrialCart({ useAnonymous: true }));
      } else {
        clearFreeTrialRedirect();
        redirectToTrialAuthWithBack();
      }
    }
    dispatch(fetchCurrency());
    dispatch(fetchStoreConfig());
  }, [dispatch]);

  useEffect(() => {
    if (storeGroupCode) {
      dispatch(fetchCart({ useAnonymous: true }));
    }
  }, [dispatch, storeGroupCode]);

  return <></>;
};

export default AppRunner;
