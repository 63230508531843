// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegularTelephone-module-control-HZRrE{display:flex;flex-basis:auto;gap:16px}[dir=rtl] .RegularTelephone-module-control-HZRrE{flex-direction:row-reverse;justify-content:flex-end}.RegularTelephone-module-code-BtSWT{padding-block:10px 0;min-width:115px}.RegularTelephone-module-phoneSelectBlock-R40Z9{display:flex}.RegularTelephone-module-telephoneFullWidth-KNlPw{width:100%}.RegularTelephone-module-phoneContainer-W-63a{width:100%}.RegularTelephone-module-prefix-Rm9YA{margin-inline-end:8px;top:24px;height:20px;position:relative}.RegularTelephone-module-info-4pMg\\+{margin-block-start:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "RegularTelephone-module-control-HZRrE",
	"code": "RegularTelephone-module-code-BtSWT",
	"phoneSelectBlock": "RegularTelephone-module-phoneSelectBlock-R40Z9",
	"telephoneFullWidth": "RegularTelephone-module-telephoneFullWidth-KNlPw",
	"phoneContainer": "RegularTelephone-module-phoneContainer-W-63a",
	"prefix": "RegularTelephone-module-prefix-Rm9YA",
	"info": "RegularTelephone-module-info-4pMg+"
};
export default ___CSS_LOADER_EXPORT___;
