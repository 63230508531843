import { memo, RefObject, useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { globalEventsHandler } from "@jti/fe-common";
import { Product as CartItem } from "@jti/magento";
import { IconLabeled, Loading } from "@jti/ui";

import useClickOutside from "../../hooks/useClickOutside";
import debouncer from "../../utils/debouncer";
import getElementHeightWithMargins from "../../utils/getElementHeightWithMargins";

import CartIcon from "./cart.svg";
import CartFilledIcon from "./cartFilled.svg";
import CartMiniCheckoutButton from "./CartMiniCheckoutButton";
import CartMiniContent from "./CartMiniContent";
import CartMiniHeader, { CartMiniHeaderType } from "./CartMiniHeader";

import "./style.scss";

export interface CartMiniProps extends CartMiniHeaderType {
  itemsPending?: boolean;
  qtyPending?: boolean;
  items: CartItem[];
  opened?: boolean;
  warningStatus?: boolean;
  isLimitEnabled?: boolean;
  isOnCartPage: boolean;

  onOpen?(): void;
  onClose?(): void;
  onInteract?(): void;
  onRemoveItemClick(itemId: string): void;
  onItemQuantityChange(itemId: string, value: number): void;
  onCheckoutClick(): void;
  onRemoveItems(itemIds: string[]): Promise<void>;
}

type CartMiniCustomStyles = {
  maxHeight?: number;
  minHeight?: number;
};

const CartMini = ({
  itemsPending: pending = false,
  qtyPending = false,
  subtotal,
  items,
  opened,
  onOpen,
  onClose,
  itemsCount,
  onRemoveItemClick,
  onItemQuantityChange,
  onCheckoutClick,
  onRemoveItems,
  warningStatus,
  isLimitEnabled,
  isOnCartPage,
}: CartMiniProps): JSX.Element => {
  const miniCartRef = useRef<HTMLDivElement>(null);
  const miniCartHeaderRef = useRef<HTMLDivElement>(null);
  const buyLimitation = !!(warningStatus && isLimitEnabled);
  const [fullScreenMinicart, setFullScreenMinicart] = useState<boolean>(false);
  const [customStyles, setCustomStyles] = useState<CartMiniCustomStyles>({});
  const classes = classNames("mini-cart", {
    "mini-cart--open": opened,
    "mini-cart--dark": document.querySelector(".page--dark"),
    "mini-cart--with-items": items?.length > 0,
    "mini-cart--no-items": !(items?.length > 0),
    "mini-cart--fullscreen": fullScreenMinicart,
  });

  useEffect(() => {
    globalEventsHandler.getCartActive().dispatch({
      active: (opened || isOnCartPage) && !pending,
    });
  }, [opened, isOnCartPage, pending]);

  const onClickLabel = () => {
    if (!pending) {
      if (!opened && onOpen) {
        onOpen();
      } else if (onClose && opened) {
        onClose();
      }
    }
  };

  const Icon = () => {
    if (pending) {
      return <Loading className="mini-cart-loading" />;
    }

    return (
      <IconLabeled
        onClick={onClickLabel}
        component={opened || isOnCartPage ? CartFilledIcon : CartIcon}
        width="20px"
        height="20px"
        label={itemsCount > 0 ? itemsCount : undefined}
        labelClassName={"mini-cart__icon-label"}
        className={"mini-cart__icon"}
        dataTestId={"cartIcon"}
      />
    );
  };

  useClickOutside(miniCartRef, () => {
    if (onClose) {
      onClose();
    }
  });

  const handleFullScreenMinicart = () => {
    const height = getElementHeightWithMargins(miniCartHeaderRef);
    setFullScreenMinicart(height > window.innerHeight);
  };

  useEffect(() => {
    if (opened && !pending) {
      handleFullScreenMinicart();
    } else {
      setFullScreenMinicart(false);
    }
  }, [opened, pending]);

  useEffect(() => {
    const updateFrameHeight = () => {
      setCustomStyles(calculatFrameHeight(miniCartRef));
    };

    const handleResize = () => {
      updateFrameHeight();
      handleFullScreenMinicart();
    };

    const handleResizeDebounced = debouncer(() => {
      handleResize();
    }, 250);

    handleResize();

    window.addEventListener("resize", handleResizeDebounced);
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, []);

  useEffect(() => {
    const onClickEscape = (e: any) => {
      if (e.key === "Escape" && onClose) {
        onClose();
      }
    };

    window.document.addEventListener("keydown", onClickEscape);
    return () => window.document.removeEventListener("keydown", onClickEscape);
  }, []);

  return (
    <div style={{ position: "relative", zIndex: 100 }} ref={miniCartRef} data-testid={"cart"}>
      <Icon />
      <div className={classes} data-testid={"mini-cart-header"} ref={miniCartHeaderRef}>
        <div className={"mini-cart__container"} style={customStyles}>
          <CartMiniHeader subtotal={subtotal} itemsCount={itemsCount} onClose={onClose} />
          <CartMiniContent
            pending={pending}
            items={items}
            qtyPending={qtyPending}
            buyLimitation={buyLimitation}
            onRemoveItemClick={onRemoveItemClick}
            onItemQuantityChange={onItemQuantityChange}
            onRemoveItems={onRemoveItems}
          />
          <CartMiniCheckoutButton
            qtyPending={qtyPending}
            isVisible={items?.length > 0}
            buyLimitation={buyLimitation}
            onCheckoutClick={onCheckoutClick}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CartMini);

function calculatFrameHeight(miniCartRef: RefObject<HTMLDivElement>) {
  const ICON_HEIGHT = 25;
  const MIN_BOTTOM_MARGIN = 10;
  const MIN_FRAME_SIZE = 210;
  const MAX_HEIGHT = 620;
  const MIN_HEIGHT = 290;
  const MIN_WINDOW_WIDTH = 960;
  const { innerHeight = 1000, innerWidth = 1000 } = window;

  if (innerWidth < MIN_WINDOW_WIDTH) {
    return {};
  }

  const { top: miniCartPosition = 0 } = miniCartRef?.current?.getBoundingClientRect() ?? {};
  const maxHeight = Math.floor(innerHeight - miniCartPosition - ICON_HEIGHT - MIN_BOTTOM_MARGIN);
  const minHeight = Math.min(maxHeight, MIN_HEIGHT);

  return {
    maxHeight: Math.min(maxHeight, MAX_HEIGHT),
    minHeight: Math.max(minHeight, MIN_FRAME_SIZE),
  };
}
