// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PasswordRequirementsBlock-module-infoBlock-fnBH3{margin-top:24px;display:grid;grid-gap:4px;gap:4px}.PasswordRequirementsBlock-module-iconClassName-kjIN7{height:auto;margin-bottom:8px}.PasswordRequirementsBlock-module-requirementDescription-bJGVV{line-height:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlock": "PasswordRequirementsBlock-module-infoBlock-fnBH3",
	"iconClassName": "PasswordRequirementsBlock-module-iconClassName-kjIN7",
	"requirementDescription": "PasswordRequirementsBlock-module-requirementDescription-bJGVV"
};
export default ___CSS_LOADER_EXPORT___;
