import { FC } from "react";

import classNames from "classnames";

import { Icon, useTranslations } from "@jti/ui";
import Subscription from "@jti/ui/icons/subscription.svg";

import styles from "./SubscriptionItemFlag.module.scss";

export type SubscriptionItemFlagProps = {
  className?: string;
};

export const SubscriptionItemFlag: FC<SubscriptionItemFlagProps> = ({ className }) => {
  const { __ } = useTranslations();

  return (
    <div className={classNames(styles.container, className)}>
      <Icon component={Subscription} width="27px" height="27px" className={styles.icon} />
      <span className={styles.caption}>{__("cart.cart_item.subscription_information")}</span>
    </div>
  );
};
