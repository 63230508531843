// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactForm-module-formWrapper-R1EIs{border-radius:8px;padding:30px;background-color:#fcfcfc}.ContactForm-module-formWrapper-R1EIs .ContactForm-module-consent-checkbox__container-WoiOU{margin-bottom:40px}.ContactForm-module-title-Y7dTE{color:#373a36;text-overflow:ellipsis;-webkit-box-orient:vertical;-webkit-line-clamp:3;text-align:center;font-weight:300;font-size:26px;line-height:38px}@media(min-width: 960px){.ContactForm-module-title-Y7dTE{line-height:44px;font-size:32px;text-align:start}}.ContactForm-module-description-VKeuB{color:#373a36;margin-block:20px 30px;margin-inline:0;text-align:center}@media(min-width: 960px){.ContactForm-module-description-VKeuB{text-align:start}}.ContactForm-module-footerDisclaimer-w4nXf{color:#373a36;font-weight:300;display:flex;align-items:center;margin-block:30px;margin-inline:0}.ContactForm-module-buttonWrapper-4rBTO{position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": "ContactForm-module-formWrapper-R1EIs",
	"consent-checkbox__container": "ContactForm-module-consent-checkbox__container-WoiOU",
	"title": "ContactForm-module-title-Y7dTE",
	"description": "ContactForm-module-description-VKeuB",
	"footerDisclaimer": "ContactForm-module-footerDisclaimer-w4nXf",
	"buttonWrapper": "ContactForm-module-buttonWrapper-4rBTO"
};
export default ___CSS_LOADER_EXPORT___;
