// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExistingAccount-module-wrap-W-lUt{background-color:#fcfcfc;padding-block:24px;padding-inline:34px;border-radius:8px;display:flex;align-items:center}.ExistingAccount-module-icon-pJRWb{margin-inline-end:20px}.ExistingAccount-module-title-6L9Bc{font-weight:700}.ExistingAccount-module-text-U47SF{color:#5c615e;font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px}@media(min-width: 960px){.ExistingAccount-module-text-U47SF{color:#373a36;font-size:16px;letter-spacing:0;line-height:23px}}.ExistingAccount-module-text-U47SF a{text-decoration:none;font-weight:500;color:#3567ff;color:var(--colors-accent-accent-100, #3567ff)}.ExistingAccount-module-text-U47SF a:hover{text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ExistingAccount-module-wrap-W-lUt",
	"icon": "ExistingAccount-module-icon-pJRWb",
	"title": "ExistingAccount-module-title-6L9Bc",
	"text": "ExistingAccount-module-text-U47SF"
};
export default ___CSS_LOADER_EXPORT___;
