// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Image-module-container-f1956{width:100%;height:100px;background-repeat:no-repeat;background-size:contain;background-position:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Image-module-container-f1956"
};
export default ___CSS_LOADER_EXPORT___;
