import { FC, useEffect, useState } from "react";

import classNames from "classnames";

import { getConfig } from "@jti/fe-common";
import { useSelector } from "@jti/store";
import { FormattedPrice, ProductMiniature } from "@jti/ui";

import { BundleOptionValueProps } from "../BundleItem/BundleOptionValue";

import styles from "./CompactBundleItem.module.scss";

const CompactBundleOptionValue: FC<BundleOptionValueProps> = ({
  product_name,
  quantity,
  prices: { initial_price, presentation_price = null },
  imageUrl,
}) => {
  const { currency: shopCurrency } = useSelector((state) => state.cart);

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const isDarkModeFromDOM = document.querySelector(".page--dark") !== null;
    setIsDarkMode(isDarkModeFromDOM);
  }, [isDarkMode]);

  const classes = classNames(styles.bundleOption, {
    [styles.bundleOptionDark]: isDarkMode,
  });

  const priceClasses = classNames(styles.bundleOptionPrice, {
    [styles.bundleOptionPriceDark]: isDarkMode,
  });

  const initialPriceClasses = classNames(styles.bundleOptionInitialPrice, {
    [styles.bundleOptionInitialPriceDark]: isDarkMode,
  });

  return (
    <li className={classes} data-testid="compactBundleProductOption">
      {imageUrl && <ProductMiniature imageUrl={imageUrl} showTitle={false} name={product_name} isCompact={true} />}
      <div className={styles.bundleOptionHeader}>
        <strong className={styles.bundleOptionCategory} data-testid="compactBundleProductOptionName">
          {quantity}&times;&nbsp;{product_name}
        </strong>
      </div>
      {getConfig().displayBundleSelectedItemsPrices && (
        <div className={priceClasses} data-testid="compactBundleProductOptionPrice">
          {presentation_price !== null && initial_price !== presentation_price ? (
            <>
              <FormattedPrice value={presentation_price} currency={shopCurrency.code} />

              <s className={initialPriceClasses} data-testid="compactBundleProductOptionInitialPrice">
                <FormattedPrice value={initial_price} currency={shopCurrency.code} />
              </s>
            </>
          ) : (
            <FormattedPrice value={initial_price} currency={shopCurrency.code} />
          )}
        </div>
      )}
    </li>
  );
};

export default CompactBundleOptionValue;
