// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroContentAuthoringWrapper-module-authorWrapper-FAi0-{position:relative;height:100dvh;margin-bottom:60px}@media(min-width: 768px){.HeroContentAuthoringWrapper-module-authorWrapper-FAi0-{margin-bottom:40px}}.HeroContentAuthoringWrapper-module-isContainerWidth-bCP-W{border-radius:8px;overflow:hidden;height:auto}.HeroContentAuthoringWrapper-module-heroCardsContentWrapper-qHLDv{height:calc(100vh - var(--fixed-hero-cards-margin) - (var(--hero-cards-image-size) + 2*var(--hero-cards-padding))/2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorWrapper": "HeroContentAuthoringWrapper-module-authorWrapper-FAi0-",
	"isContainerWidth": "HeroContentAuthoringWrapper-module-isContainerWidth-bCP-W",
	"heroCardsContentWrapper": "HeroContentAuthoringWrapper-module-heroCardsContentWrapper-qHLDv"
};
export default ___CSS_LOADER_EXPORT___;
