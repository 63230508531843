// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreLocatorCard-module-resultCard-ceYIF{border-radius:8px;margin-bottom:20px;color:#fff;padding-block:15px;padding-inline:28px;font-size:16px;display:flex;flex-direction:column;border:1px solid rgba(255,255,255,.2);height:290px}@media(min-width: 960px){.StoreLocatorCard-module-resultCard-ceYIF{margin-inline-end:10px;height:360px}}.StoreLocatorCard-module-resultCard-ceYIF:last-child{margin-bottom:0}.StoreLocatorCard-module-resultCard-ceYIF.StoreLocatorCard-module-resultCardActive-T8pjv{border-width:2px;border-color:#3567ff}.StoreLocatorCard-module-resultCardShopName-TwBGo{font-size:18px;line-height:24px;font-weight:bold;padding-bottom:5px}.StoreLocatorCard-module-resultCardAddressLabel-m9esj{font-size:18px;line-height:24px;font-weight:bold;padding-top:15px;padding-bottom:5px}.StoreLocatorCard-module-resultCardCtas-LukJD{padding-top:25px;height:100%;display:flex;flex-direction:column;justify-content:flex-end}.StoreLocatorCard-module-resultsCardCta-ub\\+kW{display:flex;cursor:pointer;width:-moz-fit-content;width:fit-content;align-items:center}.StoreLocatorCard-module-resultsCardCta-ub\\+kW path{fill:#fff}.StoreLocatorCard-module-resultsCardCtaShowDetails-IzNKC{color:#fff;text-decoration:none}.StoreLocatorCard-module-resultCardCtaGetDirections-luxHK{text-decoration:none;color:#fff;margin-bottom:12px}.StoreLocatorCard-module-resultsCardCtaIcon-rJc9T{margin-inline-end:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultCard": "StoreLocatorCard-module-resultCard-ceYIF",
	"resultCardActive": "StoreLocatorCard-module-resultCardActive-T8pjv",
	"resultCardShopName": "StoreLocatorCard-module-resultCardShopName-TwBGo",
	"resultCardAddressLabel": "StoreLocatorCard-module-resultCardAddressLabel-m9esj",
	"resultCardCtas": "StoreLocatorCard-module-resultCardCtas-LukJD",
	"resultsCardCta": "StoreLocatorCard-module-resultsCardCta-ub+kW",
	"resultsCardCtaShowDetails": "StoreLocatorCard-module-resultsCardCtaShowDetails-IzNKC",
	"resultCardCtaGetDirections": "StoreLocatorCard-module-resultCardCtaGetDirections-luxHK",
	"resultsCardCtaIcon": "StoreLocatorCard-module-resultsCardCtaIcon-rJc9T"
};
export default ___CSS_LOADER_EXPORT___;
