// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CartItemList-module-container-\\+WS2v{display:grid;grid-gap:24px;gap:24px}.CartItemList-module-emptyMessage-7Qz3i{display:block;margin-top:5em}.CartItemList-module-checkoutErrorSummary-Fe2ZU{margin-bottom:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CartItemList-module-container-+WS2v",
	"emptyMessage": "CartItemList-module-emptyMessage-7Qz3i",
	"checkoutErrorSummary": "CartItemList-module-checkoutErrorSummary-Fe2ZU"
};
export default ___CSS_LOADER_EXPORT___;
