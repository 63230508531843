type DebounceableFunction = (...args: unknown[]) => unknown;

const debouncer = <T extends DebounceableFunction>(func: T, timeout = 300): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export default debouncer;
