// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopupImage-module-wrapper-X5MfI{width:100%;height:100%;border-radius:8px;margin-bottom:16px;max-width:400px}@media(min-width: 960px){.PopupImage-module-wrapper-X5MfI{width:auto;margin-bottom:0;max-width:none;border-radius:0}}.PopupImage-module-wrapperBlurred-zyghW{background:linear-gradient(138deg, rgb(248, 247, 246) 0%, rgb(248, 240, 236) 100%);transition:background .4s ease-in}.PopupImage-module-wrapperBlurred-zyghW img{opacity:0;transition:opacity .4s ease-in}.PopupImage-module-mainImage-N94QS{aspect-ratio:16/9;object-fit:cover;border-radius:8px;width:100%;transition:opacity .4s ease-in}@media(min-width: 960px){.PopupImage-module-mainImage-N94QS{aspect-ratio:auto;border-radius:0;height:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PopupImage-module-wrapper-X5MfI",
	"wrapperBlurred": "PopupImage-module-wrapperBlurred-zyghW",
	"mainImage": "PopupImage-module-mainImage-N94QS"
};
export default ___CSS_LOADER_EXPORT___;
