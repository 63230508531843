// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconLabeled-module-container-VLjMf{position:relative}.IconLabeled-module-label-fmiDL{position:absolute;bottom:-1px;inset-inline-end:-3px;background-color:#5c615e;background-color:var(--labeledicon-label-color, #5c615e);font-size:8px;width:12px;height:12px;line-height:12px;border-radius:12px;text-align:center;color:#fff;font-weight:bold;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IconLabeled-module-container-VLjMf",
	"label": "IconLabeled-module-label-fmiDL"
};
export default ___CSS_LOADER_EXPORT___;
