// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OptionalConsents-module-container-NS6fa{border:1px solid rgba(151,151,151,.33);border-radius:8px;padding:15px;margin-top:40px}.OptionalConsents-module-container-NS6fa .OptionalConsents-module-radioGroup-B-kYV{display:flex;flex-direction:row;margin-inline-start:12px}.OptionalConsents-module-container-NS6fa .OptionalConsents-module-radioLabel--lixJ{margin-inline-end:25px}.OptionalConsents-module-radioGroupLabel-f1mD3{color:#5c615e;font-weight:400;margin-top:15px;margin-bottom:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OptionalConsents-module-container-NS6fa",
	"radioGroup": "OptionalConsents-module-radioGroup-B-kYV",
	"radioLabel": "OptionalConsents-module-radioLabel--lixJ",
	"radioGroupLabel": "OptionalConsents-module-radioGroupLabel-f1mD3"
};
export default ___CSS_LOADER_EXPORT___;
