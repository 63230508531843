// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SaudiArabiaForm-module-wrapper-wDVez{display:flex;flex-flow:column wrap;gap:20px;gap:var(--spacing-sm, 20px)}@media(min-width: 960px){.SaudiArabiaForm-module-wrapper-wDVez{display:grid;grid-template-columns:repeat(2, 1fr);grid-template-rows:repeat(3, 1fr)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SaudiArabiaForm-module-wrapper-wDVez"
};
export default ___CSS_LOADER_EXPORT___;
