// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactUsDetailsInfo-module-contactUsDetailsInfoWrapper-Pky9Q{display:flex;flex-wrap:wrap;gap:10px}.ContactUsDetailsInfo-module-contactUsDetailsInfoWrapper-Pky9Q>div{min-width:315px;margin-top:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactUsDetailsInfoWrapper": "ContactUsDetailsInfo-module-contactUsDetailsInfoWrapper-Pky9Q"
};
export default ___CSS_LOADER_EXPORT___;
