// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button-module-button--u78\\+{display:inline-flex;text-decoration:none;cursor:pointer;color:var(--colors-text-text-primary);font-size:var(--size-text-small);line-height:var(--line-height-text-md);letter-spacing:var(--letter-spacing-none);gap:var(--spacing-5xs);background-color:rgba(0,0,0,0)}.Button-module-button--u78\\+:hover{color:var(--colors-text-text-tertiary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button-module-button--u78+"
};
export default ___CSS_LOADER_EXPORT___;
