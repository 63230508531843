// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreDetails-module-storeDetailsContainer-N4jcV{color:#fff}.StoreDetails-module-storeDetailsContainer-N4jcV .StoreDetails-module-storeDetailsCard-pjgV-{height:400px;min-width:300px;margin-inline-end:25px}.StoreDetails-module-storeMapContainer-OhEN3{display:flex;flex-direction:column;margin-top:20px;height:400px}@media(min-width: 960px){.StoreDetails-module-storeMapContainer-OhEN3{margin-top:60px;flex-direction:row}}.StoreDetails-module-mapContainer-BVYWj{width:100%;height:400px;margin-top:20px;border-radius:8px}@media(min-width: 960px){.StoreDetails-module-mapContainer-BVYWj{margin-top:0}}.StoreDetails-module-storeDetailsHeadline-ni5-a{font-size:24px;margin-inline-start:14px}@media(min-width: 960px){.StoreDetails-module-storeDetailsHeadline-ni5-a{margin-inline-start:325px;font-size:65px;font-weight:300;letter-spacing:0;line-height:75px}}.StoreDetails-module-loaderContainer-7ZahS{min-height:400px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"storeDetailsContainer": "StoreDetails-module-storeDetailsContainer-N4jcV",
	"storeDetailsCard": "StoreDetails-module-storeDetailsCard-pjgV-",
	"storeMapContainer": "StoreDetails-module-storeMapContainer-OhEN3",
	"mapContainer": "StoreDetails-module-mapContainer-BVYWj",
	"storeDetailsHeadline": "StoreDetails-module-storeDetailsHeadline-ni5-a",
	"loaderContainer": "StoreDetails-module-loaderContainer-7ZahS"
};
export default ___CSS_LOADER_EXPORT___;
