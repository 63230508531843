// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes BirthDateFormCtas-module-flickerAnimation-wUOjB{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes BirthDateFormCtas-module-fadeInAnimation-L35yD{0%{opacity:0}100%{opacity:1}}@keyframes BirthDateFormCtas-module-fadeInFromTop-O1s9p{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes BirthDateFormCtas-module-fadeOutToTop-NdWpx{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes BirthDateFormCtas-module-fadeInFromLeftAnimation-RaCya{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes BirthDateFormCtas-module-textClip-HwRq3{to{background-size:100% 100%}}@keyframes BirthDateFormCtas-module-scroll-WUKqh{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes BirthDateFormCtas-module-flickerAnimation-wUOjB{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes BirthDateFormCtas-module-fadeInAnimation-L35yD{0%{opacity:0}100%{opacity:1}}@keyframes BirthDateFormCtas-module-fadeInFromTop-O1s9p{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes BirthDateFormCtas-module-fadeOutToTop-NdWpx{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes BirthDateFormCtas-module-fadeInFromLeftAnimation-RaCya{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes BirthDateFormCtas-module-textClip-HwRq3{to{background-size:100% 100%}}@keyframes BirthDateFormCtas-module-scroll-WUKqh{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.BirthDateFormCtas-module-actionWrapper-Cwbmw{display:flex;padding-block:var(--spacing-xs);padding-inline:var(--spacing-md);justify-content:center;align-items:flex-start;gap:16px;gap:var(--spacing-xs, 16px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionWrapper": "BirthDateFormCtas-module-actionWrapper-Cwbmw",
	"flickerAnimation": "BirthDateFormCtas-module-flickerAnimation-wUOjB",
	"fadeInAnimation": "BirthDateFormCtas-module-fadeInAnimation-L35yD",
	"fadeInFromTop": "BirthDateFormCtas-module-fadeInFromTop-O1s9p",
	"fadeOutToTop": "BirthDateFormCtas-module-fadeOutToTop-NdWpx",
	"fadeInFromLeftAnimation": "BirthDateFormCtas-module-fadeInFromLeftAnimation-RaCya",
	"textClip": "BirthDateFormCtas-module-textClip-HwRq3",
	"scroll": "BirthDateFormCtas-module-scroll-WUKqh"
};
export default ___CSS_LOADER_EXPORT___;
