// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoreDetailsPopup-module-storePopup-lB\\+O8{padding-block:10px 15px;padding-inline:15px;border-radius:8px;background-color:#fcfcfc;min-width:300px;font-family:var(--font-family)}.StoreDetailsPopup-module-storePopupName-7lXwK{color:#5c615e;font-size:18px;font-weight:bold;letter-spacing:.19px;line-height:24px;padding-bottom:12px;margin:0}.StoreDetailsPopup-module-storePopupParagraph-XUTSW{margin:0;padding-bottom:12px;font-size:14px;font-weight:300;letter-spacing:0;line-height:23px}.StoreDetailsPopup-module-storePopupBody-T5rbd{padding-bottom:25px}.StoreDetailsPopup-module-storePopupFooter-\\+MLFp{display:flex;justify-content:space-between}.StoreDetailsPopup-module-storePopupCta-C3pka{display:flex;align-items:center;padding-inline-end:10px;color:#5c615e;cursor:pointer;gap:10px}.StoreDetailsPopup-module-storePopupCta-C3pka:last-of-type{padding-inline-end:0}.StoreDetailsPopup-module-icon--0vTs{width:17px}.StoreDetailsPopup-module-label-jNq6S{font-size:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"storePopup": "StoreDetailsPopup-module-storePopup-lB+O8",
	"storePopupName": "StoreDetailsPopup-module-storePopupName-7lXwK",
	"storePopupParagraph": "StoreDetailsPopup-module-storePopupParagraph-XUTSW",
	"storePopupBody": "StoreDetailsPopup-module-storePopupBody-T5rbd",
	"storePopupFooter": "StoreDetailsPopup-module-storePopupFooter-+MLFp",
	"storePopupCta": "StoreDetailsPopup-module-storePopupCta-C3pka",
	"icon": "StoreDetailsPopup-module-icon--0vTs",
	"label": "StoreDetailsPopup-module-label-jNq6S"
};
export default ___CSS_LOADER_EXPORT___;
