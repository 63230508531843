import { useEffect, useState } from "react";

import { Product as CartItem } from "@jti/magento";
import { useSelector } from "@jti/store";
import { dispatchTrackingEvent, TrackingEvents, TrackingEventsAdditionalData } from "@jti/tracker";

import { buildCommerceBaseData } from "./trackingHelpers";

const useGtmQuantityChange = (): void => {
  const cart = useSelector((state) => state.cart);
  const [cachedCart, setCachedCart] = useState(cart);

  useEffect(() => {
    const { items, itemsPending, id: cartId } = cart;
    const { items: cachedCartItems, id: cachedCartId } = cachedCart;
    const shouldTrackItem = cartId && cachedCartId && !itemsPending;

    const getChangedItem = () => {
      if (!cachedCartItems.length) {
        return items[0];
      }

      if (cachedCartItems.length === items.length) {
        return items.find((item) =>
          cachedCartItems.some((cachedItem) => item.id === cachedItem.id && item.quantity !== cachedItem.quantity)
        );
      }

      return items.find(({ id: itemId }) => cachedCartItems.every(({ id: cachedItemId }) => itemId !== cachedItemId));
    };

    const getCachedItem = (id: string | undefined) => {
      return cachedCartItems.find((item) => item.id === id);
    };

    const getRemovedItem = () => {
      return cachedCartItems.filter(({ id }) => !items.some((item) => item.id === id))[0];
    };

    const trackItemQuantityChange = (trackedItem: CartItem, cachedItem: CartItem | undefined) => {
      const trackItemQuantity = trackedItem.quantity || 0;
      const cachedItemQuantity = cachedItem?.quantity || 0;

      const event =
        trackItemQuantity > cachedItemQuantity
          ? TrackingEvents.TrackingCartQuantityAdd
          : TrackingEvents.TrackingCartQuantityRemove;

      const quantityChangeData: TrackingEventsAdditionalData[typeof event] = {
        ...buildCommerceBaseData(cart),
      };
      dispatchTrackingEvent(event, quantityChangeData);
    };

    const trackItemsRemove = (removedItem: CartItem) => {
      dispatchTrackingEvent(
        TrackingEvents.TrackingCartQuantityRemove,
        buildCommerceBaseData({
          ...cart,
          items: [removedItem],
        })
      );
    };

    const removedItem = getRemovedItem();
    if (removedItem) {
      trackItemsRemove(removedItem);
    }

    if (shouldTrackItem) {
      const trackedItem = getChangedItem();

      if (trackedItem) {
        const cachedItem = getCachedItem(trackedItem.id);

        if (trackedItem.quantity !== cachedItem?.quantity) {
          trackItemQuantityChange(trackedItem, cachedItem);
        }
      }
    }

    if (cartId) {
      setCachedCart(cart);
    }
  }, [cart.items]);
};

export default useGtmQuantityChange;
