// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterBreadcrumbItem-module-breadcrumbItem-L6WYe{display:none;margin-inline-end:5px;align-items:center}@media(min-width: 960px){.FooterBreadcrumbItem-module-breadcrumbItem-L6WYe{display:flex}}.FooterBreadcrumbItem-module-breadcrumbLink-oxkPi{font-size:12px;line-height:16px;font-weight:300;text-decoration:none;cursor:pointer;color:#fcfcfc}.FooterBreadcrumbItem-module-breadcrumbLink-oxkPi:hover{color:#3567ff}.FooterBreadcrumbItem-module-breadcrumbChevron-Gr2y2{transform:rotate(180deg);margin-inline-end:5px;color:#fcfcfc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbItem": "FooterBreadcrumbItem-module-breadcrumbItem-L6WYe",
	"breadcrumbLink": "FooterBreadcrumbItem-module-breadcrumbLink-oxkPi",
	"breadcrumbChevron": "FooterBreadcrumbItem-module-breadcrumbChevron-Gr2y2"
};
export default ___CSS_LOADER_EXPORT___;
