import { ICartItemError, ICartItemErrorType } from "@jti/magento";

import { CartItem } from "@checkout/apps/OnePageCheckout/types/CartItem";

//TODO: get proper type from ICartItemErrorType right now ItemQty is missing
export enum ExtendedCartItemErrorType {
  Undefined = "UNDEFINED",
  ItemQty = "ITEM_QTY",
  ItemIncrements = "ITEM_INCREMENTS",
  CartonLimit = "CARTONS_LIMIT",
  Ongoing = "ONGOING_SUBSCRIPTION",
}

export const hasCartErrorCode = (errors: ICartItemError[], errorCode: ICartItemErrorType): boolean => {
  for (const error of errors) {
    if (error.code === errorCode) {
      return true;
    }
  }
  return false;
};

export const filterItemsByErrorCode = (items: CartItem[], errorCode: ExtendedCartItemErrorType): CartItem[] => {
  return items.filter((item) =>
    item.errors?.some((error) => (error.code as unknown as ExtendedCartItemErrorType) === errorCode)
  );
};
