// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OtpComponent-module-otpWrapper-354UB{max-width:380px}.OtpComponent-module-otp-CATSQ{color:#5c615e;font-size:12px;font-weight:300;letter-spacing:.13px;line-height:16px}.OtpComponent-module-inputStyle-DfgHV{box-sizing:border-box;height:50px;width:50px !important;border:1px solid #373a36;border-radius:8px;margin-inline-end:20px;font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px;color:#5c615e}.OtpComponent-module-border-1LvA5{margin-top:24px;padding-block:0;padding-inline:18px;border:1px solid #f6f4ea;border-radius:8px}.OtpComponent-module-text-eGr\\+X{font-size:14px;font-weight:300;letter-spacing:.15px;line-height:20px;padding-block:24px;padding-inline:0;color:#373a36}@media(min-width: 960px){.OtpComponent-module-text-eGr\\+X{font-size:16px;line-height:23px;letter-spacing:0}}@media(min-width: 960px){.OtpComponent-module-resendBtn-sk8LJ{margin-inline-start:5px}}.OtpComponent-module-phoneVerifiedAlert-l5w40{margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otpWrapper": "OtpComponent-module-otpWrapper-354UB",
	"otp": "OtpComponent-module-otp-CATSQ",
	"inputStyle": "OtpComponent-module-inputStyle-DfgHV",
	"border": "OtpComponent-module-border-1LvA5",
	"text": "OtpComponent-module-text-eGr+X",
	"resendBtn": "OtpComponent-module-resendBtn-sk8LJ",
	"phoneVerifiedAlert": "OtpComponent-module-phoneVerifiedAlert-l5w40"
};
export default ___CSS_LOADER_EXPORT___;
